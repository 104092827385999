import { useRef, useState } from "react";
import TimbreCard, { TimbreCardDataType } from "./TimbreCard/TimbreCard";
import OpenDrawer, { IOpenDrawerForward } from "../../../OpenDrawer/OpenDrawer";
import "./CustomTimbreSelector.less";
import OpenIcon from "../../../OpenDrawer/OpenIocn/OpenIcon";

interface ICustomTimbreSelector {
  data: TimbreCardDataType[];
  min?: number;
  handleTimbreItemClick: (id: string) => void;
  activeId: string;
}

const CustomTimbreSelector: React.FC<ICustomTimbreSelector> = (props) => {
  const { data, min, handleTimbreItemClick, activeId } = props;
  const [isShowAll, setIsShowAll] = useState(false);
  // const { handleProjectCaptionToIndexChange } = useMixCutDetail();
  // const { projectCaption } = useMixCutDetail();

  const openDrawerRef = useRef<IOpenDrawerForward>(null);
  return (
    <div className="custom-timbre-selector-wrapper">
      <ul>
        {(data || []).map((item, index) => {
          return index + 1 <= min || isShowAll ? (
            <li key={item.id} className="timbre-card-wrapper">
              <TimbreCard
                data={item}
                active={activeId === item.speaker_id}
                onClick={() => {
                  handleTimbreItemClick(item.speaker_id);
                }}
              />
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
      <div className="oepn-wrapper">
        {data && data.length > 6 ? (
          <>
            <OpenIcon
              className="open-wrapper"
              value={isShowAll}
              toggle={(e) => {
                setIsShowAll(e);
              }}
              text={isShowAll ? "收起" : "展开"}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CustomTimbreSelector;
