import mp3 from "@/assets/cut-mix-detail/mix-detail-mp3.png";
import CheckBoxHoc from "@/components/business/MaterialFileModal/CheckBoxHoc/CheckBoxHoc";
import SelfUploadBlock from "@/pages/VideoClips/MixCutDetail/Components/SelfUploadBlock/SelfUploadBlock";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import deleteImg from "@/assets/cut-mix-detail/delete.png";
import { observer } from "mobx-react";
import "./CustomMusicGroup.less";
interface ICustomMusicGroupProps {
  handleSelfUploadBlockBtnClick: () => void;
  handleDeleteCustomMusicItem: (id: number) => void;
  handleCheckStateChange: (e: boolean, id: number) => void;
  collectionAudio: any[];
  data: any[];
  multiple?: boolean;
}

const CustomMusicGroup: React.FC<ICustomMusicGroupProps> = observer((props) => {
  const {
    handleSelfUploadBlockBtnClick,
    data,
    handleDeleteCustomMusicItem,
    handleCheckStateChange,
    collectionAudio,
    multiple = true,
  } = props;
  return (
    <div className="custom-music-group">
      {multiple ? (
        <div className="chose-wrapper">已选择：{collectionAudio.length}</div>
      ) : (
        <></>
      )}
      <SelfUploadBlock
        onClick={() => {
          handleSelfUploadBlockBtnClick();
        }}
        style={{ marginLeft: 4 }}></SelfUploadBlock>
      {(data || []).map((item) => {
        return (
          <div key={item.id} className="custom-music-card">
            <CheckBoxHoc
              style={{
                width: 126,
                height: 70,
                borderRadius: 8,
                overflow: "hidden",
                marginLeft: 4,
                marginBottom: 4,
                position: "relative",
              }}
              checked={
                (collectionAudio || []).findIndex(
                  (step) => step?.id === item?.id,
                ) > -1
              }
              onCheckedChange={(e) => {
                handleCheckStateChange(e, item.id);
              }}>
              <div
                className="delete-btn"
                onClick={(e) => {
                  handleDeleteCustomMusicItem(Number(item.id));
                  e.stopPropagation();
                }}>
                <img src={deleteImg} alt="" />
              </div>
              <div className="music-card">
                <img src={mp3} alt="" />
              </div>
            </CheckBoxHoc>
            <p>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
});

export default CustomMusicGroup;
