import "./ClearBgColor.less";

const EmptyBgColor = () => {
  return (
    <div className="empty-div">
      <div className="custom-line"></div>
    </div>
  );
};

export default EmptyBgColor;
