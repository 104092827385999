import VideoHoc from "@/components/business/VideoHoc/VideoHoc";
import { IMaterialFile } from "@/stores/mixCutDetailStore/type";
import { IconClose } from "@arco-design/web-react/icon";
import cutImg from "@/assets/cut-mix-detail/cut.png";
import cutted from "@/assets/cut-mix-detail/cutted.png";
import useMaterialPlayInfo from "@/useStore/mixCutDetail/useMaterialPlayInfo";
import "./VideoItemBlock.less";
import { Popconfirm } from "@arco-design/web-react";
import { observer } from "mobx-react";
export interface IVideoHocProps {
  data: IMaterialFile;
  handleCloseBtnClick: (id: string) => void;
  handleEditBtnClick: () => void;
  style: React.CSSProperties;
  isCropped?: boolean;
}

const VideoItemBlock: React.FC<IVideoHocProps> = observer((props) => {
  const {
    data,
    handleCloseBtnClick,
    style,
    handleEditBtnClick,
    isCropped = false,
  } = props;
  const { toIdFindMaterialItem } = useMaterialPlayInfo();
  return (
    <VideoHoc
      url={toIdFindMaterialItem(data?.id)?.url}
      style={{ ...style, position: "relative" }}>
      <div className="video-group-video-container">
        <Popconfirm
          focusLock
          title="删除提示！"
          content="确认删除此视频素材吗"
          onOk={() => {
            handleCloseBtnClick(data.id);
          }}
          onCancel={() => {
            console.log("取消");
          }}>
          <div className="video-close-btn">
            <IconClose className="close-btn" />
          </div>
        </Popconfirm>

        <img
          src={toIdFindMaterialItem(data.id)?.cover}
          alt=""
          className="video-item-cover-img"
        />
        {/* 
        {isCropped ? (
          <img
            src={cutted}
            alt=""
            className="video-item-cutted"
            onClick={() => {
              handleEditBtnClick();
            }}
          />
        ) : (
          <img
            src={cutImg}
            alt=""
            className="video-item-edit"
            onClick={() => {
              handleEditBtnClick();
            }}
          />
        )} */}
      </div>
    </VideoHoc>
  );
});

export default VideoItemBlock;
