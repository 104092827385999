import { useEffect, useState } from "react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import OpenDrawer from "../../VideoConfig/OpenDrawer/OpenDrawer";
import BgMusicTabs from "./BgMusicTabs/BgMusicTabs";
import {
  IMusicResponse,
  bindingMusic,
  cancelCollectMusic,
  collectMusic,
  deleteCustomizeMusic,
  getBindingVideo,
  getFavoriteMusicList,
  getMusicList,
  toIdGetMusicList,
} from "@/services/mixCutDetail/mixCutDetail";
import { Message } from "@arco-design/web-react";
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import { toJS } from "mobx";
import allowTypeStore from "@/stores/allowTypeStore";
import { useParams } from "react-router-dom";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import useSimpleReuqest from "@/requestHooks/mixCutDetail/useSimpleRequest";

const customMusicList = [
  {
    name: "收藏音乐",
    collection_id: 0,
  },
  // {
  //   name: "自定义",
  //   collection_id: -1,
  // },
];

const BgMusicConfig: React.FC = () => {
  const { id } = useParams();
  const {
    data: mp3List,
    loading: mp3ListLoadingState,
    handleRequest: getPublicMusic,
  } = useSimpleReuqest<number, IMusicResponse[]>({
    request: toIdGetMusicList,
  });
  const {
    data: favoriteMusicList,
    loading: favoriteMusicLoadingState,
    handleRequest: getFavoriteMusic,
  } = useSimpleReuqest<null, IMusicResponse[]>({
    request: getFavoriteMusicList,
  });
  const [tabList, setTabList] = useState<any[]>([]);
  const [selfMusicList, setSelfMusicList] = useState<IMaterialDetail[]>([]);
  const [activeId, setActiveId] = useState(0);
  const [visibleState, setVisibleState] = useState(true);
  const [uploadVisible, setUploadVisible] = useState(false);

  const init = async () => {
    const data = await getMusicList();
    setTabList([...customMusicList, ...data]);
    getPublicMusic(data[0].collection_id);
    setActiveId(data[0].collection_id);
    const selfMusicListData = await getBindingVideo(id);
    setSelfMusicList(selfMusicListData);
  };

  const handleMaterialFileModalOkBtnClick = async (e: any) => {
    try {
      const data = await bindingMusic(
        id,
        e.map((item) => Number(item.id)),
      );
      const result = await getBindingVideo(id);
      setSelfMusicList(result);
      setUploadVisible(false);
    } catch (e) {
      console.log(e);
      Message.error("绑定音乐失败！");
    }
  };

  const handleListActiveIdChange = async (e: number) => {
    if (e !== 0 && e !== -1) {
      try {
        getPublicMusic(e);
      } catch (e) {
        console.log(e);
        Message.error("请求音乐列表失败！");
      }
    }
    if (e === 0) {
      getFavoriteMusic(null);
    }
    setActiveId(e);
  };

  const onMusicCollect = async (musicId: number) => {
    try {
      await collectMusic(musicId);
      getPublicMusic(activeId);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelCollectBtnClick = async (musicId: number) => {
    try {
      await cancelCollectMusic(musicId);
      if (activeId === 0) {
        await getFavoriteMusic(null);
        return;
      }
      if (activeId !== -1) {
        await getPublicMusic(activeId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteCustomMusic = async (musiceId: number) => {
    // try {
    //   const deleteId = deleteCustomizeMusic(id, musiceId);
    //   Message.success("删除自定义音乐成功！");
    //   if (deleteId) {
    //     const result = await getBindingVideo(id);
    //     setSelfMusicList(result);
    //   }
    // } catch (e) {
    //   console.log(e);
    //   Message.error("删除自定义音乐失败！");
    // }
    console.log(musiceId);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <VideoConfigCard>
      <VideoConfigCardHeader
        headerLeft={
          <>
            <OpenDrawer
              state={visibleState}
              handleStateChange={(e) => {
                setVisibleState(e);
              }}></OpenDrawer>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setVisibleState(!visibleState);
              }}>
              背景音乐
            </span>
          </>
        }
        headerRight={<></>}></VideoConfigCardHeader>
      <VideoConfigCardContainer style={{ padding: 0, overflow: "hidden" }}>
        {visibleState ? (
          <BgMusicTabs
            favoriteLoading={favoriteMusicLoadingState}
            publicLoading={mp3ListLoadingState}
            handleCancelCollectBtnClick={(id: number) => {
              handleCancelCollectBtnClick(id);
            }}
            handleDeleteCustomMusicItem={(id: number) => {
              deleteCustomMusic(id);
            }}
            handleMusicCollectBtcClick={(id: number) => {
              onMusicCollect(id);
            }}
            selfMusicList={selfMusicList}
            list={tabList}
            musicList={activeId === 0 ? favoriteMusicList : mp3List}
            favoriteMusicList={favoriteMusicList}
            activeId={activeId}
            handleListActiveIdChange={(e) => {
              handleListActiveIdChange(e);
            }}
            handleSelfUploadBlockBtnClick={() => {
              setUploadVisible(true);
            }}
          />
        ) : (
          <></>
        )}
      </VideoConfigCardContainer>
      <MaterialFileModal
        onCancel={() => {
          setUploadVisible(false);
        }}
        allowType={toJS(allowTypeStore.getAllMusicTypeString())}
        visible={uploadVisible}
        allowMetarial={[2, 4]}
        handleOkBtnClick={(e) => {
          handleMaterialFileModalOkBtnClick(e);
        }}
      />
    </VideoConfigCard>
  );
};

export default BgMusicConfig;
