import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./OpenDrawer.less";
export interface IOpenDrawer {
  state: boolean;
  handleStateChange: (e: boolean) => void;
}

export interface IOpenDrawerForward {
  toggleState: () => void;
}

const OpenDrawer = forwardRef<IOpenDrawerForward, IOpenDrawer>((props, ref) => {
  const [state, setState] = useState(false);

  const toggleState = () => {
    props.handleStateChange(!state);
    setState(!state);
  };

  useImperativeHandle(ref, () => ({
    toggleState,
  }));

  useEffect(() => {
    if (props.state !== null) {
      setState(props.state);
    }
  }, [props.state]);

  return (
    <div
      className={`${state ? "open" : "close"} open-icon`}
      onClick={(e) => {
        toggleState();
        e.stopPropagation();
      }}></div>
  );
});

OpenDrawer.displayName = "OpenDrawer";

export default OpenDrawer;
