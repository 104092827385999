import Header from "./Header/Header";
import PreviewingVideo from "./PreviewingVideo/PreviewingVideo";
import VideoConfig from "./VideoConfig/VideoConfig";
import VideoGroup from "./VideoGroup/VideoGroup";
import { createContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import useVideoGroup from "@/useStore/mixCutDetail/useVideoGroup";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import {
  toIdGetEngineer,
  toIdGetEngineerContent,
} from "@/services/createSpace/engineering";
import useMixCutDetailInit from "@/useStore/mixCutDetail/useMixCutDetailInit";
import VideoGlobalModal from "./TipModal/VideoGlobalModal";
import mixCutDetailCheck from "@/useStore/mixCutDetail/mixCutDetailCheck";
import moment from "moment";
import {
  toIdGetCustomScript,
  toIdGetScript,
} from "@/services/createSpace/script";
import useParseScript from "@/useStore/mixCutDetail/useParseScript";
import usePreviewConfig from "@/useStore/preview/usePreview";
import useJsonToRenderObj from "@/useStore/mixCutDetail/useJsonToRenderObj";
import "./MixCutDetail.less";
import previewConfig from "@/stores/previewStore";
import clipIdStore from "@/stores/previewStore/clipId";
import usePreviewInfo from "@/useStore/mixCutDetail/usePreviewInfo";
import { Message } from "@arco-design/web-react";
export interface IMixCutDetailContext {
  videoGroupActive: number;
  videoGroupTransitionId: string;
  setVideoGroupActive: React.Dispatch<React.SetStateAction<number>>;
}

export const mixCutDetailContext = createContext<IMixCutDetailContext>(null);

type ActiveType = "transition" | "title" | "caption";

const MixCutDetail = observer(() => {
  const { id, script_id } = useParams();
  const { parseCutsomScript } = useParseScript();
  const { maxCount } = usePreviewInfo();
  const navigator = useNavigate();

  const {
    projectCaption,
    projectSences,
    setProjectSences,
    handleProjectName,
    handleProjectSencesAdd,
    handleProjectSencesDelete,
    handleProjectVideoChange,
    handleChangeVideoItemStartAndDuration,
  } = useVideoGroup();
  const { handleToChangeVideoIndexSetTitleAndCaption } = usePreviewConfig();
  const { global, projectMsg, handleProjectMsgSet, toggleGlobaltoItem } =
    useMixCutDetail();
  const { handletoOriginJsonRender } = useJsonToRenderObj();
  const { initAllMixCutDetal } = useMixCutDetailInit();
  const [videoConfigActiveIndex, setVideoConfigActiveIndex] = useState(0);
  const [videoGroupActive, setVideoGroupActive] = useState(0);
  const [videoGroupTransitionId, setVideoGroupTransitionId] =
    useState<string>("0");
  const {
    checkJson,
    confirmCaptionsErrorIsEmpty,
    confirmSencesErrorIsEmpty,
    // combSenceMaterials,
  } = mixCutDetailCheck();
  const [tipItemModalVisible, setTipItemModalVisible] = useState(false);
  const [editorActiveIndex, setEditorActiveIndex] = useState<{
    groupIndex: number;
    activeIndex: number;
    type: ActiveType;
  }>({
    groupIndex: null,
    activeIndex: null,
    type: "" as "title",
  });

  const init = async (id: number, script?: number) => {
    try {
      const result = await toIdGetEngineer(id);
      handleProjectMsgSet({
        ...result,
        updated_at: moment
          .unix(result.updated_at)
          .format("YYYY-MM-DD HH:mm") as any,
      });
      if (script) {
        const data = await toIdGetCustomScript(script);
        parseCutsomScript(data);
      } else {
        const contentResult = await toIdGetEngineerContent(id);
        const content = JSON.parse(contentResult);
        if (Object.keys(content || {}).length === 0) {
          initAllMixCutDetal();
        } else {
          handletoOriginJsonRender(content);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTipModalOkBtnClick = () => {
    toggleGlobaltoItem();
    setVideoGroupActive(0);
    setVideoConfigActiveIndex(0);
  };

  useEffect(() => {
    if (videoGroupActive === 0 && videoConfigActiveIndex === 2) {
      setVideoConfigActiveIndex(0);
    }
    handleToChangeVideoIndexSetTitleAndCaption(videoGroupActive);
  }, [videoGroupActive]);

  const executeBasedOnScope = async (
    groupIndex: number,
    index: number,
    type: ActiveType,
  ) => {
    if (global) {
      setTipItemModalVisible(true);
      setEditorActiveIndex({ groupIndex, activeIndex: index, type });
    } else {
      setVideoConfigActiveIndex(index);
      setVideoGroupActive(groupIndex);

      if (type === "transition") {
        setVideoGroupTransitionId(groupIndex.toString());
      }
    }
  };

  useEffect(() => {
    init(Number(id), Number(script_id));
  }, [id]);

  return (
    <mixCutDetailContext.Provider
      value={{
        videoGroupActive,
        setVideoGroupActive,
        videoGroupTransitionId,
      }}>
      <div className="mix-cut-detail-wrapper">
        {/* <Button
          onClick={() => {
            console.log(getPreviewData());
          }}>
          查看数据
        </Button> */}
        <Header
          updateTime={projectMsg?.updated_at}
          handleResultBtnClick={() => {
            checkJson();
            // combSenceMaterials(handleGetOriginJson());
            // if (confirmCaptionsErrorIsEmpty() && confirmSencesErrorIsEmpty()) {
            if (!maxCount) {
              return Message.warning("当前混剪配置无法生成视频！");
            }
            window.open(`/video-clip-result/${id}`, "_blank");
            // }
            // console.log(combSenceMaterials(handleGetOriginJson()));
            // console.log(
            //   getCombinationMaxCount(combSenceMaterials(handleGetOriginJson())),
            // );
          }}
          name={projectMsg?.title}
          handleChangeName={(e) => {
            handleProjectName(e);
          }}></Header>
        <div className="mix-cut-detail-container">
          <VideoGroup
            handleHeaderItemClick={(index) => {
              setVideoGroupActive(index);
            }}
            handleVideoGroupAdd={() => {
              handleProjectSencesAdd();
            }}
            handleVideoGroupDelete={(index) => {
              handleProjectSencesDelete(index);
            }}
            data={projectSences}
            handleProjectVideoChange={handleProjectVideoChange}
            handleDataChange={(e) => {
              setProjectSences(e);
            }}
            handleCaptionBtnClick={(e) => {
              executeBasedOnScope(e, 0, "caption");
            }}
            handleTitleBtnClick={(e) => {
              executeBasedOnScope(e, 1, "title");
            }}
            handleChangeVideoItemStartAndDuration={
              handleChangeVideoItemStartAndDuration
            }
            // handlePlayerCaption={handlePlayerCaption}
            // handlePlayerCaptionStop={handlePlayerCaptionStop}
            handleTransitionBtnClick={(e) => {
              executeBasedOnScope(e, 2, "transition");
            }}></VideoGroup>
          <VideoConfig
            handleGlobalChange={() => {
              setVideoConfigActiveIndex(0);
              setVideoGroupActive(0);
              handleTipModalOkBtnClick();
            }}
            videoConfigActiveIndex={videoConfigActiveIndex}
            handleVideoConfigActiveIndexChange={setVideoConfigActiveIndex}
            CationConfig={projectCaption}
            global={global}></VideoConfig>
          <PreviewingVideo></PreviewingVideo>
        </div>
        <VideoGlobalModal
          title={"切换局部配置警告"}
          visible={tipItemModalVisible}
          onCancel={() => {
            setTipItemModalVisible(false);
          }}
          onOk={() => {
            setTipItemModalVisible(false);
            handleTipModalOkBtnClick();
          }}
          content={
            "切换到分镜配置模式，全局配置下的台词组，标题组，还有转场信息将全部丢失，是否继续？"
          }
        />
      </div>
    </mixCutDetailContext.Provider>
  );
});

export default MixCutDetail;
