import "./TransitionCard.less";
import { memo } from "react";
export interface ITransitionCard {
  id?: string;
  url: string;
  name: string;
  style?: React.CSSProperties;
}

const TransitionCard: React.FC<ITransitionCard> = memo((props) => {
  const { name, url, style } = props;
  return (
    <div className="transition-card-wrapper" style={style}>
      <div className="transition-card">
        <img src={url} alt="" />
      </div>
    </div>
  );
});

export default TransitionCard;
