import "./BgMusicTabsNav.less";

interface BgMusicTabsNavProps {
  navList: { name: string; collection_id: number }[];
  activeId: number;
  handleActiveId: (id: number) => void;
}

const BgMusicTabsNav: React.FC<BgMusicTabsNavProps> = (props) => {
  const { activeId, handleActiveId, navList } = props;
  return (
    <>
      <ul className="bg-music-config-nav">
        {navList.map((item, index) => {
          return (
            <li
              onClick={() => {
                handleActiveId(item.collection_id);
              }}
              key={item.collection_id}
              className={`${activeId === item.collection_id ? "active" : ""}`}>
              {item.name}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default BgMusicTabsNav;
