import { useEffect, useRef, useState } from "react";
import TitleGroup from "../../FontTitle/TitleGroup/TitleGroup";
import VoiceConfig from "../VoiceConfig/VoiceConfig";
import LineFontConfig from "../VoiceConfig/LineFontConfig/LineFontConfig";
import { ITextSetting } from "@/stores/mixCutDetailStore/type/caption";
import { observer } from "mobx-react";
import useGlobalCaption from "@/useStore/mixCutDetail/useGlobalCaption";
import { editorValueType } from "../VoiceLine/VoiceLine";
import MyEditor from "@/components/business/MyEditor";
import SmartSearch from "@/components/business/SmartSearch";
import Intelligent from "@/components/business/Intelligent";
import { Message } from "@arco-design/web-react";
import aiLineImg from "@/assets/cut-mix-detail/ai_line.png";
import clockImg from "@/assets/cut-mix-detail/clock_blue.png";
import lineImg from "@/assets/cut-mix-detail/color_sound.png";
import captionPlayerImg from "@/assets/cut-mix-detail/caption-playing.gif";
import loadingSvg from "@/assets/create-space/loading.svg";
import "./GlobalCaption.less";
import {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import usePreviewConfig from "@/useStore/preview/usePreview";
import useAudioPlayer from "@/requestHooks/mixCutDetail/useAudioPlayer";
import { useEditorHelper } from "@/components/business/MyEditor/helper";

const initEditorClearValue: editorValueType[] = [
  {
    type: "paragraph",
    content: [
      {
        type: "text",
        text: "",
      },
    ],
  },
];

export const toStrGetEditorValueType = (strList: string[]) => {
  if (strList && strList.length > 0) {
    return [
      ...strList.map((item) => ({
        type: "paragraph",
        content: [
          {
            type: "text",
            text: item,
          },
        ],
      })),
    ];
  } else {
    return initEditorClearValue;
  }
};

const GlobalCaption = observer(() => {
  const {
    globalCaptions,
    handleDeleteGlobalCaptionItem,
    handleAddGlobalCaptions,
    handleGlobalCationsChange,
  } = useGlobalCaption();
  const {
    isPlaying,
    stopAudio,
    playAudio,
    loading: isLoading,
  } = useAudioPlayer();
  const { handleClipIdChange, clipId } = usePreviewConfig();
  const { handlePreviewCaptionChange } = usePreviewConfig();
  const [activeIndex, setActiveIndex] = useState(0);
  const [aiSearchState, setAiSearchState] = useState(false);
  const [intelligenceState, setIntelligenceState] = useState(false);
  const [titleIntellIgenceValue, setTitleTntellIgenceValue] = useState("");
  const [titleIntellIgenceType, setTitleTntellIgenceType] = useState("改写");
  const [isIntelligenceTypo, setIsIntelligenceTypo] = useState(true);

  // smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(true);

  // 编辑器光标位置
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    range: {
      from: -1,
      to: -1,
    },
  });

  const editorRef = useRef<any>(null);
  const getTitleGroup = () => {
    return globalCaptions.map((item, index) => {
      return {
        title: "台词组" + (index + 1),
        value: item.id,
      };
    });
  };

  const handleClickInterlligent = (opt) => {
    const edit = editorRef.current.editor;
    const [helper] = useEditorHelper(edit);
    const { from, to } = helper.getSelectionSSML();
    switch (opt.type) {
      case "change":
        if (from === to) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const editor = editorRef.current.editor;
    const [helper] = useEditorHelper(editor);
    setSelectInfo({ ...selectInfo, range: helper.getSelectionSSML() });
  }, [activeIndex]);

  const toPlayStateGetImg = (index: number) => {
    if (isLoading === true)
      return (
        <img
          onClick={() => {
            stopAudio();
          }}
          src={loadingSvg}
          alt=""
        />
      );
    if (isPlaying === true)
      return (
        <img
          onClick={() => {
            stopAudio();
          }}
          src={captionPlayerImg}
          alt=""
        />
      );
    return (
      <img
        onClick={() => {
          handlePlayerCaption(index);
        }}
        src={lineImg}
        alt=""
      />
    );
  };

  const handlePlayerCaption = async (index: number) => {
    try {
      const [helper] = useEditorHelper(editorRef.current.editor);
      const item = globalCaptions[index];
      if (item.content.join(",").length > 0) {
        await playAudio({
          line: helper.exportSSML(),
          speaker_id: item.voice_setting.speaker_id,
          speed_ratio: item.voice_setting.speed_ratio,
          volume_ratio: item.voice_setting.volume_ratio,
          pitch_ratio: item.voice_setting.pitch_ratio,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="global-caption">
      <TitleGroup
        titleCopy="添加台词组"
        style={{ marginTop: 12, marginBottom: 12 }}
        handleActiveIndexChange={(index) => {
          handlePreviewCaptionChange(globalCaptions[index]);
          setActiveIndex(index);
          stopAudio();
        }}
        active={activeIndex}
        data={getTitleGroup()}
        handleAddBtnClick={handleAddGlobalCaptions}
        isEdit={true}
        handleDeleteBtnClick={(e: number) => {
          if (globalCaptions.length === 1) {
            Message.error("必须保持有一个台词组！");
            return;
          }
          if (e === 0 && activeIndex === 0) {
            handlePreviewCaptionChange((globalCaptions || [])[1] || null);
            handleClipIdChange(null);
          }
          if (activeIndex === e) {
            setActiveIndex(0);
          }
          if (activeIndex > e) {
            setActiveIndex(activeIndex - 1);
          }

          handleDeleteGlobalCaptionItem(e);
        }}></TitleGroup>
      <VideoConfigCardHeader
        headerLeft={
          <div className="caption-header">
            台词文案{toPlayStateGetImg(activeIndex)}
          </div>
        }
        headerRight={""}
      />
      <VideoConfigCardContainer style={{ padding: 10 }}>
        <div style={{ position: "relative" }} className="editor-wrapper">
          <MyEditor
            ref={(ref) => {
              editorRef.current = ref;
            }}
            useCharacterLimit={false}
            content={
              (globalCaptions || [])[activeIndex]?._content_json &&
              (globalCaptions || [])[activeIndex]?._content_json?.length > 0
                ? (globalCaptions || [])[activeIndex]?._content_json
                : [
                    {
                      type: "listItem",
                      content: [
                        {
                          type: "paragraph",
                        },
                      ],
                    },
                  ]
            }
            placeholder={"请输入文字标题"}
            type={"caption"}
            limit={100}
            handlefocus={() => {
              handleClipIdChange(globalCaptions[activeIndex].id);
            }}
            handleUpdate={(val) => {
              const data = (
                editorRef.current.editor.getJSON().content[0].content || []
              ).map((node) => {
                return `<speak>${editorRef.current.helper.formatNodeToSSML(node).replace(/\n\n/g, "")}</speak>`;
              });
              const newItem = { ...globalCaptions[activeIndex] };
              newItem.content = data;
              newItem._content_json = val;
              handleGlobalCationsChange(activeIndex, newItem);
              handlePreviewCaptionChange(newItem);
            }}
            selected={({ value, range }) => {
              setSmartValue(
                value ||
                  editorRef.current.editor
                    .getText()
                    .replace(/\n\n\n\n/g, "\n")
                    .slice(1),
              );
              setSelectInfo({
                index: -1,
                range,
              });
            }}
            selectInfo={selectInfo}
          />
          <div className="voice-container-bar">
            <div className="insert-opeator" style={{ position: "relative" }}>
              <div
                className="tags insert-stop"
                style={{ marginRight: 6 }}
                onClick={() => {
                  editorRef.current.addBreak({
                    time: "0.5s",
                  });
                }}>
                <img src={clockImg} alt="" />
                插入停顿
              </div>
              <img
                src={aiLineImg}
                alt=""
                className="ai-line"
                onClick={() => {
                  setAiSearchState(true);
                }}
              />
              {aiSearchState ? (
                <SmartSearch
                  placeholder={"帮我写..."}
                  styles={{
                    marginLeft: "70px",
                    width: "468px",
                  }}
                  handelClickBtn={(value: string, type: string) => {
                    setSmartStatus(type);
                    setSendSmartStatus(value);
                    setIntelligenceState(true);
                  }}
                  editValue={smartValue}
                  isEnd={itelligentIsExecution}
                  handleKeyDown={(event) => {
                    if (event.key === "Escape" || event.keyCode === 27) {
                      setAiSearchState(false);
                      setIntelligenceState(false);
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </VideoConfigCardContainer>
      {intelligenceState ? (
        <Intelligent
          style={{
            width: "400px",
            position: "fixed",
            right: 0,
            bottom: 0,
            height: "calc(100vh - 120px)",
            zIndex: 1000,
            top: 73,
          }}
          defaultStatus={smartStatus}
          value={sendSmartStatus}
          isInExecution={setItelligentIsExecution}
          handleOnChange={(e) => {
            handleClickInterlligent(e);
          }}
          handleCloseBtnClick={() => {
            setSendSmartStatus("");
            setIntelligenceState(false);
            setAiSearchState(false);
          }}
        />
      ) : (
        <></>
      )}
      <VoiceConfig
        global={true}
        is_global={false}
        style={{ marginTop: 12 }}
        speedValue={
          (globalCaptions || [])[activeIndex]?.voice_setting.speed_ratio
        }
        pitchValue={
          (globalCaptions || [])[activeIndex]?.voice_setting.pitch_ratio
        }
        speekerId={
          (globalCaptions || [])[activeIndex]?.voice_setting.speaker_id
        }
        volume={(globalCaptions || [])[activeIndex]?.voice_setting.volume_ratio}
        handleSpeedValueChange={(e) => {
          const newItem = globalCaptions[activeIndex];
          newItem.voice_setting.speed_ratio = e;
          handleGlobalCationsChange(activeIndex, { ...newItem });
          handlePreviewCaptionChange(newItem);
        }}
        handlePitchValueChange={(e) => {
          const newItem = globalCaptions[activeIndex];
          newItem.voice_setting.pitch_ratio = e;
          handleGlobalCationsChange(activeIndex, { ...newItem });
          handlePreviewCaptionChange(newItem);
        }}
        handleSpeekerIdChange={(e) => {
          const newItem = globalCaptions[activeIndex];
          newItem.voice_setting.speaker_id = e;
          handleGlobalCationsChange(activeIndex, { ...newItem });
          handlePreviewCaptionChange(newItem);
        }}
        handleVolumeChange={(e) => {
          const newItem = globalCaptions[activeIndex];
          newItem.voice_setting.volume_ratio = e;
          handleGlobalCationsChange(activeIndex, { ...newItem });
          handlePreviewCaptionChange(newItem);
        }}
      />
      <LineFontConfig
        global={true}
        style={{ marginTop: 12 }}
        data={(globalCaptions || [])[activeIndex]?.text_setting}
        handleTextSettingChange={(e) => {
          const newItem = globalCaptions[activeIndex];
          newItem.text_setting = e;
          handleGlobalCationsChange(activeIndex, { ...newItem });
          handlePreviewCaptionChange(newItem);
        }}
      />
    </div>
  );
});

export default GlobalCaption;
