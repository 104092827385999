import React from "react";
import no from "@/assets/teamoverview/no.png";
const Nodata = (props?) => {
  const { title } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: "1",
      }}>
      <img src={no} alt="" style={{ width: "150px", height: "107px" }} />
      <p style={{ textAlign: "center" }}>{title ? title : "暂无内容"}</p>
    </div>
  );
};
export default Nodata;
