import aiLineImg from "@/assets/cut-mix-detail/ai_line.png";
import clockImg from "@/assets/cut-mix-detail/clock_blue.png";
import SmartSearch from "@/components/business/SmartSearch";
import { useContext, useEffect, useRef, useState } from "react";
import Intelligent from "@/components/business/Intelligent";
import { Message } from "@arco-design/web-react";
import { observer } from "mobx-react";
import MyEditor from "@/components/business/MyEditor";
import lineImg from "@/assets/cut-mix-detail/color_sound.png";
import captionPlayerImg from "@/assets/cut-mix-detail/caption-playing.gif";
import loadingSvg from "@/assets/create-space/loading.svg";
import {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import TitleGroup from "../../FontTitle/TitleGroup/TitleGroup";
import usePreviewConfig from "@/useStore/preview/usePreview";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import { mixCutDetailContext } from "../../MixCutDetail";
import useAudioPlayer from "@/requestHooks/mixCutDetail/useAudioPlayer";
import { useEditorHelper } from "@/components/business/MyEditor/helper";
import "./VoiceLine.less";
export interface IVoiceLine {
  ids: number[];
  style: React.CSSProperties;
  onChange: (e: string[], str: any, playContent: string) => void;
  value: string[][];
  captionEditorIndex: number;
  onCaptionEditorIndexChange: (e: number) => void;
  handleAddButtonClick: () => void;
  handleDeleteButtonClick: (index: number) => void;
}

export interface editorItem {
  type: "text";
  text: string;
}

export interface editorValueType {
  type: "paragraph";
  content: editorItem[];
}

const VoiceLine: React.FC<IVoiceLine> = observer((props) => {
  const {
    ids,
    style,
    onChange,
    value,
    captionEditorIndex,
    onCaptionEditorIndexChange,
    handleAddButtonClick,
    handleDeleteButtonClick,
  } = props;
  const { videoGroupActive } = useContext(mixCutDetailContext);
  const { projectCaption } = useCaption();
  const { handleClipIdChange, handlePreviewCaptionChange } = usePreviewConfig();
  const [aiSearchState, setAiSearchState] = useState<boolean>(false);
  const [intelligenceState, setIntelligenceState] = useState<boolean>(false);
  const {
    isPlaying,
    stopAudio,
    playAudio,
    loading: isLoading,
  } = useAudioPlayer();
  // smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(true);
  // 编辑器光标位置
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    range: {
      from: -1,
      to: -1,
    },
  });
  const toPlayStateGetImg = (index: number) => {
    if (isLoading === true)
      return (
        <img
          onClick={() => {
            stopAudio();
          }}
          src={loadingSvg}
          alt=""
        />
      );
    if (isPlaying === true)
      return (
        <img
          onClick={() => {
            stopAudio();
          }}
          src={captionPlayerImg}
          alt=""
        />
      );
    return (
      <img
        onClick={() => {
          handlePlayerCaption(index);
        }}
        src={lineImg}
        alt=""
      />
    );
  };

  const handlePlayerCaption = async (index: number) => {
    try {
      const [helper] = useEditorHelper(editorRef.current.editor);
      const item = projectCaption[videoGroupActive][index];
      if (item.content.join(",").length > 0) {
        await playAudio({
          line: helper.exportSSML(),
          speaker_id: item.voice_setting.speaker_id,
          speed_ratio: item.voice_setting.speed_ratio,
          volume_ratio: item.voice_setting.volume_ratio,
          pitch_ratio: item.voice_setting.pitch_ratio,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    stopAudio();
  }, [captionEditorIndex]);

  useEffect(() => {
    const editor = editorRef.current.editor;
    const [helper] = useEditorHelper(editor);
    setSelectInfo({ ...selectInfo, range: helper.getSelectionSSML() });
  }, [captionEditorIndex]);

  const toValueGetTitleGroup = () => {
    return value.map((_, index) => {
      return {
        value: index,
        title: "台词组" + (index + 1),
      };
    });
  };

  const handleClickInterlligent = (opt) => {
    const edit = editorRef.current.editor;
    const [helper] = useEditorHelper(edit);
    const { from, to } = helper.getSelectionSSML();
    switch (opt.type) {
      case "change":
        if (from === to) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
  };

  const editorRef = useRef<any>(null);
  return (
    <div className="voice-line-wrapper" style={style}>
      <TitleGroup
        active={captionEditorIndex}
        titleCopy="添加台词组"
        style={{ marginTop: 12, marginBottom: 12 }}
        handleActiveIndexChange={(index) => {
          onCaptionEditorIndexChange(index);
        }}
        data={toValueGetTitleGroup()}
        handleAddBtnClick={() => {
          handleAddButtonClick();
        }}
        isEdit={true}
        handleDeleteBtnClick={(e) => {
          if (value.length === 1) {
            Message.error("必须保持有一个台词组！");
            return;
          }
          if (e === 0 && captionEditorIndex === 0) {
            handlePreviewCaptionChange(
              ((projectCaption || [])[videoGroupActive] || [])[
                captionEditorIndex + 1
              ] || null,
            );
            handleClipIdChange(null);
          }
          if (captionEditorIndex === e) {
            onCaptionEditorIndexChange(0);
          }
          if (captionEditorIndex > e) {
            onCaptionEditorIndexChange(captionEditorIndex - 1);
          }
          handleDeleteButtonClick(e);
        }}></TitleGroup>
      <>
        <VideoConfigCardHeader
          style={{ marginTop: "20px" }}
          headerLeft={
            <span className="header-left">
              台词文案 {toPlayStateGetImg(captionEditorIndex)}
            </span>
          }
          headerRight={<>shift+enter 行内折行</>}></VideoConfigCardHeader>
        <VideoConfigCardContainer style={{ padding: 10, paddingBottom: 0 }}>
          <span
            className="clear"
            onClick={() => {
              onChange(
                [],
                [
                  {
                    type: "listItem",
                    content: [
                      {
                        type: "paragraph",
                      },
                    ],
                  },
                ],
                "",
              );
              handleClipIdChange(null);
            }}>
            清空
          </span>
          <div
            style={{
              position: "relative",
            }}>
            <MyEditor
              ref={(ref) => {
                editorRef.current = ref;
              }}
              useCharacterLimit={false}
              content={
                value[captionEditorIndex] && value[captionEditorIndex].length
                  ? value[captionEditorIndex]
                  : [
                      {
                        type: "listItem",
                        content: [
                          {
                            type: "paragraph",
                          },
                        ],
                      },
                    ]
              }
              placeholder={"请输入台词文案"}
              type={"caption"}
              limit={100}
              handleblur={() => {
                // handleClipIdChange(null);
              }}
              handlefocus={() => {
                onCaptionEditorIndexChange(captionEditorIndex);
                handleClipIdChange(
                  projectCaption[videoGroupActive][captionEditorIndex].id,
                );
              }}
              handleUpdate={(val) => {
                const data = (
                  editorRef.current.editor.getJSON().content[0].content || []
                ).map((node) => {
                  return `<speak>${editorRef.current.helper.formatNodeToSSML(node).replace(/\n\n/g, "")}</speak>`;
                });
                const playContent = editorRef.current.editor
                  .getText()
                  .replace("/\n", " ");
                onChange(data, val, playContent);
              }}
              selected={({ value, range }) => {
                setSmartValue(
                  value ||
                    editorRef.current.editor
                      .getText()
                      .replace(/\n\n\n\n/g, "\n")
                      .slice(1),
                );
                setSelectInfo({
                  index: -1,
                  range,
                });
              }}
              selectInfo={selectInfo}
            />
          </div>

          <div className="voice-container-bar">
            <div className="insert-opeator" style={{ position: "relative" }}>
              <div
                className="tags insert-stop"
                onClick={(e) => {
                  e.stopPropagation();
                  editorRef.current[captionEditorIndex].addBreak({
                    time: "0.5s",
                  });
                  setAiSearchState(false);
                  setIntelligenceState(false);
                  setItelligentIsExecution(true);
                }}
                style={{ marginRight: 6, cursor: "pointer" }}>
                <img src={clockImg} alt=""></img>
                插入停顿
              </div>
              {/* 智能文案 */}
              <img
                src={aiLineImg}
                alt=""
                className="ai-line"
                onClick={() => {
                  setAiSearchState(!aiSearchState);
                  setIntelligenceState(false);
                  setItelligentIsExecution(true);
                }}
              />
              {aiSearchState ? (
                <SmartSearch
                  disStopPropagation
                  placeholder={"帮我写..."}
                  styles={{
                    marginLeft: "70px",
                    width: "468px",
                  }}
                  handelClickBtn={(value: string, type: string) => {
                    setSmartStatus(type);
                    setSendSmartStatus(value);
                    setIntelligenceState(true);
                  }}
                  editValue={smartValue}
                  isEnd={itelligentIsExecution}
                  handleKeyDown={(event) => {
                    if (event.key === "Escape" || event.keyCode === 27) {
                      setAiSearchState(false);
                      setIntelligenceState(false);
                      setItelligentIsExecution(true);
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </VideoConfigCardContainer>
      </>
      {intelligenceState ? (
        <Intelligent
          style={{
            width: "405px",
            top: 73,
            position: "fixed",
            right: 0,
            bottom: 0,
            height: "calc(100vh - 120px)",
            zIndex: 1000,
          }}
          defaultStatus={smartStatus}
          value={sendSmartStatus}
          isInExecution={setItelligentIsExecution}
          handleOnChange={(e) => {
            handleClickInterlligent(e);
          }}
          handleCloseBtnClick={() => {
            setSendSmartStatus("");
            setIntelligenceState(false);
            setItelligentIsExecution(true);
            setAiSearchState(aiSearchState);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
});

export default VoiceLine;
