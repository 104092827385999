import { Checkbox, InputNumber } from "@arco-design/web-react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../UIComponents/VideoConfigCard/VideoConfigCard";
import ChoseActionBar from "../VideoCoverConfig/ChoseActionBar/ChoseActionBar";
import TransitionGroup from "./TransitionGroup/TransitionGroup";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import { getTransitionsList } from "@/services/mixCutDetail/mixCutDetail";
import useGlobalTransition from "@/useStore/mixCutDetail/useGlobalTransition";
import { ITransitionEffectItem } from "@/stores/mixCutDetailStore/type/transition";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./Transition.less";

const processChoice = (
  e: {
    effect: ITransitionEffectItem;
  }[],
) => {
  return e.map((item) => {
    return { id: item.effect.sub_type, name: item.effect.type };
  });
};

const GlobalTransition = observer(() => {
  const { data } = useRequest({
    request: getTransitionsList,
  });
  const [choseList, setChoseList] = useState<{ name: string; id: number }[]>(
    [],
  );
  const {
    globalTransition,
    handleAddTransitionItem,
    handleRemoveTransitionItem,
    handleChangeDuration,
  } = useGlobalTransition();

  const getCheckList = () => {
    const newcheckList = (data || []).filter((item) =>
      (globalTransition.effects || []).some(
        (step) => step.sub_type === item.content.sub_type,
      ),
    );
    setChoseList(
      newcheckList.map((item) => {
        return { name: item.name, id: item.id };
      }),
    );
  };

  const handleCheckboxChange = (e: boolean, id: number) => {
    const item = data.find((item) => item.id === id);
    if (e) {
      handleAddTransitionItem({
        sub_type: item.content.sub_type,
        type: "Transition",
      });
    } else {
      handleRemoveTransitionItem({
        sub_type: item.content.sub_type,
        type: "Transition",
      });
    }
  };
  useEffect(() => {
    getCheckList();
  }, [globalTransition, data]);

  return (
    <>
      <>
        <VideoConfigCard>
          <VideoConfigCardHeader
            headerLeft={"全局转场设置"}
            headerRight={<></>}></VideoConfigCardHeader>
          <VideoConfigCardContainer>
            <div className="transition-header">
              {choseList.length > 0 ? (
                <ChoseActionBar
                  handleChoseListChange={(id) => {
                    handleCheckboxChange(false, id);
                  }}
                  choseList={choseList}
                />
              ) : (
                <ChoseActionBar
                  handleChoseListChange={(id) => {
                    handleCheckboxChange(false, id);
                  }}
                  choseList={[]}
                />
              )}
              <div className="transition-timer">
                时长：
                <InputNumber
                  size="mini"
                  mode="button"
                  max={1}
                  min={0.1}
                  step={0.1}
                  value={globalTransition.duration / 1000}
                  onChange={(e) => {
                    const transitionDuration = e * 1000;
                    handleChangeDuration(transitionDuration);
                  }}
                  defaultValue={0.5}
                  style={{ width: 100, margin: "0 6px" }}
                />
                秒
              </div>
            </div>
            <TransitionGroup
              checkList={choseList}
              data={data}
              handleCheckboxChange={(e, id) => {
                handleCheckboxChange(e, id);
              }}
            />
          </VideoConfigCardContainer>
        </VideoConfigCard>
      </>
    </>
  );
});

export default GlobalTransition;
