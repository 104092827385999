import { useState, useEffect } from "react";
import {
  Tree,
  Input,
  Popover,
  Collapse,
  Button,
  Message,
} from "@arco-design/web-react";
import "../style/selectinput.less";
import company from "@/assets/member/company.png";
import { inject, observer } from "mobx-react";
import { IconSearch } from "@arco-design/web-react/icon";
import ChildeMerbel from "./childeMerbel";
import Nodata from "./Nodata";

const TreeNode = Tree.Node;
const CollapseItem = Collapse.Item;
const Slesctinput = (props: any) => {
  const { team, teamover, HandeoppenClick } = props;

  useEffect(() => {
    team.adddepartment();
  }, [team.ttdepedata]);
  const [TreeData, setTreeData] = useState([]);

  useEffect(() => {
    setTreeData(team.Treedepedata);
    console.log(team.Treedepedata);
  }, [team.Treedepedata]);

  const [inputValue, setInputValue] = useState("");

  const [clickHoveActivetable, setClickHoveActivetable] = useState<Number>(-1);

  function searchData(inputValue) {
    const loop = (data) => {
      const result = [];
      data.forEach((item) => {
        if (item.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) {
          result.push({ ...item });
        } else if (item.children) {
          const filterData = loop(item.children);

          if (filterData.length) {
            result.push({ ...item, children: filterData });
          }
        }
      });
      return result;
    };

    return loop(team.Treedepedata);
  }
  const [radiostate, setradiostate] = useState(null);
  const HandeSubmiClick = () => {
    if (radiostate) {
      teamover.team_transfer_to(radiostate);
      HandeoppenClick();
    } else {
      Message.info("请选择移交团队的人员");
    }
  };
  useEffect(() => {
    if (!inputValue) {
      setTreeData(team.Treedepedata);
    } else {
      const result = searchData(inputValue);
      setTreeData(result);
    }
  }, [inputValue]);

  return (
    <div className="wrapel_contel">
      <div className="Input_childn">
        <Input
          prefix={<IconSearch />}
          className="treeTopSearch"
          onChange={setInputValue}
          placeholder="搜索部门/人员"
        />
      </div>
      <p className="companydiv">{team.team_name}</p>
      <Collapse bordered={false} style={{ maxWidth: 1180 }}>
        {TreeData?.length > 0 &&
          TreeData.map((item, index) => {
            return (
              <CollapseItem
                key={item.key}
                header={
                  <p>
                    {`${item.title}  `}
                    {/* {item?.total ? `(${item.total})` : `(0)`} */}
                  </p>
                }
                name={item.key}>
                {item.total ? (
                  <ChildeMerbel
                    ID={item.key}
                    HandeonChangeradio={setradiostate}
                    value={radiostate}></ChildeMerbel>
                ) : (
                  <></>
                )}
              </CollapseItem>
            );
          })}
      </Collapse>
      {TreeData?.length == 0 && (
        <div style={{ width: "100%", height: "100%", flex: "1" }}>
          <Nodata title={<p style={{ marginTop: 20 }}>暂无成员</p>}></Nodata>
        </div>
      )}
      <div className="btns_contel">
        <Button type="primary" className={"dis_err"} onClick={HandeoppenClick}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            HandeSubmiClick();
          }}>
          确认
        </Button>
      </div>
    </div>
  );
};
export default inject("team", "teamover")(observer(Slesctinput));
