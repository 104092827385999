import logoImg from "@/assets/headimage/logo.png";
import EditText, {
  IforwardRef,
} from "@/pages/CreationSpace/components/EditText/EditText";
import editImg from "@/assets/create-space/edit.png";
import { useRef } from "react";
import { Button } from "@arco-design/web-react";
import VoLongButton from "@/components/VLButton/VLButton";
import "./Header.less";
import { useNavigate } from "react-router-dom";
import MyTask from "@/components/UI/MyTask";
interface IHeader {
  disEdit?: boolean;
  name: string;
  updateTime?: number;
  handleChangeName: (e: string) => void;
  handleSaveBtnClick?: () => void;
  handleResultBtnClick?: () => void;
  mode?: "mixDetail" | "componse";
}

const Header: React.FC<IHeader> = (props) => {
  const {
    disEdit,
    name,
    mode = "mixDetail",
    handleChangeName,
    handleSaveBtnClick,
    handleResultBtnClick,
    updateTime,
  } = props;
  const inputRef = useRef<IforwardRef>(null);
  const navigator = useNavigate();
  return (
    <div className="header">
      <img
        src={logoImg}
        alt=""
        onClick={() => {
          navigator("/work-bench");
        }}
      />
      <div className={"edit-wrapper-container"}>
        <EditText
          disabed={!disEdit}
          ref={inputRef}
          style={{
            width: "auto",
            fontWeight: "600",
            fontSize: "22px",
            color: "#333333",
          }}
          name={name}
          handleInputEndEnter={(e) => {
            handleChangeName(e);
            inputRef.current.toggleIsEdit();
          }}
        />
        {!disEdit ? (
          <img
            src={editImg}
            onClick={() => {
              inputRef.current.toggleIsEdit();
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="cut-detail-action">
        {updateTime ? (
          <div className="time">{updateTime} 已自动保存</div>
        ) : (
          <></>
        )}
        {handleSaveBtnClick ? (
          <Button
            style={{ marginRight: 10 }}
            className={"button"}
            onClick={() => {
              if (handleSaveBtnClick) {
                handleSaveBtnClick();
              }
            }}>
            保存
          </Button>
        ) : (
          <></>
        )}
        {mode === "componse" ? (
          <>
            <MyTask />
          </>
        ) : (
          <></>
        )}
        <VoLongButton
          style={{ marginLeft: "12px" }}
          type="primary"
          onClick={() => {
            if (handleResultBtnClick) {
              handleResultBtnClick();
            }
          }}>
          {mode === "mixDetail" ? "视频混剪" : "合成视频"}
        </VoLongButton>
      </div>
    </div>
  );
};

export default Header;
