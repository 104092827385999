import "./BgChoiceBlock.less";

export interface IBgChoiceBlock {
  isChoice?: boolean;
  img: string;
  style?: React.CSSProperties;
  onClick: () => void;
}

const BgChoiceBlock: React.FC<IBgChoiceBlock> = (props) => {
  const { isChoice, img, style, onClick } = props;
  return (
    <div
      onClick={onClick}
      className="choice-block"
      style={{ ...style, background: isChoice ? "#D8D8D8" : "" }}>
      <img src={img} alt="" />
    </div>
  );
};

export default BgChoiceBlock;
