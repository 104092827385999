import { Input, InputProps } from "@arco-design/web-react";
import { IconSearch } from "@arco-design/web-react/icon";
import "./SmallSearch.less";

const SmallSearch: React.FC<InputProps> = (props) => {
  return (
    <div className="small-search-wrapper bg-search" style={{ width: "100%" }}>
      <Input
        {...props}
        className={"vl-input border-radius-search"}
        size="small"
        style={{ width: "100%" }}
        prefix={<IconSearch />}
        placeholder="搜索歌曲名称/歌手"
      />
    </div>
  );
};

export default SmallSearch;
