import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import FlowerFontCard from "./FlowerFontCard";
import { IFlowerFontItemResponse } from "@/services/mixCutDetail/mixCutDetail";
import _ from "lodash";
import OpenIcon from "../../../OpenDrawer/OpenIocn/OpenIcon";
import "./FlowerFontCardGroup.less";
export interface IFlowerFontCardGroup {
  imgList: IFlowerFontItemResponse[];
  activeEfffect: Record<string, string>;
  handelFlowerFontIdChange: (effect: Record<string, string>) => void;
  maxShowNumber: number;
  style?: React.CSSProperties;
  defaultShowState?: boolean;
}

export interface IFlowerFontCardGroupForward {
  toggle: () => void;
  getFlowerFontCardGroupAllShowState: () => boolean;
}

const FlowerFontCardGroup = forwardRef<
  IFlowerFontCardGroupForward,
  IFlowerFontCardGroup
>((props, ref) => {
  const {
    imgList,
    activeEfffect,
    handelFlowerFontIdChange,
    maxShowNumber,
    defaultShowState,
    style,
  } = props;
  const [allShow, setAllShow] = useState(false);

  const toggle = () => {
    setAllShow(!allShow);
  };

  useImperativeHandle(ref, () => {
    return {
      toggle,
      getFlowerFontCardGroupAllShowState() {
        return allShow;
      },
    };
  });

  useEffect(() => {
    setAllShow(defaultShowState);
  }, [defaultShowState]);

  return (
    <div style={{ flex: 1 }}>
      <div className="flower-font-group-wrapper" style={style}>
        <div className="flower-font-block-wrapper">
          <FlowerFontCard
            onClick={() => {
              handelFlowerFontIdChange({});
            }}
            active={Object.keys(activeEfffect || {}).length === 0}
            img={""}
          />
        </div>
        {(imgList || []).map((item, index) => {
          return allShow || maxShowNumber > index ? (
            <div className="flower-font-block-wrapper" key={index}>
              <FlowerFontCard
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handelFlowerFontIdChange(item.content);
                }}
                active={_.isEqual(item.content, activeEfffect)}
                img={item.url}
              />
            </div>
          ) : (
            <></>
          );
        })}
      </div>

      <OpenIcon
        className="open-wrapper"
        value={allShow}
        toggle={(e) => {
          setAllShow(e);
        }}
        text={allShow ? "收起" : "展开"}
      />
    </div>
  );
});
FlowerFontCardGroup.displayName = "FlowerFontCardGroup";
export default FlowerFontCardGroup;
