import { Checkbox } from "@arco-design/web-react";
import { useEffect, useState } from "react";
import VoiceSlider from "./VoiceSlider/VoiceSlider";
import TimberSelector from "./TimbreConfig/TimbreSelector/TimbreSelector";
import CustomTimbreSelector from "./TimbreConfig/CustomTimbreSelector/CustomTimbreSelector";
import OpenDrawer from "../OpenDrawer/OpenDrawer";
import "./VoiceConfig.less";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import { getTimbre } from "@/services/mixCutDetail/mixCutDetail";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import CustomMusicGroup from "../../BgConfig/BgMusicConfig/BgMusicTabs/CustomMusicGroup/CustomMusicGroup";

export interface IVoiceConfig {
  style: React.CSSProperties;
  speedValue: number;
  pitchValue: number;
  speekerId: string;
  volume: number;
  global: boolean;
  is_global: boolean;
  handleSpeedValueChange: (e: number) => void;
  handlePitchValueChange: (e: number) => void;
  handleSpeekerIdChange: (e: string, name: string) => void;
  handleVolumeChange: (e: number) => void;
  handleGlobalCaptionChange?: (e: boolean) => void;
}

const VoiceConfig: React.FC<IVoiceConfig> = (props) => {
  const {
    style,
    speedValue,
    pitchValue,
    speekerId,
    volume,
    handlePitchValueChange,
    handleSpeedValueChange,
    handleSpeekerIdChange,
    handleVolumeChange,
    is_global,
    global,
    handleGlobalCaptionChange,
  } = props;
  const [state, setState] = useState(true);
  const { data, handleRequest } = useRequest({
    request: getTimbre,
    initSearchData: { type: 1 },
  });
  const [customVoice, setCustomVoice] = useState([]);
  const [collectionCustomVoice, setCollectionCustomVoice] = useState([]);
  const [timbreSelectorId, setTimbreSelector] = useState(1);
  const handleTimbreSelectorChange = (e) => {
    setTimbreSelector(e);
    handleRequest({ type: e });
  };

  useEffect(() => {
    if (data && data.length > 0 && !speekerId) {
      const name = `${data[0].name}_${data[0].speaker_style}`;
      handleSpeekerIdChange(data[0].speaker_id, name);
    }
  }, [data, props.speekerId]);

  return (
    <div style={style}>
      <VideoConfigCard>
        <VideoConfigCardHeader
          headerLeft={
            <>
              <OpenDrawer state={state} handleStateChange={setState} />
              <span
                onClick={() => {
                  setState(!state);
                }}>
                设置配音
              </span>
            </>
          }
          headerRight={
            <>
              {global ? (
                <></>
              ) : (
                <>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={is_global}
                    onChange={(e) => {
                      if (handleGlobalCaptionChange) {
                        handleGlobalCaptionChange(e);
                      }
                    }}
                  />
                  <span>应用到全部镜头</span>
                </>
              )}
            </>
          }></VideoConfigCardHeader>
        <VideoConfigCardContainer style={{ height: state ? "auto" : 0 }}>
          {state ? (
            <>
              <div className="timbre-selector">
                <TimberSelector
                  handleActiveIndex={handleTimbreSelectorChange}
                  activeId={timbreSelectorId}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"语速"}
                  isZero={true}
                  step={0.1}
                  showTicks={true}
                  max={2.5}
                  value={speedValue}
                  handleSliderChange={handleSpeedValueChange}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"音调"}
                  isZero={true}
                  step={0.1}
                  showTicks={true}
                  max={2}
                  value={pitchValue}
                  handleSliderChange={handlePitchValueChange}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"音量"}
                  step={1}
                  showTicks={false}
                  max={100}
                  value={Math.trunc(volume * 100)}
                  handleSliderChange={(e) => {
                    handleVolumeChange(e / 100);
                  }}
                />
              </div>
              <div className="custom-timbre-select-card-group">
                {timbreSelectorId === 4 ? (
                  <>
                    <CustomMusicGroup
                      handleSelfUploadBlockBtnClick={() => {
                        console.log("上传组件");
                      }}
                      handleDeleteCustomMusicItem={() => {
                        console.log("删除组件");
                      }}
                      collectionAudio={collectionCustomVoice}
                      data={customVoice}
                      handleCheckStateChange={() => {
                        console.log("跟新状态");
                      }}
                      multiple={false}
                    />
                  </>
                ) : (
                  <CustomTimbreSelector
                    handleTimbreItemClick={(e) => {
                      const item = data.find((item) => item.speaker_id === e);
                      const name = `${item.name}_${item.speaker_style}`;
                      handleSpeekerIdChange(e, name);
                    }}
                    activeId={speekerId}
                    data={data}
                    min={6}
                  />
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </VideoConfigCardContainer>
      </VideoConfigCard>
    </div>
  );
};

export default VoiceConfig;
