import { Fragment } from "react/jsx-runtime";
import CoverImgCard from "./CoverImgCard/CoverImgCard";
import { ICoverItemResponse } from "@/services/mixCutDetail/mixCutDetail";
import { memo } from "react";
import "./CoverImgGroup.less";
export interface ICoverImgCard {
  coverList: ICoverItemResponse[];
  handleChoseImgChange: (state: boolean, id: number) => void;
  collection: { name: string; id: number }[];
}

const CoverImgGroup: React.FC<ICoverImgCard> = memo((props) => {
  const { coverList, handleChoseImgChange, collection } = props;
  return (
    <div className="cover-img-group-wrapper">
      {(coverList || []).map((item) => {
        return (
          <Fragment key={item.name}>
            <CoverImgCard
              name={item.name}
              img={item.url}
              isChecked={
                (collection || []).findIndex((step) => step.id === item.id) > -1
              }
              handleCheckStateChange={(e) => {
                handleChoseImgChange(e, item.id);
              }}
              id={item.id}
            />
          </Fragment>
        );
      })}
    </div>
  );
});

export default CoverImgGroup;
