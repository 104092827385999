import { useState } from "react";
import { LoadingType } from "./useRequest";

export interface IUserSimpleRequestProps<S, D> {
  request: (data: S) => Promise<D>;
}

export interface IUserSimpleRequestReturn<S, D> {
  handleRequest: (data: S) => void;
  data: D;
  loading: LoadingType;
}

const useSimpleReuqest = <S, D>(
  props: IUserSimpleRequestProps<S, D>,
): IUserSimpleRequestReturn<S, D> => {
  const { request } = props;
  const [data, setData] = useState<D>(null);
  const [loading, setLoading] = useState<LoadingType>("noStart");

  const handleRequest = async (requestData: S) => {
    setLoading("loading");
    try {
      const result = await request(requestData);
      setData(result);
      setLoading("done");
    } catch (error) {
      console.error("Request failed:", error);
      setLoading("error");
    }
  };

  return {
    handleRequest,
    data,
    loading,
  };
};

export default useSimpleReuqest;
