import { IconPlus } from "@arco-design/web-react/icon";
import "./SelfUploadBlock.less";

interface ISelfUploadBlockProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

const SelfUploadBlock: React.FC<ISelfUploadBlockProps> = (props) => {
  const { style, onClick } = props;
  return (
    <div className="self-upload-card" style={style} onClick={onClick}>
      <IconPlus style={{ fontSize: 9 }} />
      <p>添加素材</p>
    </div>
  );
};

export default SelfUploadBlock;
