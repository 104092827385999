import { useRef, useState } from "react";
import { Modal, ModalProps, Slider } from "@arco-design/web-react";
import CustomVideo from "@/pages/CreateVideo/component/VideoCompont/videoany";
import TimelineCanvas from "./TimeLine";
import TimelineZoomControl from "./ZoomControl";
import FrameTimeline from "./FrameTimeline/FrameTimeline";
import "./CutModal.less";
import VideoPreview from "@/components/business/VideoPreview/VideoPreview";

export interface IOpeatorTime {
  start: number;
  duration: number;
}

export interface ICutVideoInfo {
  videoDuration: number;
  fps: number;
  img: string;
  count: number;
  imgWidth: number;
  imgHeight: number;
  videoUrl: string;
  start: number;
  duration: number;
}
const preWidth = 180;
export type CutModal = ICutVideoInfo &
  Omit<ModalProps, "onOk"> & { onOk: (data: IOpeatorTime) => void };

const CutModal: React.FC<CutModal> = (props) => {
  const {
    videoDuration,
    videoUrl,
    fps,
    img,
    count,
    imgWidth,
    imgHeight,
    start,
    duration,
    onOk,
    ...res
  } = props;
  const [zoomLevel, setZoomLevel] = useState(1);
  const showWidth = ((videoDuration / 1000) * preWidth) / count;
  const [opeatorTime, setOpeatorTime] = useState<IOpeatorTime>({
    start: null,
    duration: null,
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <Modal
      {...res}
      onOk={() => {
        if (opeatorTime.duration === null || opeatorTime.start === null) {
          onOk(null);
        } else {
          onOk(opeatorTime);
        }
      }}
      style={{ width: 1380 }}
      className={"vl-modal cut-modal"}
      title="裁剪视频"
      visible={res.visible}>
      {/* <CustomVideo style={{ width: "100%" }} src={videoUrl} /> */}
      <div style={{ width: "100%", background: "red", marginBottom: "20px" }}>
        <VideoPreview url={videoUrl} ref={videoRef} />
      </div>

      <div
        className="time-line-frame-wrapper"
        style={{ overflow: "auto", width: "100%" }}>
        <TimelineCanvas
          durationInMs={videoDuration}
          fps={fps}
          zoomLevel={zoomLevel}
          onTimeUpdate={(e) => {
            videoRef.current.currentTime = e;
          }}
        />

        <FrameTimeline
          totalDurationMs={videoDuration}
          showHeight={30}
          img={img}
          count={count}
          width={imgWidth}
          height={imgHeight}
          zoomLevel={zoomLevel}
          start={start}
          duration={duration}
          onSelectionChange={(start: number, duration: number) => {
            setOpeatorTime({ start, duration });
          }}
          fps={fps}
        />
      </div>
      {zoomLevel}
      <TimelineZoomControl value={zoomLevel} onChange={setZoomLevel} />
    </Modal>
  );
};

export default CutModal;
