import { Slider } from "@arco-design/web-react";
import "./VoiceSlider.less";
import { useState } from "react";
interface IVoiceSlider {
  title: string;
  step: number;
  showTicks: boolean;
  max: number;
  style?: React.CSSProperties;
  isZero?: boolean;
  value: number;
  handleSliderChange: (e: number) => void;
}

const VoiceSlider: React.FC<IVoiceSlider> = (props) => {
  const {
    title,
    step,
    showTicks,
    max,
    style,
    isZero,
    value,
    handleSliderChange,
  } = props;

  const formatNumber = (value: number) => {
    return value % 1 ? value : value + ".0";
  };

  const isMaxValue = () => {
    return max === value;
  };

  return (
    <div style={style} className="timbre-slider-wrapper">
      <div className="title">{title}</div>
      <Slider
        value={value}
        onChange={(e) => {
          handleSliderChange(e as number);
        }}
        style={{ width: "75%" }}
        className={`${isMaxValue() ? "full" : ""}`}
        max={max}
        step={step}
        showTicks={showTicks}
      />
      <span className="value">{isZero ? formatNumber(value) : value}</span>
    </div>
  );
};

export default VoiceSlider;
