import VideoGroupBlock, {
  IVideoGroupBlockProps,
} from "./VideoGroupBlock/VideoGroupBlock";
import { IconPlus } from "@arco-design/web-react/icon";
import Title from "../UIComponents/Title/Title";
import MixCutConfigWrapper from "../UIComponents/MixCutConfigWrapper/MixCutConfigWrapper";
import { observer } from "mobx-react";
import { useState } from "react";
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import allowTypeStore from "@/stores/allowTypeStore";
import { toJS } from "mobx";
import { IMaterialFile, ISences } from "@/stores/mixCutDetailStore/type";
import { useParams } from "react-router-dom";
import { bindingVideo } from "@/services/mixCutDetail/mixCutDetail";
import useTitle from "@/useStore/mixCutDetail/useTitle";
import CutModal, {
  ICutVideoInfo,
  IOpeatorTime,
} from "./VideoGroupBlock/CutModal/CutModal";
import useMaterialPlayInfo from "@/useStore/mixCutDetail/useMaterialPlayInfo";
import {
  IMaterialDetail,
  getSimpleMedia,
} from "@/services/createSpace/graphQ/getSimpleMedia";
import { Button, Message, Modal } from "@arco-design/web-react";
import useMixCutDetailCheck from "@/useStore/mixCutDetail/mixCutDetailCheck";
import {
  addCustomScriptRefenceCount,
  addHotScriptRefenceCount,
  toIdGetHotScript,
  toIdGetScript,
} from "@/services/createSpace/script";
import SelectScriptMoadl from "@/components/business/SelectScriptMoadl";
import useParseScript from "@/useStore/mixCutDetail/useParseScript";
import importScriptImg from "@/assets/cut-mix-detail/import.png";
import modalTipImg from "@/assets/cut-mix-detail/modal-tip.png";

import "./VideoGroup.less";
export interface IVideoGroupProps
  extends Omit<
    IVideoGroupBlockProps,
    "handleUploadBtnClick" | "handleMetarialEditBtnClick"
  > {
  handleVideoGroupAdd: () => void;
  handleChangeVideoItemStartAndDuration: (
    groupIndex: number,
    index: number,
    data: IOpeatorTime,
  ) => void;
}

const handleUplodaMaterialData = (
  e: IMaterialDetail[],
  sences: IMaterialFile[],
): IMaterialFile[] => {
  return [
    ...sences,
    ...e
      .map((item) => {
        return {
          id: item.id,
          clip_id: Math.floor(Date.now() + Math.random() * 10000),
          media_id: item.media_id,
          type: 1 as const,
          width: item.width,
          height: item.height,
          start: 0,
          duration: item.duration,
          is_cropped: false,
          speed: 1 as const,
        };
      })
      .filter((item) => {
        return sences.findIndex((step) => step.id === item.id) === -1;
      }),
  ];
};

const getAllMaterailId = (data: ISences[]): string[] => {
  return Array.from(
    new Set(
      data.flatMap((item) => item.materials.map((material) => material.id)),
    ),
  );
};

const VideoGroup: React.FC<IVideoGroupProps> = observer((props) => {
  const {
    data,
    handleDataChange,
    handleVideoGroupAdd,
    handleVideoGroupDelete,
    handleTitleBtnClick,
    handleCaptionBtnClick,
    handleTransitionBtnClick,
    handleProjectVideoChange,
    // handlePlayerCaption,
    handleChangeVideoItemStartAndDuration,
    // handlePlayerCaptionStop,
    handleHeaderItemClick,
  } = props;
  const { id } = useParams();
  const { toIndexDeleteSencesError } = useMixCutDetailCheck();
  const [radioType, setRadioType] = useState<"material" | "local">("material");
  const [uploadVisible, setUploadVisible] = useState(false);
  const { parseCutsomScript, parseHotScipt } = useParseScript();
  const [editIndex, setEditIndex] = useState(0);
  const [editAudioVisible, setEditAudioVisible] = useState(false);
  const [tipModalState, setTipModalState] = useState(false);
  const [importScriptModalVisible, setImportScriptModalVisible] =
    useState(false);
  const [editMaterialPostion, setEditMaterialPostion] = useState<{
    groupIndex: number;
    index: number;
  }>({ groupIndex: null, index: null });
  const { handleTitleClear } = useTitle();
  const { handleMaterialItemAdd } = useMaterialPlayInfo();
  const [cutVideoInfo, setCutVideoInfo] = useState<ICutVideoInfo>(null);

  function parseImageInfo(filePath) {
    const regex = /_(\d+)x(\d+)_([\d]+)_tile/;
    const match = filePath.match(regex);
    if (match) {
      const width = parseInt(match[1], 10);
      const height = parseInt(match[2], 10);
      const count = parseInt(match[3], 10);
      return {
        width: width,
        height: height,
        count: count,
      };
    } else {
      return null;
    }
  }

  const handleMaterialModalBtnOkClick = async (e: any[]) => {
    if (e && e.length > 0) {
      toIndexDeleteSencesError(editIndex);
    } else {
      setUploadVisible(false);
      return;
    }
    try {
      e.forEach((item) => {
        handleMaterialItemAdd(item.id, {
          url: item.play_info.play_url,
          cover: item.play_info.cover_url,
        });
      });
      await bindingVideo(
        id,
        e.map((item) => item.id),
      );
    } catch (e) {
      console.log(e);
    } finally {
      handleProjectVideoChange(
        editIndex,
        handleUplodaMaterialData(e, data[editIndex].materials),
      );
      setUploadVisible(false);
    }
  };

  const handleMetarialEditBtnClick = async (
    groupIndex: number,
    index: number,
  ) => {
    try {
      setEditMaterialPostion({ groupIndex, index });
      const id = data[groupIndex].materials[index].id;
      const result = await getSimpleMedia(Number(id));
      const { count, width, height } = parseImageInfo(result.frame_snapshot);
      setCutVideoInfo({
        start: data[groupIndex].materials[index].start,
        duration: data[groupIndex].materials[index].duration,
        videoDuration: Number(result.duration),
        fps: Math.round(Number(result.fps)),
        img: result.frame_snapshot,
        count: count,
        imgWidth: width,
        imgHeight: height,
        videoUrl: result.play_info.play_url,
      });
      setEditAudioVisible(true);
    } catch (e) {
      Message.error("请求接口失败，请刷新后重试");
    }
  };

  return (
    <MixCutConfigWrapper
      style={{
        flex: 1,
        minWidth: "500px",
        overflowX: "scroll",
        borderTop: "none",
      }}>
      <div className="mix-cut-detail-videogroup-header">
        <Title
          title={"镜头内容与配置"}
          desc={"为各个镜头组配置素材、文案、时长等信息"}
        />
        <Button
          type={"text"}
          style={{
            border: "1px solid #666666",
            color: "#666666",
          }}
          onClick={() => {
            setTipModalState(true);
          }}>
          <img
            src={importScriptImg}
            alt=""
            style={{
              width: "12px",
              marginRight: 6,
              position: "relative",
              left: "-1px",
              top: "3px",
            }}
          />
          导入脚本
        </Button>
      </div>
      <div className="cut-mix-detail-videogroup-contaienr">
        <VideoGroupBlock
          handleHeaderItemClick={handleHeaderItemClick}
          handleMetarialEditBtnClick={handleMetarialEditBtnClick}
          handleVideoGroupDelete={handleVideoGroupDelete}
          data={data}
          handleProjectVideoChange={handleProjectVideoChange}
          handleDataChange={handleDataChange}
          handleTitleBtnClick={(e) => {
            handleTitleClear();
            handleTitleBtnClick(e);
          }}
          handleCaptionBtnClick={(e) => {
            handleCaptionBtnClick(e);
          }}
          handleTransitionBtnClick={handleTransitionBtnClick}
          handleUploadBtnClick={(type, index) => {
            setEditIndex(index);
            setRadioType(type);
            setUploadVisible(true);
          }}
        />
      </div>
      {(data || []).length > 14 ? (
        <></>
      ) : (
        <div className={"bottom-wrapper"}>
          <div
            className="add-button"
            style={{ marginTop: 10 }}
            onClick={() => {
              handleVideoGroupAdd();
            }}>
            <IconPlus />
            新增镜头组
          </div>
        </div>
      )}

      {uploadVisible ? (
        <MaterialFileModal
          disabledRecordStation={true}
          handleOkBtnClick={handleMaterialModalBtnOkClick}
          onCancel={() => {
            setUploadVisible(false);
          }}
          maxCollectionLength={15}
          defaultCollection={data[editIndex].materials.map((item) => item.id)}
          importCollection={getAllMaterailId(data)}
          visible={uploadVisible}
          allowMetarial={[1, 4]}
          defalutValue={radioType}
          allowType={toJS(
            allowTypeStore.getAllVideoTypeString(),
          )}></MaterialFileModal>
      ) : (
        <></>
      )}
      {editAudioVisible ? (
        <CutModal
          visible={editAudioVisible}
          {...cutVideoInfo}
          onCancel={() => {
            setEditAudioVisible(false);
            setEditMaterialPostion({ groupIndex: null, index: null });
          }}
          onOk={(time) => {
            if (time) {
              handleChangeVideoItemStartAndDuration(
                editMaterialPostion.groupIndex,
                editMaterialPostion.index,
                time,
              );
            }
            setEditAudioVisible(false);
          }}
        />
      ) : (
        <></>
      )}
      {importScriptModalVisible ? (
        <SelectScriptMoadl
          visible={importScriptModalVisible}
          handleChangeMoadl={() => {
            setImportScriptModalVisible(false);
          }}
          minHeight={600}
          handleImportScript={async (id, type) => {
            if (type === "custom") {
              const data = await toIdGetScript(id);
              parseCutsomScript(data.content);
              addCustomScriptRefenceCount(id as number);
            } else {
              const data = await toIdGetHotScript(id);
              parseHotScipt(data.content);
              addHotScriptRefenceCount(id as number);
            }
            setImportScriptModalVisible(false);
          }}
        />
      ) : (
        <></>
      )}
      <Modal
        style={{ width: 464 }}
        className={"modal-script-export"}
        title={
          <div className="modal-script-header">
            <img src={modalTipImg} alt="" />
            提示
          </div>
        }
        onOk={() => {
          setImportScriptModalVisible(true);
          setTipModalState(false);
        }}
        onCancel={() => {
          setTipModalState(false);
        }}
        visible={tipModalState}>
        <div className="modal-script-content">
          当前页面内容未保存，导入脚本后将全部被替换是否直接导入?
        </div>
      </Modal>
    </MixCutConfigWrapper>
  );
});

export default VideoGroup;
