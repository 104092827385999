import { Fragment } from "react/jsx-runtime";
import CoverImgCard from "../Components/CoverImgGroup/CoverImgCard/CoverImgCard";
import SelfUploadBlock from "../Components/SelfUploadBlock/SelfUploadBlock";

interface ISelfCoverGroupProps {
  handleUploadBlockBtnClick: () => void;
  handleChoseImgChange: (state: boolean, id: number) => void;
  data: any[];
  handleDeleteItem: (id: number) => void;
  collection: any[];
}

const SelfCoverGroup: React.FC<ISelfCoverGroupProps> = (props) => {
  const {
    handleUploadBlockBtnClick,
    data,
    collection,
    handleChoseImgChange,
    handleDeleteItem,
  } = props;
  return (
    <div className="cover-img-group-wrapper">
      <SelfUploadBlock
        onClick={() => {
          handleUploadBlockBtnClick();
        }}
        style={{ marginBottom: 15, marginLeft: 18, width: 126, height: 160 }}
      />
      {data.map((item) => {
        return (
          <Fragment key={item.id}>
            <CoverImgCard
              handleDeleteBtnClick={handleDeleteItem}
              name={item.name}
              img={item.play_info.cover_url}
              isChecked={
                collection.findIndex((step) => step.id === item.id) > -1
              }
              handleCheckStateChange={(e) => {
                handleChoseImgChange(e, item.id);
              }}
              id={item.id}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default SelfCoverGroup;
