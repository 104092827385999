import { Checkbox } from "@arco-design/web-react";
import deleteImg from "@/assets/cut-mix-detail/white_delete.png";
import "./CoverImgCard.less";

interface ICoverImgOrMp3Card {
  id: number;
  style?: React.CSSProperties;
  isChecked: boolean;
  name: string;
  img: string;
  handleCheckStateChange: (e: boolean) => void;
  handleDeleteBtnClick?: (id: number) => void;
}

const CoverImgCard: React.FC<ICoverImgOrMp3Card> = (props) => {
  const {
    isChecked,
    handleCheckStateChange,
    style,
    id,
    img,
    name,
    handleDeleteBtnClick,
  } = props;
  return (
    <div
      style={style}
      className={`${isChecked ? "active" : ""} cover-card-wrapper `}>
      <img className="cover-img" src={img} alt="" />
      <div className="checkbox-wrapper">
        <Checkbox
          style={{ width: 14, height: 14 }}
          checked={isChecked}
          onChange={(e) => {
            handleCheckStateChange(e);
          }}></Checkbox>
      </div>
      <div className="delete_wrapper">
        <img
          src={deleteImg}
          alt=""
          onClick={() => {
            if (handleDeleteBtnClick) {
              handleDeleteBtnClick(id);
            }
          }}
        />
      </div>
      <p>{name}</p>
    </div>
  );
};

export default CoverImgCard;
