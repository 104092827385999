import CoverCard, { ICoverCardProps } from "./CoverCard/CoverCard";
import CheckBoxHoc from "@/components/business/MaterialFileModal/CheckBoxHoc/CheckBoxHoc";
import { IBgResponse } from "@/services/mixCutDetail/mixCutDetail";
import { observer } from "mobx-react";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import "./CoverGroup.less";
export interface ICoverGroupProps {
  data: ((ICoverCardProps & { id: number; name: string }) | IMaterialDetail)[];
  style?: React.CSSProperties;
  handleCoverChange: (e: boolean, id: number) => void;
  activeIds: IBgResponse[];
}

const CoverGroup: React.FC<ICoverGroupProps> = observer((props) => {
  const { data, handleCoverChange, activeIds } = props;
  return (
    <div className="cover-group">
      {(data || []).map((item) => {
        return (
          <div key={item.id} style={{ marginRight: 3, marginBottom: 10 }}>
            <CheckBoxHoc
              style={{ borderRadius: 4 }}
              checked={
                (activeIds || []).findIndex((step) => item.id === step.id) > -1
              }
              onCheckedChange={(e) => {
                handleCoverChange(e, Number(item.id));
              }}>
              <CoverCard
                active={
                  (activeIds || []).findIndex((step) => item.id === step.id) >
                  -1
                }
                url={(item as ICoverCardProps).url}></CoverCard>
            </CheckBoxHoc>
            <p>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
});

export default CoverGroup;
