import { useEffect, useState } from "react";
import { Table, Badge } from "@arco-design/web-react";
import { Pagination } from "@arco-design/web-react";
import Operate from "./Operate";
import { observer, inject } from "mobx-react";
import Roleactive from "./roleactive";
import Nodata from "@/pages/TeamOverview/componts/Nodata";

const getAllDataStatus = (opt) => {
  if (opt == undefined) opt = 3;
  const actionMap = [
    {
      stata: 1,
    },
    { status: "processing", color: "#165DFF", text: "待加入" },
    { status: "error", color: "#F53F3F", text: "已拒绝" },
    { status: "success", color: "#247937 ", text: "正常" },
    { status: "default", color: "#86909C", text: "已停用" },
    // 可以继续添加更多映射
  ];
  const { status, color, text } = actionMap[opt];
  return (
    <>
      <Badge
        status={
          status as "success" | "processing" | "default" | "error" | "warning"
        }
        style={{ marginRight: 11 }}
      />
      <span style={{ color }}>{text}</span>
    </>
  );
};

const FormTabel = (props: any) => {
  const { team } = props;
  const columns = [
    {
      title: "姓名",
      dataIndex: "member_name",
    },
    {
      title: "手机号码",
      dataIndex: "member_phone",
    },
    {
      title: "部门",
      dataIndex: "department_name",
    },
    {
      title: "角色",
      dataIndex: "role_id",
      render: (col, item, index) => {
        return Roleactive.string(item.role_id);
      },
    },
    {
      title: "状态",
      dataIndex: "state",
      render: (col, item, index) => {
        return getAllDataStatus(item.state);
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      render: (a, b, c) => {
        return <Operate data={b} team={team}></Operate>;
      },
    },
  ];
  const [Data, setDatalist] = useState(team.meberdata);
  const [data, setData] = useState(team.meberdata);
  const merbersum = team.merbersum;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  function onChangeTable(current, pageSize) {
    team.handeselect(current, pageSize);
  }

  useEffect(() => {
    setDatalist(team.meberdata);
    setData(team.meberdata);
  }, [team.meberdata]);

  return (
    <>
      <Table
        noDataElement={<Nodata></Nodata>}
        style={{ marginBottom: "40px" }}
        loading={loading}
        columns={columns}
        data={data}
        pagination={false}
        onChange={onChangeTable}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            team.selectmeberactive = selectedRowKeys;
            console.log(111);

            team.role_idsetlect(selectedRows || false);
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        rowClassName={(record, index) => {
          return selectedRowKeys.includes(record.key) ? "selected-row" : "";
        }}
        renderPagination={(paginationNode) => (
          <div
            style={{
              marginTop: 10,
            }}>
            {paginationNode}
          </div>
        )}
      />

      <Pagination
        showTotal={(total) => `共 ${total}条`} //是否显示数据总数
        total={merbersum ? merbersum : 0} //                       数据总数
        // hideOnSinglePage  // 是否在一页的时候 隐藏
        showJumper //	是否显示快速跳转到某页，在 simple 模式下默认为 true	boolean
        sizeCanChange //	是否可以改变每页条数
        onChange={(current, pageSize) => {
          onChangeTable(current, pageSize);
        }}
        // defaultCurrent={1}
        current={team.topmeberdata.page}
        style={{
          width: "-webkit-fill-available",
          // marginTop: "5px",
          marginRight: "20px",
          position: "fixed",
          bottom: "0",
          height: "40px",
          backgroundColor: "#FFFFFF",
          
          padding: "0 10px",
        }}
      />
    </>
  );
};
export default inject("team")(observer(FormTabel));
