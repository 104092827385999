import { useState } from "react";
import { SketchPicker } from "react-color";
import "./ColorLineImgBlock.less";
interface IColorLineImgBlockProps {
  img: string;
  color?: string;
  style?: React.CSSProperties;
  handleColorChange: (string) => void;
  imgStyle?: React.CSSProperties;
}

const ColorLineImgBlock: React.FC<IColorLineImgBlockProps> = (props) => {
  const { img, color, style, handleColorChange, imgStyle } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [pickColorPlanePosition, setPickColorPlanePosition] = useState<{
    x: number;
    y: number;
  }>({ x: null, y: null });
  const handleChangeComplete = (color) => {
    handleColorChange(color.hex);
    setDisplayColorPicker(false);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  return (
    <div
      style={style}
      className="color-line-block"
      onClick={(e) => {
        const { clientX, clientY } = e;
        setPickColorPlanePosition({ x: clientX, y: clientY });
        setDisplayColorPicker(true);
      }}>
      <img src={img} style={imgStyle} alt="" />
      <div
        className="line"
        style={{
          background: color ? color : "black",
          borderColor: color ? color : "black",
        }}></div>
      {displayColorPicker ? (
        <div
          style={{
            position: "fixed",
            zIndex: 200,
            top: pickColorPlanePosition.y - 200,
            left: pickColorPlanePosition.x + 20,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ColorLineImgBlock;
