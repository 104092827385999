import { useContext, useEffect, useState } from "react";
import LineFontConfig from "../VideoConfig/VoiceConfig/LineFontConfig/LineFontConfig";
import VoiceConfig from "../VideoConfig/VoiceConfig/VoiceConfig";
import VoiceLine from "../VideoConfig/VoiceLine/VoiceLine";
import { mixCutDetailContext } from "../MixCutDetail";
import { convertKeysToSnakeCase } from "@/utils/tool";
import useVideoGroup from "@/useStore/mixCutDetail/useVideoGroup";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import { observer } from "mobx-react";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import usePreviewConfig from "@/useStore/preview/usePreview";

const CaptionConfig = observer(() => {
  const { projectCaption } = useVideoGroup();
  const {
    handleProjectCaptionToIndexChange,
    handleProjectCaptionItemAddItem,
    hanldeProjectCaptionItemDeleteItem,
  } = useCaption();
  const { handlePreviewCaptionChange, handleClipIdChange } = usePreviewConfig();
  const { global } = useMixCutDetail();
  const { videoGroupActive } = useContext(mixCutDetailContext);
  const [captionEditorIndex, setCaptionEditorIndex] = useState(0);

  useEffect(() => {
    handlePreviewCaptionChange(
      ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex] ||
        null,
    );
    handleClipIdChange(null);
  }, [captionEditorIndex]);

  useEffect(() => {
    setCaptionEditorIndex(0);
  }, [videoGroupActive]);
  return (
    <>
      <VoiceLine
        ids={((projectCaption || [])[videoGroupActive] || []).map(
          (item) => item?.id,
        )}
        captionEditorIndex={captionEditorIndex}
        onCaptionEditorIndexChange={(e) => {
          setCaptionEditorIndex(e);
        }}
        handleAddButtonClick={() => {
          handleProjectCaptionItemAddItem(videoGroupActive);
        }}
        handleDeleteButtonClick={(index) => {
          hanldeProjectCaptionItemDeleteItem(videoGroupActive, index);
        }}
        onChange={(e, str: any, playContent) => {
          const newProjectCaptionItem = {
            ...projectCaption[videoGroupActive][captionEditorIndex],
          };
          newProjectCaptionItem.content = e;
          newProjectCaptionItem._content_json = str;
          newProjectCaptionItem._preview = playContent;
          handleProjectCaptionToIndexChange(
            videoGroupActive,
            captionEditorIndex,
            newProjectCaptionItem,
          );
        }}
        value={((projectCaption || [])[videoGroupActive] || []).map(
          (item) => item?._content_json,
        )}
        style={{ marginTop: 13 }}
      />
      <VoiceConfig
        handleGlobalCaptionChange={(e) => {
          if (projectCaption?.length > 0) {
            const newProjectCaptionItem = {
              ...(projectCaption || [])[videoGroupActive][captionEditorIndex],
            };
            newProjectCaptionItem.voice_setting.is_global = e;
            handleProjectCaptionToIndexChange(
              videoGroupActive,
              captionEditorIndex,
              newProjectCaptionItem,
            );
          }
        }}
        global={global}
        is_global={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.voice_setting?.is_global
        }
        style={{ marginTop: 13 }}
        speedValue={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.voice_setting?.speed_ratio
        }
        pitchValue={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.voice_setting?.pitch_ratio
        }
        speekerId={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.voice_setting?.speaker_id
        }
        volume={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.voice_setting?.volume_ratio
        }
        handleSpeedValueChange={(e) => {
          if (projectCaption?.length > 0) {
            const newProjectCaptionItem = {
              ...(projectCaption || [])[videoGroupActive],
            }[captionEditorIndex];
            newProjectCaptionItem.voice_setting.speed_ratio = e;
            handleProjectCaptionToIndexChange(
              videoGroupActive,
              captionEditorIndex,
              newProjectCaptionItem,
            );
          }
        }}
        handlePitchValueChange={(e) => {
          if (projectCaption?.length > 0) {
            const newProjectCaptionItem = {
              ...(projectCaption || [])[videoGroupActive],
            }[captionEditorIndex];
            newProjectCaptionItem.voice_setting.pitch_ratio = e;
            handleProjectCaptionToIndexChange(
              videoGroupActive,
              captionEditorIndex,
              newProjectCaptionItem,
            );
          }
        }}
        handleSpeekerIdChange={(e, name) => {
          if (
            projectCaption &&
            (projectCaption[videoGroupActive] || [])[captionEditorIndex]
          ) {
            const newProjectCaptionItem = {
              ...(projectCaption || [])[videoGroupActive],
            }[captionEditorIndex];
            newProjectCaptionItem.voice_setting.speaker_id = e;
            newProjectCaptionItem.voice_setting.speaker_name = name;
            handleProjectCaptionToIndexChange(
              videoGroupActive,
              captionEditorIndex,
              newProjectCaptionItem,
            );
          }
        }}
        handleVolumeChange={(e) => {
          if (projectCaption?.length > 0) {
            const newProjectCaptionItem = {
              ...(projectCaption || [])[videoGroupActive],
            }[captionEditorIndex];
            newProjectCaptionItem.voice_setting.volume_ratio = e;
            handleProjectCaptionToIndexChange(
              videoGroupActive,
              captionEditorIndex,
              newProjectCaptionItem,
            );
          }
        }}
      />
      <LineFontConfig
        data={
          ((projectCaption || [])[videoGroupActive] || [])[captionEditorIndex]
            ?.text_setting
        }
        global={global}
        style={{ marginTop: 13 }}
        handleTextSettingChange={(e) => {
          const newProjectCaptionItem = {
            ...projectCaption[videoGroupActive],
          }[captionEditorIndex];
          newProjectCaptionItem.text_setting = convertKeysToSnakeCase(e);
          handleProjectCaptionToIndexChange(
            videoGroupActive,
            captionEditorIndex,
            newProjectCaptionItem,
          );
        }}
      />
    </>
  );
});

export default CaptionConfig;
