import { useEffect, useState } from "react";
import TitleGroupTag from "./TitleGroupTag/TitleGroupTag";
import { useEditor } from "@tiptap/react";
import { IconDoubleLeft, IconDoubleRight } from "@arco-design/web-react/icon";
import "./TitleGroup.less";
export interface TitleItemProps {
  title: string;
}

export interface ITitleGroup {
  style?: React.CSSProperties;
  data: TitleItemProps[];
  isEdit?: boolean;
  active?: number;
  handleAddBtnClick?: () => void;
  handleDeleteBtnClick?: (index: number) => void;
  handleActiveIndexChange?: (index: number) => void;
  defaultValue?: string;
  titleCopy?: string;
}

const getIsActive = (index: number, countLength: number) => {
  return (state: "left" | "right") => {
    if ((index === 0 || countLength === 1) && state === "left") {
      return false;
    }
    if (index === countLength - 1 && state === "right") {
      return false;
    }
    return true;
  };
};

const TitleGroup: React.FC<ITitleGroup> = (props) => {
  const {
    data,
    style,
    handleAddBtnClick,
    handleDeleteBtnClick,
    isEdit,
    defaultValue,
    handleActiveIndexChange,
    titleCopy,
    active,
  } = props;

  return (
    <div className="title-group-tag-wrapper" style={style}>
      <div className="title-group-container">
        <TitleGroupTag
          isEdit={isEdit}
          titleList={data.map((item) => item.title)}
          activeIndex={active}
          handleTitleDelete={(index: number) => {
            handleDeleteBtnClick(index);
          }}
          handleActiveIndex={(e) => {
            handleActiveIndexChange(e);
          }}
        />
      </div>

      {handleAddBtnClick ? (
        <>
          <div className="button-group">
            <span
              className={`button-group-block-wrapper ${getIsActive(active, data.length)("left") ? "active" : ""}`}
              style={{ marginRight: 10 }}>
              <IconDoubleLeft
                onClick={() => {
                  if (data.length === 0 || active === 0) {
                    return;
                  }
                  handleActiveIndexChange(active - 1);
                }}
              />
            </span>
            <span
              className={`button-group-block-wrapper ${getIsActive(active, data.length)("right") ? "active" : ""}`}>
              <IconDoubleRight
                onClick={() => {
                  if (data.length === 0 || active === data.length - 1) {
                    return;
                  }
                  handleActiveIndexChange(active + 1);
                }}
              />
            </span>
          </div>
          <span
            style={{ cursor: "pointer" }}
            className="add-btn"
            onClick={() => {
              handleAddBtnClick();
            }}>
            {titleCopy ? titleCopy : "添加标题组"}
          </span>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TitleGroup;
