import { Fragment } from "react/jsx-runtime";
import TitleGroupTagItem from "./TitleGroupTagItem/TitleGroupTagItem";
import "./TitleGroupTag.less";
export interface ITitleGroupTag {
  style?: React.CSSProperties;
  isEdit?: boolean;
  titleList: string[];
  activeIndex: number;
  handleActiveIndex: (index: number) => void;
  handleTitleDelete?: (index: number) => void;
}

const TitleGroupTag: React.FC<ITitleGroupTag> = (props) => {
  const {
    style,
    titleList,
    activeIndex,
    handleTitleDelete,
    handleActiveIndex,
    isEdit,
  } = props;
  return (
    <>
      <div className="video-cut-title-wrapper" style={style}>
        {titleList.map((item, index) => {
          return (
            <Fragment key={item + index}>
              <TitleGroupTagItem
                closeState={isEdit}
                active={index === activeIndex}
                title={item}
                onClick={() => {
                  handleActiveIndex(index);
                }}
                handleDeleteBtnClick={() => {
                  handleTitleDelete(index);
                }}
              />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default TitleGroupTag;
