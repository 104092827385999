import { ReactNode } from "react";
import "./MixCutConfigWrapper.less";

export interface IMixCutConfigWrapper {
  children?: ReactNode;
  style?: React.CSSProperties;
}

const MixCutConfigWrapper: React.FC<IMixCutConfigWrapper> = (props) => {
  const { children, style } = props;
  return (
    <div className="mix-cut-config-wrapper" style={{ ...style }}>
      {children}
    </div>
  );
};

export default MixCutConfigWrapper;
