import "./FlowerFontCard.less";

interface IFlowerFontCard {
  style?: React.CSSProperties;
  active: boolean;
  img: string;
  onClick: () => void;
}

const FlowerFontCard: React.FC<IFlowerFontCard> = (props) => {
  const { active, img, onClick, style } = props;
  return (
    <div
      style={style}
      onClick={onClick}
      className={`${active ? "active" : ""} flower-img-block`}>
      {img ? <img src={img} /> : "无"}
    </div>
  );
};

export default FlowerFontCard;
