import transformLinkImg from "@/assets/cut-mix-detail/transform_link.png";
import "./VideoItemTransition.less";

export interface IVideoItemTransition {
  style?: React.CSSProperties;
  onClick: () => void;
  number?: number;
}

const VideoItemTransition: React.FC<IVideoItemTransition> = (props) => {
  const { style, onClick, number } = props;
  return (
    <div className="transition-icon-wrapper" style={style}>
      <div className="transition-icon-container">
        <img
          src={transformLinkImg}
          alt=""
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
        {number ? <div className="wrapper-number">{number}</div> : <></>}
      </div>
    </div>
  );
};

export default VideoItemTransition;
