import { useEffect, useRef } from "react";
import { createEditor } from "./editorUtil";
import "./index.less";
import { getEditingSticker } from "@/services/videoEditing/video";

function VideoEditing() {
  const containerRef = useRef(null);

  // 函数用于加载外部脚本
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      // 检查脚本是否已经存在
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        resolve(true);
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      script.onload = () => {
        // console.log(`脚本 ${src} 加载完成`);
        resolve(true);
      };

      script.onerror = () => {
        // console.error(`脚本 ${src} 加载失败`);
        reject(new Error(`脚本 ${src} 加载失败`));
      };

      document.head.appendChild(script);
    });
  };

  // 函数用于加载外部样式表
  const loadCSS = (href) => {
    return new Promise((resolve, reject) => {
      // 检查样式表是否已经存在
      const existingLink = document.querySelector(`link[href="${href}"]`);
      if (existingLink) {
        resolve(true);
        return;
      }

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;

      link.onload = () => {
        // console.log(`样式表 ${href} 加载完成`);
        resolve(true);
      };

      link.onerror = () => {
        // console.error(`样式表 ${href} 加载失败`);
        reject(new Error(`样式表 ${href} 加载失败`));
      };

      document.head.appendChild(link);
    });
  };

  useEffect(() => {
    let isMounted = true; // 防止组件卸载后仍尝试更新

    const loadAssetsAndInit = async () => {
      try {
        // 并行加载脚本和样式表
        await Promise.all([
          loadScript(
            "https://g.alicdn.com/thor-server/video-editing-websdk/5.0.0/index.js",
          ),
          loadCSS(
            "https://g.alicdn.com/thor-server/video-editing-websdk/5.0.0/index.css",
          ),
        ]);

        if (!isMounted) return; // 组件已卸载，停止执行

        if (!containerRef.current) {
          console.log("containerRef.current 为 null");
          return;
        }

        const { init, destroy } = createEditor({
          container: containerRef.current,
        });

        try {
          init();
          console.log("编辑器初始化成功");
        } catch (error) {
          console.error("编辑器初始化失败:", error);
        }

        // 清理函数，组件卸载时销毁编辑器
        return () => {
          try {
            destroy();
            console.log("编辑器销毁成功");
          } catch (error) {
            console.error("编辑器销毁失败:", error);
          }
        };
      } catch (error) {
        console.error("外部资源加载失败:", error);
      }
    };

    loadAssetsAndInit();

    // 清理函数，确保在组件卸载时不会进行状态更新
    return () => {
      isMounted = false;
    };
  }, []); // 空依赖数组，确保只在挂载和卸载时执行

  return (
    <div className="Editor">
      <div ref={containerRef} style={{ height: "100vh" }} />
    </div>
  );
}

export default VideoEditing;
