import "./OpenIcon.less";

export interface IOpenIconProps {
  toggle: (e: boolean) => void;
  value: boolean;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
}

const OpenIcon: React.FC<IOpenIconProps> = (props) => {
  const { value, toggle, text, className, style } = props;

  return (
    <div
      className={`${className} open-icon-wrapper`}
      style={style}
      onClick={() => {
        toggle(!value);
      }}>
      <span>{text}</span>
      <div className={`${value ? "open" : "close"}`}></div>
    </div>
  );
};

export default OpenIcon;
