import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import "./BgColorConfig.less";
import BgColorChoseContainer from "./BgColorChoseContainer/BgColorChoseContainer";
import { observer } from "mobx-react";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
const BgColorConfig = observer(() => {
  const { bgColor } = useCoverAndBg();
  return (
    <VideoConfigCard>
      <VideoConfigCardHeader
        style={{
          marginTop: 13,
          borderRadius: 8,
          borderBottom: "1px solid #EEEEEE",
        }}
        headerLeft={
          <>
            背景颜色
            <div
              className="header-color-block"
              style={{ background: bgColor }}></div>
          </>
        }
        headerRight={""}
      />
      <VideoConfigCardContainer>
        <BgColorChoseContainer />
      </VideoConfigCardContainer>
    </VideoConfigCard>
  );
});
export default BgColorConfig;
