// 权限路由组件
import { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

interface Props {
  children: ReactElement;
}
const createIntercept = ({ children }: Props) => {
  const location = useLocation();
  const params = useParams();

  if (!location.state && !location.state?.isCreated && !params.id) {
    window.history.back();
  } else {
    return <>{children}</>;
  }
};

export default createIntercept;
