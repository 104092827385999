import { useState, useEffect } from "react";
import { Tree, Input, Popover } from "@arco-design/web-react";
import "../index.less";
import company from "@/assets/member/company.png";
import { inject, observer } from "mobx-react";

const TreeNode = Tree.Node;

const Memberdepartments = (props: any) => {
  const { team } = props;

  useEffect(() => {
    team.adddepartment();
  }, [team.ttdepedata]);

  const [TreeData, setTreeData] = useState([]);
  useEffect(() => {
    setTreeData(team.Treedepedata);
  }, [team.Treedepedata]);
  const [inputValue, setInputValue] = useState("");

  const handelClickTree = (treeItem) => {
    setClickHoveActivetable(treeItem[0]);
    team.handeSelctactiveDep(treeItem[0]);
    team.handeselecttdata("部门", treeItem[0]);
    
  };
  const [clickHoveActivetable, setClickHoveActivetable] = useState<Number>(-1);

  function searchData(inputValue) {
    const loop = (data) => {
      const result = [];
      data.forEach((item) => {
        if (item.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) {
          result.push({ ...item });
        } else if (item.children) {
          const filterData = loop(item.children);

          if (filterData.length) {
            result.push({ ...item, children: filterData });
          }
        }
      });
      return result;
    };

    return loop(team.Treedepedata);
  }

  useEffect(() => {
    if (!inputValue) {
      setTreeData(team.Treedepedata);
    } else {
      const result = searchData(inputValue);
      setTreeData(result);
    }
  }, [inputValue]);

  return (
    <div>
      <Input.Search
        className="treeTopSearch"
        onChange={setInputValue}
        placeholder="搜索部门/人员"
      />
      <div className="companydiv">
        <p
          className={
            clickHoveActivetable == Number(-1)
              ? "opplist-lisotpost opdata"
              : "opplist-lisotpost"
          }
          style={{ cursor: "pointer" }}
          onClick={() => {
            setClickHoveActivetable(Number(-1));
            team.handeselecttdata("depeman");
          }}>
          {team.team_name}
        </p>
      </div>
      <Tree
        autoExpandParent={false}
        treeData={TreeData}
        onSelect={(selectedKeys, extra) => {
          handelClickTree(selectedKeys);
        }}
        checkedKeys={[]}
        renderTitle={(arr) => {
          const { title, _key } = arr;
          const ot = title;
          if (inputValue) {
            const index = String(title).indexOf(inputValue);

            if (index === -1) {
              return title;
            }

            const prefix = String(title).substr(0, index);
            const suffix = String(title).substr(index + inputValue.length);
            return (
              <span>
                {prefix}
                <span style={{ color: "var(--color-primary-light-4)" }}>
                  {String(title).substr(index, inputValue.length)}
                </span>
                {suffix}
              </span>
            );
          }

          if (arr.childrenData?.length > 0) {
            return `${title}   ( ${arr.childrenData?.length} )`;
          }

          return (ot as string).length > 10 ? (
            <Popover position="tl" content={title}>
              <p
                className={
                  clickHoveActivetable == Number(_key)
                    ? "opplist-lisotpost opdata"
                    : "opplist-lisotpost"
                }>
                {title}
              </p>
            </Popover>
          ) : (
            <p
              className={
                clickHoveActivetable == Number(_key)
                  ? "opplist-lisotpost opdata"
                  : "opplist-lisotpost"
              }>
              {title}
            </p>
          );
        }}></Tree>
    </div>
  );
};
export default inject("team")(observer(Memberdepartments));
