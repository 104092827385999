import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import {
  Button,
  Form,
  Input,
  Popover,
  Radio,
  Upload,
  Image,
} from "@arco-design/web-react";
import code from "@/assets/teamoverview/QRcode.png";
import entel from "@/assets/teamoverview/entel.png";
import mov_error from "@/assets/teamoverview/mov_error.png";
import mov_info from "@/assets/teamoverview/mov_info.png";
import merber from "@/assets/teamoverview/merber.png";
import siting from "@/assets/teamoverview/videocompositing.png";
import TeamRights from "@/assets/teamoverview/TeamRights.png";
import modaltitlicon from "@/assets/teamoverview/modaltitlicon.png";
import titleImg from "@/assets/teamoverview/title.png";
import { IconClose, IconPlus, IconSearch } from "@arco-design/web-react/icon";
import { inject, observer } from "mobx-react";
import ContelSelect from "./ContelSelect";
import TableSelect from "./Table";
import Modals from "@/components/UI/Modals";
import Slesctinput from "./componts/slesctinput";
import { useNavigate } from "react-router-dom";
const FormItem = Form.Item;
const TeamOverview = (props: any) => {
  const { teamover, team } = props;
  const to = useNavigate();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [popstate, topopstate] = useState(false);
  const fileRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("UserStore")) {
      const arr = JSON.parse(localStorage.getItem("UserStore"));
      if (arr?.teamInfo?.team_user_role?.roles == null) {
        to("/");
      } else {
        if (
          arr?.teamInfo?.team_user_role?.roles.filter(
            (item) => item?.code == "superadmin",
          )
        ) {
          teamover.teme_package_to();
          team.team_membet_uuidget_to();
          teamover.team_corporate_get_to();
          teamover.team_package_record_to();
          teamover.user_wechat_qrcode_to();
          team.team_department_findid_to({
            page: null,
            page_size: null,
          });
        } else {
          to("/");
        }
      }
    }
  }, []);
  useEffect(() => {
    setdata(teamover.qaydata);
  }, [teamover.qaydata]);
  useEffect(() => {
    setdata(teamover.qaydata);
  }, []);
  useEffect(() => {
    if (teamover.qyrzstatus) {
      setEnterprstate(teamover.qyrzstatus);
    }
    // setEnterprstate(3);
  }, [teamover.qyrzstatus]);
  const dataimg = {
    视频合成条数: siting,
    存储空间: entel,
    团队成员账号: merber,
    二维码: code,
  };
  const [data, setdata] = useState([]);
  const [Enterprstate, setEnterprstate] = useState(0);
  const Enterprisedata = ["企业认证", "审核中", "查看认证", "重新认证"];
  const [prisestate, setprisestate] = useState(false);
  const [formRef] = Form.useForm();
  const [license, setlicense] = useState("");
  const dataref = useRef(null);

  // 企业认证 弹框
  const HandeClickEnterprise = () => {
    if (Enterprstate != 1) {
      setprisestate(true);
    }
  };
  const handestyle = () => {
    if (prisestate) {
      return "block";
    } else {
      return "none";
    }
  };
  const HandehoverPro = () => {
    if (!Enterprstate) return ``;
    if (Enterprstate == 1) {
      return (
        <p className="mov_pro_title_einfo">
          <img src={mov_info} alt="" />
          工作人员正在审核中请耐心等待
        </p>
      );
    } else if (Enterprstate == 3) {
      return (
        <p className="mov_pro_title_einfo">
          <img src={mov_error} alt="" />
          认证失败：图片不清晰。请上传清晰且四角完整的图片
        </p>
      );
    }
  };
  const convertMbToGb = (mb, isNum = false): any => {
    if (typeof mb !== "number" || mb < 0) {
      throw new Error("输入必须是一个非负的数值。");
    }
    const GB = 1024;
    const TB = GB * 1024;

    if (mb >= TB) {
      const tb = mb / TB;
      // 保留两位小数，去除多余的零
      return isNum
        ? parseFloat(tb.toFixed(2))
        : `${parseFloat(tb.toFixed(2))} TB`;
    } else if (mb >= GB) {
      const gb = mb / GB;
      // 保留两位小数，去除多余的零
      return isNum
        ? parseFloat(gb.toFixed(2))
        : `${parseFloat(gb.toFixed(2))} GB`;
    } else {
      return isNum ? mb : `${mb} MB`;
    }
  };
  const HandeClickOpenVisble = () => {
    setprisestate(!prisestate);
  };
  const HandeoppenClick = () => {
    if (Enterprstate == 2) {
    } else {
      topopstate(!popstate);
    }
  };
  const handleFileChange = (e) => {
    const file = e;
    if (file) {
      // 立即预览图片
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handeUploadClick = async (fileList, file) => {
    if (file) {
      const { name } = file;
      // const { originFile } = file;
      // console.log(originFile);
      if (name) {
        const token = await teamover.getDocumentUploadToken_to(name, file.size);
        // const data = await teamover.setFile_teamOverview_to(token, originFile);
        const data = await teamover.setFile_teamOverview_to(token, file);
        const { object_key } = data;

        const slindata = await teamover.team_license_ocr_to(object_key);
        setlicense(object_key);
        if (slindata) {
          handleFileChange(file);
          formRef.setFieldsValue({
            input_Social: slindata?.name,
            input_Social_code: slindata?.register_number,
            input_Social_role: slindata?.legal_person,
          });
        }
      }
    }
  };
  const HandelClicksubmitstate = async () => {
    try {
      await formRef.validate();
      const input_Social = formRef.getFieldValue("input_Social");
      const input_Social_pag = formRef.getFieldValue("input_Social_pag");
      const input_Social_code = formRef.getFieldValue("input_Social_code");
      const input_Social_role = formRef.getFieldValue("input_Social_role");
      teamover.team_corporate_to({
        company_name: input_Social,
        credit_code: input_Social_code,
        legal_rep: input_Social_role,
        license: license,
      });
      setprisestate(!prisestate);
      // if (selectroledata?.length != null && seleData != null) {
      //   setvisbleadd(false);
      //   await team.team_invite_to({
      //     department_id: Number(seleData),
      //     role_id: selectroledata,
      //   });
      // }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="team_overview">
      <div className="team_overview_header">
        <div className="header_contel">
          <div className="contel_L">
            <img
              src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp"
              alt=""
            />
            <div className="contel_Lcomne">
              <h4 style={{ display: "flex" }}>
                合肥摘星方舟人工智能团队
                {Enterprstate == 2 && (
                  <span className="contel_Lcomne_renzhen">
                    <img
                      src={titleImg}
                      alt=""
                      style={{ width: "14px", height: "14px" }}
                    />
                    已认证
                  </span>
                )}
              </h4>
              <p>
                旗下{` `}
                {teamover?.supertoaldata?.total -
                  teamover?.supertoaldata?.supertotal || 0}
                {` `}
                名成员
              </p>
            </div>
          </div>
          <div className="contel_R">
            <Popover position="tr" content={HandehoverPro()}>
              <Button
                className={`imgbutton    ${Enterprstate == 1 && `mov_btn_SH`}  ${Enterprstate == 2 && `mov_btn_Sueee`}  ${Enterprstate == 3 && `mov_btn_Error`}`}
                type="outline"
                onClick={HandeClickEnterprise}>
                {Enterprisedata[Enterprstate]}
              </Button>
            </Popover>
            <Popover
              trigger="click"
              position="br"
              popupVisible={popstate} //
              // blurToHide={true}
              content={
                <Slesctinput HandeoppenClick={HandeoppenClick}></Slesctinput>
              }>
              <Button
                className="imgbutton"
                type="outline"
                onClick={() => {
                  HandeoppenClick();
                }}>
                移交团队
              </Button>
            </Popover>
          </div>
        </div>

        {data && (
          <div className="contel">
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>{data[0]?.name}</h5>
                  <p>
                    已合成
                    <span>{data[0]?.total - data[0]?.supertotal}</span>条
                  </p>
                </div>
                <img src={dataimg[data[0]?.name]} alt="" />
              </div>
              <div className="bottom">
                权益套餐剩余条数：{data[0]?.supertotal}
              </div>
            </div>
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>团队{data[1]?.name}</h5>
                  <p>
                    已使用
                    <span>
                      {data[1]?.total - data[1]?.supertotal}
                      <span style={{ fontSize: 14 }}>MB</span>
                    </span>
                  </p>
                </div>
                <img src={dataimg[data[1]?.name]} alt="" />
              </div>
              <div className="bottom">
                {/* 权益套餐总空间：{convertMbToGb(data[1]?.total * 1024, true)} */}
                存储空间剩余容量{data[1]?.supertotal}
              </div>
            </div>
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>{data[2]?.name}</h5>
                  <p>
                    已加入
                    <span>
                      {data[2]?.total - data[2]?.supertotal}
                      <span style={{ fontSize: 14 }}>位</span>
                    </span>
                  </p>
                </div>
                <img src={dataimg[data[2]?.name]} alt="" />
              </div>
              <div className="bottom">还可以邀请成员{data[2]?.supertotal}</div>
            </div>

            {/* {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="contel-Item">
                  {item.name == "团队成员账号" ? (
                    <div className="top">
                      <div className="top_left">
                        <h5>{item.name}</h5>
                        <p>
                          <span>
                            {item.supertotal}
                            <span>位</span>
                          </span>
                          剩余
                        </p>
                      </div>
                      <img src={dataimg[item.name]} alt="" />
                    </div>
                  ) : (
                    <div className="top">
                      <div className="top_left">
                        <h5>{item.name}</h5>
                        <p>
                          <span>{item.supertotal}</span>
                          剩余
                        </p>
                      </div>
                      <img src={dataimg[item.name]} alt="" />
                    </div>
                  )}
                  <div className="bottom">权益套餐总条数：{item.total}</div>
                </div>
              );
            })} */}
            <div className="contel-Item">
              <div
                className="code_body"
                style={
                  teamover.ewcode
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }>
                {teamover.ewcode ? <img src={teamover.ewcode} alt="" /> : <></>}
                <div className="code_contel">
                  <h5>请扫描二维码添加进行咨询</h5>
                  <p>更多优惠与惊喜等你哟～</p>
                </div>
              </div>
            </div>
            <div className="oppoation">
              <img src={TeamRights} alt="" />
            </div>
          </div>
        )}
      </div>
      <Modals
        width={579}
        title="企业认证"
        titleIcon={
          <img src={modaltitlicon} style={{ width: "23px", height: "23px" }} />
        }
        visible={prisestate}
        cancelBtn={<Button onClick={HandeClickOpenVisble}>取消</Button>}
        subMiteBtn={
          <Button
            type="primary"
            onClick={() => {
              HandelClicksubmitstate();
            }}>
            保存
          </Button>
        }
        closeIcon={<IconClose onClick={HandeClickOpenVisble} />}>
        <div className="modals_team_overview_qiy">
          <Form form={formRef} style={{ maxWidth: 500 }} autoComplete="off">
            <p className="topname"></p>
            <>
              <p className="input_Social ">营业执照</p>
              <FormItem
                field="input_Social_pag"
                // rules={[{ required: true, message: "请选择营业执照" }]}
              >
                {/* <div style={{ height: "30px" }}></div> */}
                <div className="contel_wrapp">
                  <Upload
                    multiple
                    disabled
                    onChange={(fileList, file) => {
                      handeUploadClick(fileList, file);
                    }}>
                    <div className="trigger">
                      <input
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          zIndex: "2",
                        }}
                        type="file"
                        name=""
                        onChange={(e) => {
                          handeUploadClick("1", e.target.files[0]);
                        }}></input>
                      {imagePreviewUrl && (
                        <Image
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            zIndex: "3",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          width={200}
                          src={imagePreviewUrl}
                          alt="lamp"
                        />
                      )}
                      <div className="contell_upload">
                        <IconPlus />
                        <p>点击上传</p>
                      </div>
                    </div>
                  </Upload>
                  <div className="contell_upload_right">
                    <p>1、营业执照需要通过年检；</p>
                    <p>2、请使用原件/加盖公章的复印件，扫描/拍照后上传；</p>
                    <p>3、支持png、jpg格式，3mb以内</p>
                  </div>
                </div>
              </FormItem>
            </>

            <p className="input_Social">企业名称</p>
            <FormItem
              requiredSymbol={false}
              field="input_Social"
              rules={[{ required: true, message: "请输入企业名称" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
            <p className="input_Social">统一社会信用代码</p>
            <FormItem
              field="input_Social_code"
              rules={[{ required: true, message: "请输入统一社会信用代码" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
            <p className="input_Social">法定代表人</p>
            <FormItem
              field="input_Social_role"
              rules={[{ required: true, message: "请输入法定代表人" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
          </Form>
        </div>
      </Modals>
      <div className="team_overview_content">
        <ContelSelect></ContelSelect>
        <TableSelect></TableSelect>
      </div>
    </div>
  );
};
export default inject("teamover", "team")(observer(TeamOverview));
