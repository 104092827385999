import React, { FC, useEffect, useState } from "react";
import "../index.less";
import { Dropdown, Menu, Space } from "@arco-design/web-react";
import { IconDown } from "@arco-design/web-react/icon";
import { inject, observer } from "mobx-react";
interface SetopenSetlectProps {
  item: string;
}

const SetopenSetlect: React.FC<SetopenSetlectProps> = (props: any) => {
  const { team } = props;
  const { item } = props;
  const [dataactive, setdataactive] = useState("");
  const [lectdata, setlectdata] = useState([]);
  const [activekey, setactivekey] = useState("");

  const [lectValue, setlectValue] = useState("");
  const dropList = (
    <Menu
      style={{ width: "200px" }}
      onClickMenuItem={(key, a) => {
        setlectValue(a.target.innerHTML);
        setactivekey(key);
        team.handeselecttdata(dataactive, key);
      }}
      className="setleonptioitem">
      {lectdata &&
        lectdata.map((item) => {
          return (
            <Menu.Item style={{ color: "#86909C" }} key={item.key}>
              {item.title}
            </Menu.Item>
          );
        })}
    </Menu>
  );
  useEffect(() => {
    const mutiemArrymi = [
      {
        title: "全部角色",
        key: 1,
      },
      {
        title: "编导",
        key: 2,
      },
      {
        title: "剪辑",
        key: 3,
      },
      {
        title: "运营",
        key: 4,
      },
    ];
    const mutiemArryto = team.Treedepedata;
    const mutiemArryla = [
      {
        title: "全部状态",
        key: 0,
      },
      {
        title: "待加入",
        key: 1,
      },
      {
        title: "已拒绝",
        key: 2,
      },
      {
        title: "正常",
        key: 3,
      },
      {
        title: "已停用",
        key: 4,
      },
    ];
    // const data = { 角色: mutiemArrymi, 部门: mutiemArryto, 状态: mutiemArryla };
    const data = { 角色: mutiemArrymi, 状态: mutiemArryla };
    if (props.item) {
      setlectdata(data[props.item]);
      setdataactive(props.item);
    }
  }, [item, team.Treedepedata]);
  return (
    <div>
      <Space className="dropdown-demo">
        <Dropdown droplist={dropList} trigger="click" position="bl">
          <div className="setleonptio">
            <p className="setleonptiovalue">
              {lectValue ? lectValue : `请选择${props.item}`}
            </p>
            <p className="setleonptioicon">
              <IconDown />
            </p>
          </div>
        </Dropdown>
      </Space>
    </div>
  );
};
export default inject("team")(observer(SetopenSetlect));
