import React, { useEffect, useRef, useState } from "react";

interface TimelineProps {
  durationInMs: number;
  zoomLevel: number;
  fps: number;
  onTimeUpdate?: (time: number) => void;
}

const ZOOM_CONFIGS: {
  [key: number]: {
    largeTickTime: number;
    showFrames?: boolean;
  };
} = {
  1: { largeTickTime: 120 }, // 2分钟
  2: { largeTickTime: 60 }, // 1分钟
  3: { largeTickTime: 30 }, // 30秒
  4: { largeTickTime: 20 }, // 20秒
  5: { largeTickTime: 15 }, // 15秒
  6: { largeTickTime: 5 }, // 5秒
  7: { largeTickTime: 3 }, // 3秒
  8: { largeTickTime: 2 }, // 2秒
  9: { largeTickTime: 1, showFrames: true }, // 1秒，显示帧
};

const TimelineCanvas: React.FC<TimelineProps> = ({
  durationInMs = 40 * 60 * 1000, // 默认值 40 分钟
  zoomLevel,
  fps,
  onTimeUpdate,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [dragging, setDragging] = useState(false);

  // 计算总时长，秒为单位
  const totalDurationInSeconds = durationInMs / 1000;
  const config = ZOOM_CONFIGS[zoomLevel];

  // 小刻度宽度和大刻度宽度的动态计算
  const smallTickSpacingPx = zoomLevel === 9 ? 30 : 5;
  const largeTickSpacingPx =
    zoomLevel === 9 ? smallTickSpacingPx * fps : smallTickSpacingPx * 10;
  const largeTickTime = config.largeTickTime;

  const paddingLeft = 20;
  const ballRadius = 6;

  // 将 canvas 宽度固定为 50000px
  const canvasWidth = 50000;

  // 时间格式化函数，返回 00:00 形式
  const formatTimeAsMinutesAndSeconds = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");
    return `${mins}:${secs}`;
  };

  // 绘制时间轴
  const drawTimeline = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const height = 50;
        const majorTickHeight = 18; // 大刻度高度
        const minorTickHeight = 6; // 小刻度高度

        // 设置 canvas 宽度为 50000px
        canvas.width = canvasWidth;
        canvas.height = height;

        ctx.strokeStyle = "#EDEDED";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(paddingLeft, 20);
        ctx.lineTo(canvasWidth, 20);
        ctx.stroke();

        let currentTime = 0;
        let currentFrame = 0;

        // 根据 zoomLevel 绘制小刻度和大刻度
        for (
          let xPosition = paddingLeft;
          xPosition <= canvasWidth;
          xPosition += smallTickSpacingPx
        ) {
          const isMajorTick =
            zoomLevel === 9
              ? currentFrame % fps === 0
              : (xPosition - paddingLeft) % largeTickSpacingPx === 0;

          if (isMajorTick) {
            // 绘制大刻度线和时间标签
            ctx.strokeStyle = "#999999";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(xPosition, 20);
            ctx.lineTo(xPosition, 20 + majorTickHeight);
            ctx.stroke();

            ctx.fillStyle = "#333333";
            ctx.font = "10px Arial";
            const timeLabel = formatTimeAsMinutesAndSeconds(currentTime);
            ctx.fillText(timeLabel, xPosition + 4, 35);

            currentTime += largeTickTime;
          } else if (zoomLevel === 9 && config.showFrames) {
            // 绘制帧标签和小刻度
            ctx.strokeStyle = "#999999";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(xPosition, 20);
            ctx.lineTo(xPosition, 20 + minorTickHeight);
            ctx.stroke();

            ctx.fillStyle = "#333333";
            ctx.font = "10px Arial";
            ctx.fillText(`${currentFrame}f`, xPosition + 4, 35);
          } else if (zoomLevel !== 9) {
            // 绘制小刻度
            ctx.strokeStyle = "#999999";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(xPosition, 20);
            ctx.lineTo(xPosition, 20 + minorTickHeight);
            ctx.stroke();
          }

          currentFrame++;
        }
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (!dragging) return;
    setDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!dragging) return;
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      let x = e.clientX - rect.left - paddingLeft;
      const maxX = canvasWidth - paddingLeft;
      if (x < 0) x = 0;
      if (x > maxX) x = maxX;

      const time = (x / (smallTickSpacingPx + 1)) * largeTickTime;
      setSelectedTime(time);

      if (onTimeUpdate) {
        onTimeUpdate(time);
      }
    }
  };

  const handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    let x = e.clientX - rect.left - paddingLeft;
    const maxX = canvasWidth - paddingLeft;
    if (x < 0) x = 0;
    if (x > maxX) x = maxX;

    const time = (x / (smallTickSpacingPx + 1)) * largeTickTime;
    setSelectedTime(time);

    if (onTimeUpdate) {
      onTimeUpdate(time);
    }
  };

  useEffect(() => {
    drawTimeline();
  }, [durationInMs, zoomLevel, fps]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const linePosition =
    selectedTime !== null
      ? paddingLeft + (selectedTime / largeTickTime) * (smallTickSpacingPx + 1)
      : paddingLeft;

  return (
    <div
      id="timeline"
      style={{
        width: "100%",
        position: "relative",
        height: "86px",
      }}>
      <canvas
        ref={canvasRef}
        onClick={handleCanvasClick}
        style={{ position: "absolute", top: 30, left: 0, zIndex: 0 }}
      />
      {selectedTime !== null && (
        <>
          <div
            style={{
              position: "absolute",
              left: `${linePosition}px`,
              top: "40px",
              width: "2px",
              height: "76px",
              backgroundColor: "#9b59b6",
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: "absolute",
              left: `${linePosition - ballRadius}px`,
              top: "40px",
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#9b59b6",
              cursor: "pointer",
              zIndex: 2,
            }}
            onMouseDown={handleMouseDown}
          />
        </>
      )}
    </div>
  );
};

export default TimelineCanvas;
