import lineImg from "@/assets/cut-mix-detail/config-img/line-voice.png";
import titleImg from "@/assets/cut-mix-detail/config-img/title.png";
import disTransitionImg from "@/assets/cut-mix-detail/config-img/disabled-transition.png";
import transitionImg from "@/assets/cut-mix-detail/config-img/transition.png";
import coverImg from "@/assets/cut-mix-detail/config-img/cover.png";
import disCoverImg from "@/assets/cut-mix-detail/config-img/disabled-cover.png";
import bgImg from "@/assets/cut-mix-detail/config-img/bg.png";
import disBgImg from "@/assets/cut-mix-detail/config-img/disabled-bg.png";
import "./ConfigTab.less";

const tagDataList = [
  {
    icon: <img src={lineImg} />,
    disIcon: <img src={lineImg} />,
    text: "台词配音",
  },
  {
    icon: <img src={titleImg} />,
    disIcon: <img src={titleImg} />,
    text: "文本标题",
  },
  {
    icon: <img src={transitionImg} />,
    disIcon: <img src={disTransitionImg} />,
    text: "转场设置",
  },
  {
    icon: <img src={coverImg} />,
    disIcon: <img src={disCoverImg} />,
    text: "视频封面",
  },
  {
    icon: <img src={bgImg} />,
    disIcon: <img src={disBgImg} />,
    text: "背景配置",
  },
];

interface IConfigTag {
  activeKey: number;
  handleActiveIndexChange: (e: number) => void;
  global?: boolean;
  videoActiveIndex: number;
}

const getAbledList = (global: boolean, activeIndex: number) => {
  if (!global) {
    return [0, 1, activeIndex > 0 ? 2 : undefined, 3, 4];
  } else {
    return [0, 1, 2, 3, 4];
  }
};

const ConfigTag: React.FC<IConfigTag> = (props) => {
  const { activeKey, handleActiveIndexChange, global, videoActiveIndex } =
    props;
  // const [disabled, setDisabled] = useState([0, 1, 2]);
  return (
    <ul className="video-config-tab-header">
      {tagDataList.map((item, index) => {
        return (
          <li
            key={item.text + index}
            onClick={(e) => {
              if (global) {
                handleActiveIndexChange(index);
              } else {
                if (getAbledList(global, videoActiveIndex).includes(index)) {
                  handleActiveIndexChange(index);
                  return;
                }
                e.preventDefault();
                return;
              }
            }}
            className={`${activeKey === index ? "active" : ""} ${getAbledList(global, videoActiveIndex).includes(index) ? "" : "disabled"}`}>
            <div className="img-wrapper">
              {getAbledList(global, videoActiveIndex).includes(index)
                ? item.icon
                : item.disIcon}
            </div>
            <p>{item.text}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default ConfigTag;
