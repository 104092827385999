import Modals from "@/components/UI/Modals";
import {
  Button,
  Form,
  Message,
  Popover,
  Select,
  Tooltip,
} from "@arco-design/web-react";
import { IconCheck, IconClose } from "@arco-design/web-react/icon";
import { useEffect, useRef, useState } from "react";
import "./invtite.less";
import depenull from "@/assets/member/depenull.png";
import invite from "@/assets/member/invite.png";
import memuy from "@/assets/member/memuyiq.png";
import intevitt from "@/assets/member/intevitt.png";
import intevitv from "@/assets/member/intevitv.png";
import { inject, observer } from "mobx-react";
const FormItem = Form.Item;

const Norenderfalse = (props) => {
  const { team, HandeopenClick } = props;
  return (
    <>
      <div className="depe_yqmobal_null">
        <div>
          <img src={depenull} alt="" />
          <p className="depe_yqmobal_null_title">暂时无数据</p>
          <Button
            id="depe_yqmobal_null_button"
            onClick={() => {
              team.oponDepe();
              HandeopenClick();
            }}>
            去设置部门
          </Button>
        </div>
      </div>
    </>
  );
};
const Btninvitemeber = (props: any) => {
  const [form] = Form.useForm();
  const { team, teamover } = props;
  const [visbleadd, setvisbleadd] = useState(false);
  const formRef = useRef(form);
  const [seleData, setDataopt] = useState(null);
  const [selectList, setSelectList] = useState([]);
  const [selectroledata, setselectdata] = useState(null);

  const Option = Select.Option;
  const options = [
    {
      title: "编导",
      key: 2,
    },
    {
      title: "剪辑",
      key: 3,
    },
    {
      title: "运营",
      key: 4,
    },
    // {
    //   title: "查看",
    //   key: 5,
    // },
  ];
  const [optionsdepe, setoptionsdepe] = useState([]);
  interface OptionInfo {
    child: {
      key: string;
    };
  }
  useEffect(() => {
    formRef.current.resetFields();
  }, [visbleadd]);

  const closeModal = () => {
    setvisbleadd(false);
  };
  const [disabledtitle, setdisabledtitle] = useState("");
  const [disabledstate, setdisabledstate] = useState(false);

  const handeSelctOpdata = () => {
    if (
      teamover.supertoaldata.total == null &&
      teamover.supertoaldata.supertotal == null
    ) {
      setdisabledtitle("套餐到期，无法邀请成员");
      setdisabledstate(true);
    } else if (
      teamover.supertoaldata.total == teamover.supertoaldata.supertotal &&
      teamover.supertoaldata.supertotal == 0
    ) {
      setdisabledtitle("坐席位已满，无法邀请成员");
      setdisabledstate(true);
    } else if (
      teamover.supertoaldata.total > 0 &&
      teamover.supertoaldata.supertotal > 0
    ) {
      setdisabledstate(false);
    }
  };
  useEffect(() => {
    handeSelctOpdata();
  }, []);
  useEffect(() => {
    handeSelctOpdata();
  }, [teamover.supertoaldata]);

  const closeModalsubit = async () => {
    try {
      await formRef.current.validate();
      if (selectroledata?.length != null && seleData != null) {
        setvisbleadd(false);
        await team.team_invite_to({
          department_id: Number(seleData),
          role_id: selectroledata,
          // type: 2,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    setoptionsdepe(team.Treedepedata);
  }, [team.Treedepedata]);
  const getCheckState = (list: string[], name: string) => {
    return list.includes(name);
  };
  return (
    <>
      {/* <Button
        type="primary"
        className="imgbuttoninvet"
        icon={<img src={intevitt} alt="" />}
        onClick={() => {
          setvisbleadd(true);
        }}
        disabled={disabledstate}>
        邀请成员
      </Button> */}
      {disabledstate ? (
        <Tooltip
          position="top"
          trigger="hover"
          content={<p>{disabledtitle}</p>}>
          <div>
            <Button
              type="primary"
              className="imgbuttoninvetv"
              icon={<img src={intevitv} alt="" />}
              onClick={() => {
                // setvisbleadd(true);
              }}
              // disabled={disabledstate}
            >
              邀请成员
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          className="imgbuttoninvet"
          icon={<img src={intevitt} alt="" />}
          onClick={() => {
            setvisbleadd(true);
          }}
          disabled={disabledstate}>
          邀请成员
        </Button>
      )}

      <Modals
        width={486}
        // height={300}
        title="邀请成员"
        titleIcon={
          <img src={memuy} style={{ width: "23px", height: "23px" }} />
        }
        visible={visbleadd}
        cancelBtn={<Button onClick={closeModal}>取消</Button>}
        subMiteBtn={
          <Button type="primary" onClick={closeModalsubit}>
            复制邀请链接
          </Button>
        }
        closeIcon={<IconClose onClick={closeModal} />}>
        <div className="meber_modal_memuyq">
          <Form ref={formRef} style={{ maxWidth: 500 }} autoComplete="off">
            <p className="meber_mobal_selname">请选择角色</p>
            <FormItem
              requiredSymbol={false}
              field="SelectRole"
              rules={[{ required: true, message: "请选择角色" }]}>
              <Select
                placeholder="请选择成员角色"
                className="meber_mobal_selelon_name"
                mode="multiple"
                value={selectList}
                onChange={(e, a) => {
                  const arr = [];
                  (a as OptionInfo[]).map((ite) => {
                    arr.push(Number(ite.child.key));
                  });
                  setselectdata(arr);
                  setSelectList(e);
                }}
                allowClear>
                {options.map((option) => (
                  <Option
                    key={option.key}
                    value={option.title}
                    wrapperClassName="select-demo-hide-option-checkbox">
                    <div className="depe_select_modal_iconcheck">
                      <span>{option.title}</span>
                      <span>
                        {getCheckState(selectList, String(option.title)) ? (
                          <>
                            <IconCheck
                              style={{ color: "#165DFF" }}
                              className="icon_wrapper"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </FormItem>
            <p className="meber_mobal_seldepe">请选择部门</p>
            <FormItem
              requiredSymbol={false}
              field="SelectDepe"
              rules={[{ required: true, message: "请选择部门" }]}>
              <Select
                placeholder="请选择所在部门"
                className="meber_mobal_selelon_depe"
                onChange={(e, a) => {
                  setDataopt((a as OptionInfo).child.key);
                }}
                notFoundContent={
                  <Norenderfalse
                    team={team}
                    HandeopenClick={closeModal}></Norenderfalse>
                }>
                {optionsdepe.map((option) => (
                  <Option key={option.key} value={option.title}>
                    {option.title}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Form>
        </div>
      </Modals>
    </>
  );
};

export default inject("team", "teamover")(observer(Btninvitemeber));
