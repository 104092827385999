import { useRef, useState } from "react";
import OpenDrawer from "../../OpenDrawer/OpenDrawer";
import { Checkbox, Select } from "@arco-design/web-react";
import aImg from "@/assets/cut-mix-detail/a.png";
import bgImg from "@/assets/cut-mix-detail/bg.png";
import boldImg from "@/assets/cut-mix-detail/bold.png";
import IImg from "@/assets/cut-mix-detail/i.png";
import rightImg from "@/assets/cut-mix-detail/right.png";
import leftImg from "@/assets/cut-mix-detail/left.png";
import centerImg from "@/assets/cut-mix-detail/center.png";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../../UIComponents/VideoConfigCard/VideoConfigCard";
import FlowerFontCardGroup, {
  IFlowerFontCardGroupForward,
} from "./FlowerFontCardGroup/FlowerFontCardGroup";
import { getFlowerFont } from "@/services/mixCutDetail/mixCutDetail";
import fontList from "@/config/fontList";
import ColorLineImgBlock from "./ColorLineImgBlock/ColorLineImgBlock";
import BgChoiceBlock from "./BgChoiceBlock/BgChoiceBlock";
import { ITextSetting } from "@/stores/mixCutDetailStore/type/caption";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import "./LineFontConfig.less";
import { getEffectParam } from "@/utils/perm";

const Option = Select.Option;

export interface ILineFontConfig {
  style?: React.CSSProperties;
  data: ITextSetting;
  handleTextSettingChange: (e: ITextSetting) => void;
  global: boolean;
}

const getfontSizeOption = () => {
  return Array.from({ length: 100 }, (_, i) => i + 1);
};

const imgList = [
  {
    value: "right",
    label: rightImg,
  },
  {
    value: "center",
    label: centerImg,
  },
  {
    value: "left",
    label: leftImg,
  },
];

const LineFontConfig: React.FC<ILineFontConfig> = (props) => {
  const { global, style, data, handleTextSettingChange } = props;

  const {
    font,
    font_size,
    color,
    background_color,
    italic,
    alignment,
    effect,
    bold,
    is_global,
  } = data || {};

  const [openState, setOpenState] = useState(true);
  const { data: FontList } = useRequest({ request: getFlowerFont });
  const [flowerFontShowAllState] = useState(false);
  const flowerFontGroupRef = useRef<IFlowerFontCardGroupForward>(null);

  const editFontSetting = (
    e: keyof ITextSetting,
    value: ITextSetting[keyof ITextSetting],
  ) => {
    if (e === "font_size") {
      const { outline, shadow } = getEffectParam(
        value,
        data.effect.advanced as any,
      );
      data.outline = outline;
      data.shadow = shadow;
    }
    if (e === "effect") {
      const { outline, shadow } = getEffectParam(
        data.font_size,
        data.effect.advanced as any,
      );
      data.outline = outline;
      data.shadow = shadow;
    }
    handleTextSettingChange({
      ...data,
      [e]: value,
    });
  };

  return (
    <div style={style}>
      <VideoConfigCard>
        <VideoConfigCardHeader
          headerLeft={
            <>
              <OpenDrawer state={openState} handleStateChange={setOpenState} />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenState(!openState);
                }}>
                设置字体
              </span>
            </>
          }
          headerRight={
            global ? (
              <></>
            ) : (
              <>
                <Checkbox
                  style={{ marginRight: 6 }}
                  checked={is_global}
                  onChange={(e) => {
                    editFontSetting("is_global", e);
                  }}
                />
                <span>应用到全部镜头台词</span>
              </>
            )
          }></VideoConfigCardHeader>
        <VideoConfigCardContainer
          style={{ paddingRight: 0, height: `${openState ? "auto" : 0}` }}>
          {openState ? (
            <>
              <div className="line-font-config-line">
                <div className="title">选择字体</div>
                <div className="font-setting-config-container">
                  <Select
                    className="border"
                    value={font}
                    placeholder="字体选择"
                    size="small"
                    onChange={(e) => {
                      editFontSetting("font", e);
                    }}
                    style={{ width: 140 }}>
                    {fontList.map((option, index) => (
                      <Option
                        key={option.value}
                        // disabled={index === 3}
                        value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    className={"border"}
                    value={font_size}
                    onChange={(e) => {
                      editFontSetting("font_size", e);
                    }}
                    placeholder="12"
                    size="small"
                    style={{ width: 70 }}>
                    {getfontSizeOption().map((option, index) => (
                      <Option
                        key={option}
                        disabled={index === 3}
                        value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                  <div className="font-setting-container">
                    <ColorLineImgBlock
                      imgStyle={{ width: 12, height: 12 }}
                      color={color}
                      img={aImg}
                      style={{ marginLeft: 12 }}
                      handleColorChange={(e) => {
                        editFontSetting("color", e);
                      }}
                    />
                    <ColorLineImgBlock
                      imgStyle={{ width: 12, height: 12 }}
                      color={background_color}
                      img={bgImg}
                      style={{ marginLeft: 12 }}
                      handleColorChange={(e) => {
                        editFontSetting("background_color", e);
                      }}
                    />
                    <BgChoiceBlock
                      img={boldImg}
                      isChoice={bold}
                      style={{ marginLeft: 12 }}
                      onClick={() => {
                        editFontSetting("bold", !bold);
                      }}
                    />
                    <BgChoiceBlock
                      img={IImg}
                      isChoice={italic}
                      onClick={() => {
                        editFontSetting("italic", !italic);
                      }}
                      style={{ marginLeft: 12 }}
                    />
                    <Select
                      className={"border font-select"}
                      id={"wrapper"}
                      getPopupContainer={() => {
                        return document.querySelector("#wrapper");
                      }}
                      size="small"
                      value={alignment}
                      style={{ width: "38px", padding: 0, marginLeft: 12 }}
                      onChange={(e) => {
                        editFontSetting("alignment", e);
                      }}
                      dropdownMenuClassName="option-wrapper">
                      {imgList.map((item) => {
                        return (
                          <Select.Option value={item.value} key={item.label}>
                            <img
                              src={item.label}
                              alt=""
                              style={{ width: 14, height: 14 }}
                            />
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {/* <img src={rightImg} alt="" /> */}
                  </div>
                </div>
              </div>
              <div className="flower-font-choose">
                <div className="title">选择花字</div>
                <FlowerFontCardGroup
                  ref={flowerFontGroupRef}
                  maxShowNumber={11}
                  imgList={FontList}
                  activeEfffect={effect}
                  handelFlowerFontIdChange={(e) => {
                    editFontSetting("effect", e);
                  }}
                  style={{ marginLeft: "-16px", flex: 1 }}
                  defaultShowState={flowerFontShowAllState}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </VideoConfigCardContainer>
      </VideoConfigCard>
    </div>
  );
};

export default LineFontConfig;
