import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tabs, Input, Button } from "@arco-design/web-react";
import { IconSearch } from "@arco-design/web-react/icon";
import { observer, inject } from "mobx-react";
import SetopenSetlect from "./ItemSelect";

const ItemSelect = (props: any) => {
  const { team } = props;
  const [selectData, useselectData] = useState([]);
  const itemdomTO = function (item) {
    return <SetopenSetlect item={item}></SetopenSetlect>;
  };
  const SearchInput = ({ onChange, ...props }) => {
    const [value, setValue] = useState("");
    const debounceRef = useRef(null);
    const debouncedSetValue = useCallback((newValue) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        onChange(newValue);
        setValue(newValue);
      }, 400);
    }, []);
    const handleChange = (e) => {
      debouncedSetValue(e);
    };
    return <Input {...props} onChange={handleChange} />;
  };

  const data = [
    {
      itemDom: (
        <SearchInput
          prefix={<IconSearch />}
          allowClear
          placeholder="请输入姓名/手机号查询"
          className="plearthPhone"
          onChange={(e) => {
            team.handeselecttdata("index", e);
          }}
        />
      ),
    },
    {
      itemDom: itemdomTO("角色"),
    },
    // {
    //   itemDom: itemdomTO("部门"),
    // },
    {
      itemDom: itemdomTO("状态"),
    },
  ];
  const datathree = [
    {
      itemDom: (
        <SearchInput
          allowClear
          prefix={<IconSearch />}
          placeholder="请输入部门名称"
          className="optab_depename_select"
          onChange={(e) => {
            team.updataselectdata(e, 1, 10);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const { actvie } = props;
    if (actvie == "Member") {
      useselectData(data);
    } else if (actvie == "Role") {
      useselectData([]);
    } else {
      useselectData(datathree);
    }
  }, [props]);

  return (
    <div className="Filtememberscontextselectbody">
      {selectData &&
        selectData.map((item, index) => {
          return <div key={index}>{item.itemDom}</div>;
        })}
    </div>
  );
};
export default inject("team")(observer(ItemSelect));
