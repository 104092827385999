import { Fragment } from "react/jsx-runtime";
import TransitionCard, {
  ITransitionCard,
} from "./TransitionCard/TransitionCard";
import "./TransitionGroup.less";
import CheckBoxHoc from "@/components/business/MaterialFileModal/CheckBoxHoc/CheckBoxHoc";
import { IMixDetailTransitionRequest } from "@/services/mixCutDetail/mixCutDetail";

export interface ITransitionGroup {
  data: IMixDetailTransitionRequest[];
  handleCheckboxChange: (state: boolean, id: number) => void;
  checkList: { name: string; id: number }[];
}

const TransitionGroup: React.FC<ITransitionGroup> = (props) => {
  const { data, handleCheckboxChange, checkList } = props;

  return (
    <div className="transition-group">
      {(data || []).map((item) => {
        return (
          <div
            key={item.id}
            className="transition-card-wrapper"
            style={{ marginRight: 10, marginBottom: 10 }}>
            <CheckBoxHoc
              style={{ padding: 0, borderRadius: 8 }}
              checked={checkList.findIndex((step) => step.id === item.id) > -1}
              onCheckedChange={(e) => {
                handleCheckboxChange(e, item.id);
              }}>
              <TransitionCard url={item.url} name={item.name} />
            </CheckBoxHoc>
            <p>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TransitionGroup;
