import React, { useEffect, useRef, useState } from "react";

export interface IFrameTimelineProps {
  img: string; // 图片的路径
  count: number; // 图片中的小图数量
  width: number; // 单个小图在原始图片中的宽度
  height: number; // 单个小图在原始图片中的高度
  zoomLevel: number; // 缩放级别
  showHeight: number; // 在 canvas 上显示的小图的高度
  totalDurationMs: number; // 总时间，以毫秒为单位
  start: number;
  duration: number;
  fps: number; // 帧数
  onSelectionChange?: (startTimeMs: number, durationMs: number) => void; // 用于暴露开始时间和持续时间的回调
}

const ZOOM_CONFIGS: {
  [key: number]: {
    largeTickTime: number;
    showFrames?: boolean;
  };
} = {
  1: { largeTickTime: 120 }, // 2分钟
  2: { largeTickTime: 60 }, // 1分钟
  3: { largeTickTime: 30 }, // 30秒
  4: { largeTickTime: 20 }, // 20秒
  5: { largeTickTime: 15 }, // 15秒
  6: { largeTickTime: 5 }, // 5秒
  7: { largeTickTime: 3 }, // 3秒
  8: { largeTickTime: 2 }, // 2秒
  9: { largeTickTime: 1, showFrames: true }, // 1秒，显示帧
};

const FrameTimeline: React.FC<IFrameTimelineProps> = ({
  img,
  count,
  width,
  height,
  zoomLevel,
  showHeight,
  totalDurationMs,
  start,
  duration,
  fps,
  onSelectionChange,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const paddingLeft = 20; // 保持与 TimelineCanvas 一致的偏移量

  const [dragStartX, setDragStartX] = useState(0); // 记录鼠标点击时的 X 坐标
  const [initialLeftPosRatio, setInitialLeftPosRatio] = useState(0);
  const [initialRightPosRatio, setInitialRightPosRatio] = useState(1);

  // 根据 zoomLevel 获取对应的时间刻度
  const config = ZOOM_CONFIGS[zoomLevel];
  const largeTickTime = config.largeTickTime;

  // 计算总宽度和每帧的宽度
  const totalDurationInSeconds = totalDurationMs / 1000;
  const smallTickSpacingPx = zoomLevel === 9 ? 30 : 5;
  const largeTickSpacingPx =
    zoomLevel === 9 ? smallTickSpacingPx * fps : smallTickSpacingPx * 10;
  const totalWrapperWidth =
    (totalDurationInSeconds / largeTickTime) * largeTickSpacingPx;

  // 根据 canvas 宽度动态调整每张图片的宽度
  const adjustedWidth = totalWrapperWidth / count;

  const [leftPosRatio, setLeftPosRatio] = useState(0); // 初始位置从最左边开始
  const [rightPosRatio, setRightPosRatio] = useState(1); // 初始位置从最右边开始
  const leftPos = leftPosRatio * totalWrapperWidth;
  const rightPos = rightPosRatio * totalWrapperWidth;

  const [dragging, setDragging] = useState<"left" | "right" | null>(null);

  // 根据 zoomLevel 和其他参数重新绘制帧图像
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = img;

      image.onload = () => {
        const imgWidth = image.width;
        const framesPerRow = Math.floor(imgWidth / width);
        const adjustedHeight = showHeight;

        canvas.width = totalWrapperWidth; // canvas 的宽度根据 zoomLevel 调整
        canvas.height = adjustedHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < count; i++) {
          const row = Math.floor(i / framesPerRow);
          const col = i % framesPerRow;

          const sx = col * width;
          const sy = row * height;

          ctx?.drawImage(
            image,
            sx,
            sy,
            width,
            height,
            i * adjustedWidth,
            0,
            adjustedWidth, // 动态拉伸每帧图像宽度
            adjustedHeight,
          );

          // 如果是 zoomLevel = 9 的情况下，显示帧数
          if (zoomLevel === 9) {
            ctx.fillStyle = "#333";
            ctx.font = "10px Arial";
            const frameLabel = `${i + 1}f`;
            ctx.fillText(frameLabel, i * adjustedWidth + 5, showHeight - 5);
          }
        }
      };
    }
  }, [
    img,
    count,
    width,
    height,
    zoomLevel, // 依赖 zoomLevel 进行调整
    adjustedWidth,
    showHeight,
    totalWrapperWidth,
  ]);

  // 根据 start 和 duration 初始化左右位置
  useEffect(() => {
    const initialLeftPos = start / totalDurationMs;
    const initialRightPos = (start + duration) / totalDurationMs;

    setLeftPosRatio(initialLeftPos);
    setRightPosRatio(initialRightPos);
  }, [start, duration, totalDurationMs]);

  // 处理拖拽左或右的选区
  const handleMouseDown = (side: "left" | "right", e: React.MouseEvent) => {
    e.preventDefault();
    const canvasRect = canvasRef.current?.getBoundingClientRect();
    if (canvasRect) {
      setDragStartX(e.clientX); // 记录鼠标点击时的 X 坐标
      setInitialLeftPosRatio(leftPosRatio); // 记录当前的 leftPosRatio
      setInitialRightPosRatio(rightPosRatio); // 记录当前的 rightPosRatio
      setDragging(side); // 设置当前拖拽的方向
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!dragging) return;

    const deltaX = e.clientX - dragStartX; // 计算鼠标移动的距离
    const deltaRatio = deltaX / totalWrapperWidth; // 转换为比例

    if (dragging === "left") {
      // 限制左边界，确保不会超出右边界
      const newLeftPosRatio = Math.min(
        Math.max(initialLeftPosRatio + deltaRatio, 0),
        rightPosRatio - 0.05, // 添加额外间距以防止完全重叠
      );
      setLeftPosRatio(newLeftPosRatio);
    } else if (dragging === "right") {
      // 限制右边界，确保不会超出左边界
      const newRightPosRatio = Math.max(
        initialRightPosRatio + deltaRatio,
        leftPosRatio + 0.05, // 添加额外间距以防止完全重叠
      );
      setRightPosRatio(newRightPosRatio);
    }
  };

  const handleMouseUp = () => {
    setDragging(null);

    const startTimeMs = Math.round(leftPosRatio * totalDurationMs);
    const durationMs = Math.round(
      (rightPosRatio - leftPosRatio) * totalDurationMs,
    );

    if (onSelectionChange) {
      onSelectionChange(startTimeMs, durationMs);
    }
  };

  // 监听鼠标移动和放开事件
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, leftPosRatio, rightPosRatio]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%", // 容器宽度为 100%
        height: `${showHeight}px`,
      }}
      onMouseDown={(e) => e.preventDefault()}>
      <canvas
        id={"frame-canvas"}
        ref={canvasRef}
        style={{
          position: "absolute",
          left: `${paddingLeft}px`,
          width: `${totalWrapperWidth}px`, // canvas 的总宽度与 totalWrapperWidth 对应
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: `${paddingLeft + leftPos - 1}px`,
          width: `${rightPos - leftPos + 4}px`,
          height: `${showHeight}px`,
          border: "2px solid #007bff",
        }}>
        <div
          style={{
            position: "absolute",
            left: "-11px",
            top: -3,
            width: "10px",
            height: "calc(100% + 3px)",
            backgroundColor: "#007bff",
            cursor: "ew-resize",
          }}
          onMouseDown={(e) => handleMouseDown("left", e)}
        />
        <div
          style={{
            position: "absolute",
            right: "-5px",
            top: -3,
            width: "10px",
            height: "calc(100% + 3px)",
            backgroundColor: "#007bff",
            cursor: "ew-resize",
          }}
          onMouseDown={(e) => handleMouseDown("right", e)}
        />
      </div>
    </div>
  );
};

export default FrameTimeline;
