import React, { useEffect, useState } from "react";
import { Pagination, Table, TableColumnProps } from "@arco-design/web-react";
import { inject, observer } from "mobx-react";
import Nodata from "../componts/Nodata";
const columns: TableColumnProps[] = [
  {
    title: "编号",
    dataIndex: "a",
  },
  {
    title: "交易类型",
    dataIndex: "b",
    render: (a, b, c) => {
      console.log(a);
      return (
        <span
          className={
            a == "权益退款" ? "opdatatitle opred" : "opdatatitle opgren"
          }>
          {a}
        </span>
      );
    },
  },
  {
    title: "权益名称",
    dataIndex: "c",
  },
  {
    title: "销售人员",
    dataIndex: "d",
  },
  {
    title: "交易金额",
    dataIndex: "e",
  },
  {
    title: "交易方式",
    dataIndex: "f",
  },
  {
    title: "交易时间",
    dataIndex: "g",
  },
];
const columnson: TableColumnProps[] = [
  {
    title: "编号",
    dataIndex: "a",
  },
  {
    title: "应用类型",
    dataIndex: "b",
    render: (a, b, c) => {
      console.log(a);
      return (
        <span
          className={
            a == "权益退款" ? "opdatatitle opred" : "opdatatitle opgren"
          }>
          {a}
        </span>
      );
    },
  },
  {
    title: "文件名称",
    dataIndex: "c",
  },
  {
    title: "交易类型",
    dataIndex: "d",
  },
  {
    title: "交易明细",
    dataIndex: "e",
  },
  {
    title: "剩余条款",
    dataIndex: "f",
  },
  {
    title: "操作账号",
    dataIndex: "g",
  },
  {
    title: "交易时间",
    dataIndex: "data",
  },
];
const data = [];
const dataon = [];
const onChangeTable = (current: number, pageSize: number) => {
  console.log(current, pageSize);
};
const TableSelect = (props) => {
  const { teamover } = props;
  const [a, seta] = useState([]);
  const [n, setn] = useState([]);
  useEffect(() => {
    if (teamover.dataactive == "activeqy") {
      seta(dataon);
      setn(columnson);
    } else {
      seta(data);
      setn(columns);
    }
  }, [teamover.dataactive]);

  return (
    <div className="selccompont_tabdata">
      <Table
        hover={false}
        tableLayoutFixed={true}
        border={false}
        columns={n}
        data={a}
        pagination={false}
        noDataElement={<Nodata></Nodata>}
      />
      {/* <Pagination
        total={50}
        showTotal={(total) => `共 ${total}条`}
        showJumper
        sizeCanChange
        className={"tab-pages"}
      /> */}
      <Pagination
        showTotal={(total) => `共 ${total}条`} //是否显示数据总数
        total={a && a.length ? a.length : 0} // 数据总数
        // hideOnSinglePage  // 是否在一页的时候 隐藏
        showJumper //	是否显示快速跳转到某页，在 simple 模式下默认为 true	boolean
        sizeCanChange //	是否可以改变每页条数
        onChange={(current, pageSize) => {
          onChangeTable(current, pageSize);
        }}
        className={"tab-pages"}
      />
    </div>
  );
};
export default inject("teamover")(observer(TableSelect));

{
  /*
const data = [
  {
    key: "1",
    a: "230901909898",
    b: "购买权益",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "2",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "3",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "4",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "5",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "6",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "7",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "8",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "9wqr",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "11wra",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "51aeq",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "1vaf1",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "5fa1",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "11ds",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "51fa",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "11ds",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "5b1",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "11zf",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "5a1",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "11f",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "51sagf",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "1ad1",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "51341",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "111231",
    a: "230901909898",
    b: "权益退款",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
  {
    key: "5213121",
    a: "230901909898",
    b: "平台赠送",
    c: "企业基础版3.0",
    d: "王美丽（18355180713）",
    e: "¥9,500",
    f: "银行对公",
    g: "2023-09-27 15:09:27",
  },
];
const dataon = [
  {
    key: "1",
    a: "230901909898",
    b: "智能混剪",
    c: "栋天动地",
    d: "权益扣除",
    e: "-2",
    f: "2",
    g: "大聪明",
    data: "2024-10-9",
  },
  {
    key: "1a",
    a: "230901909898",
    b: "一键成片",
    c: "毁天灭地",
    d: "失败返还",
    e: "-2",
    f: "2",
    g: "大聪明",
    data: "2024-10-9",
  },
  {
    key: "1b",
    a: "230901909898",
    b: "视频解析",
    c: "上天下地",
    d: "权益扣除",
    e: "-2",
    f: "2",
    g: "大聪明",
    data: "2024-10-9",
  },
  {
    key: "1c",
    a: "230901909898",
    b: "智能混剪",
    c: "穿天越地",
    d: "权益扣除",
    e: "-2",
    f: "2",
    g: "大聪明",
    data: "2024-10-9",
  },
];

  */
}
