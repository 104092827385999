import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Message,
  Pagination,
  Form,
} from "@arco-design/web-react";
import DepeOperate from "../components/Depeoperve";
import {
  IconDelete,
  IconUserAdd,
  IconClose,
} from "@arco-design/web-react/icon";
import adddepeicon from "@/assets/member/depeadd.png";
import depeaddmobal from "@/assets/member/depeaddmobal.png";
import Modals from "@/components/UI/Modals/index";
import "./index.less";
import { observer, inject } from "mobx-react";
import Nodata from "@/pages/TeamOverview/componts/Nodata";
const FormItem = Form.Item;
const columns = [
  {
    title: "部门名称",
    dataIndex: "name",
  },
  // {
  //   title: "创建人",
  //   dataIndex: "uuid",
  // },
  {
    title: "部门人数",
    dataIndex: "total",
  },
  {
    title: "更新时间",
    dataIndex: "updated_at",
  },
  {
    title: "操作",
    dataIndex: "operate",
    render: (a, b, c) => {
      const handeClikeDelet = () => {
        console.log();
      };
      const handeClikeDetail = () => {
        Message.success(`详情部门${b.depename}`);
      };

      return (
        <DepeOperate
          data={b}
          deletClick={handeClikeDelet}
          detaliClick={handeClikeDetail}></DepeOperate>
      );
    },
  },
];

const Depe = (props) => {
  const { team } = props;
  const [data, setData] = useState([]);
  const [sumlength, setsumlength] = useState(0);
  useEffect(() => {
    setData(team.ttdepedata);
  }, [team.ttdepedata]);
  useEffect(() => {
    team.team_department_findid_to("");
    return () => {
      team.updataselectdata("", 1, 10);
    };
  }, []);
  useEffect(() => {
    setsumlength(team.datasum);
  }, [team.datasum]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 获取当前 渲染

  const [loading, setLoading] = useState(false);

  async function onChangeTable(current, pageSize) {
    setLoading(true);
    console.log();

    await team.updataselectdata(null, current, pageSize);
    // setData(allData.slice((current - 1) * pageSize, current * pageSize));
    setLoading(false);
  }

  const BtnThreeElement = () => {
    const [form] = Form.useForm();
    // 新增
    const [visbleadd, setvisbleadd] = useState(false);
    const [adddepinput, setadddepinput] = useState("");
    const closeModal = () => {
      setvisbleadd(false);
    };
    const onChangeAdddepe = (e) => {
      setadddepinput(e);
    };
    const closeModalsubit = async () => {
      try {
        await form.validate();
        team.team_department_to(adddepinput);
        setvisbleadd(false);
      } catch (e) {}
    };

    //删除
    const [visbldele, setvisbldele] = useState(false);
    const selstdata = team.ttselectdepe;
    const deptnumber = team.ttselectdepeactive;

    const closedeleModal = () => {
      setvisbldele(false);
    };
    return (
      <>
        <Button
          type="primary"
          className="imgbutton"
          onClick={() => {
            setvisbleadd(true);
          }}
          icon={
            <img
              src={adddepeicon}
              alt=""
              style={{ width: "18px", height: "18px" }}></img>
          }>
          新增
        </Button>
        <Modals
          width={496}
          height={321}
          title="新增部门"
          titleIcon={
            <img
              src={depeaddmobal}
              alt=""
              style={{ color: "blue", width: "23px", height: "23px" }}
            />
          }
          visible={visbleadd}
          cancelBtn={<Button onClick={closeModal}>取消</Button>}
          subMiteBtn={
            <Button type="primary" onClick={closeModalsubit}>
              保存
            </Button>
          }
          closeIcon={<IconClose onClick={closeModal} />}>
          <div className="addmobal_inputbox">
            <span className="add_teme_name">团队名称</span>
            <Input
              className="moble_dep modeble_temename"
              disabled
              placeholder={team.team_name}
            />
            <span className="add_depe_name">部门名称</span>
            <Form form={form} autoComplete="off" style={{ width: 600 }}>
              <FormItem
                field="name"
                rules={[{ required: true, message: "请输入20字以内的部门" }]}>
                <Input
                  maxLength={20}
                  showWordLimit
                  placeholder="请输入部门名称"
                  className="moble_dep "
                  onChange={(e) => {
                    onChangeAdddepe(e);
                  }}
                />
              </FormItem>
            </Form>
          </div>
        </Modals>

        {/*  
          input 公司名称 根据 数据来显示
          input输入内容 回调获取后 清空 input内容 
          */}
        {/* 
            if 单选  ||  多选 &&  if 删除内容是否有 else 弹窗提示
            if删除的部门 是否有子部门
        */}
        <Button
          icon={<IconDelete style={{ width: "16px", height: "16px" }} />}
          className="imgbutton"
          onClick={() => {
            if (selstdata.length > 0) {
              setvisbldele(true);
            } else {
              Message.info({
                content: "请选择要删除的成员!",
                showIcon: true,
                position: "top",
              });
            }
          }}
          type="outline">
          删除
        </Button>

        <Modals
          width={464}
          height={230}
          title="提示"
          titleIcon={
            <IconUserAdd
              style={{ color: "blue", width: "23px", height: "23px" }}
            />
          }
          visible={visbldele}
          cancelBtn={
            <Button
              style={deptnumber ? {} : { display: "none" }}
              onClick={closedeleModal}>
              取消
            </Button>
          }
          subMiteBtn={
            <Button
              type="primary"
              onClick={async () => {
                if (deptnumber) {
                  await team.team_department_iddelete_to();
                }
                setvisbldele(false);
              }}>
              确定
            </Button>
          }
          closeIcon={<IconClose onClick={closedeleModal} />}>
          <div className="delemodal_pbox">
            {!deptnumber ? (
              <>
                <p>无法删除</p>
                <p>请在成员管理中移除部门下所有成员，再尝试</p>
              </>
            ) : (
              <>
                <p>确认删除当前部门？</p>
                <p>删除后数据不可恢复</p>
              </>
            )}
          </div>
        </Modals>
      </>
    );
  };

  return (
    <div className="deeptab">
      <div className="tabletop">
        <BtnThreeElement></BtnThreeElement>
      </div>
      <div className="tablebody">
        <Table
          loading={loading}
          columns={columns}
          data={data}
          pagination={false}
          onChange={onChangeTable}
          rowSelection={{
            selectedRowKeys,
            checkAll: true,
            onChange: (selectedRowKeys, selectedRows) => {
              //当前选中的数据
              team.selectset(selectedRows);
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          noDataElement={<Nodata></Nodata>}
          rowClassName={(record, index) => {
            return selectedRowKeys.includes(record.key) ? "selected-row" : "";
          }}
          renderPagination={(paginationNode) => (
            <div
              style={{
                marginTop: 10,
              }}>
              {paginationNode}
            </div>
          )}
        />
        <Pagination
          showTotal={(total) => `共 ${total}条`} //是否显示数据总数
          total={sumlength && data.length ? sumlength : 0} //                       数据总数
          // hideOnSinglePage  // 是否在一页的时候 隐藏
          showJumper //	是否显示快速跳转到某页，在 simple 模式下默认为 true	boolean
          sizeCanChange //	是否可以改变每页条数
          onChange={(current, pageSize) => {
            onChangeTable(current, pageSize);
          }}
          style={{
            width: "-webkit-fill-available",
            marginTop: 15,
            marginRight: "20px",
            position: "fixed",
            bottom: "-0",
            height: "35px",
            backgroundColor: "#FFFFFF",
          }}
        />
      </div>
    </div>
  );
};
export default inject("team")(observer(Depe));
