import { IdsType, toNumberList } from "@/services/createSpace/script";
import { Message } from "@arco-design/web-react";
import { useEffect, useState } from "react";

export type LoadingType = "noStart" | "loading" | "done" | "error";

type PageParamsType = {
  page: number;
  page_size: number;
  total?: number;
};

interface IUseRequestProps<
  T,
  S extends { page?: number; page_size?: number } = {},
> {
  request: (data: S) => Promise<{ data: T[]; total?: number }>;
  initSearchData?: Omit<S, "page" | "page_size">;
  page?: {
    page_size: number;
    page: number;
  };
  manual?: boolean;
  batchDelete?: (ids: IdsType) => Promise<any>;
  createItem?: (data: T | any) => Promise<T | any>;
  updateItem?: (data: T | any) => Promise<T | any>;
}

interface IUserRequestReturn<S, T> {
  data: T[] | null;
  loading: LoadingType;
  searchData: Omit<S, "page" | "page_size"> | null;
  pageParams: PageParamsType;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  setPageParams: React.Dispatch<React.SetStateAction<PageParamsType>>;
  setSearchData: React.Dispatch<
    React.SetStateAction<Omit<S, "page" | "page_size">>
  >;
  handleCreateItem: (data: T | any) => Promise<T | any>;
  handleRequest: (
    searchParams: Omit<S, "page" | "page_size">,
  ) => Promise<{ data: T[]; total?: number }>;
  handlePageChange: (pageParams: Omit<PageParamsType, "total">) => void;
  handleBatchDelete: (ids: IdsType) => void;
  handleUpdateItem: (data: T | any) => void;
  page: PageParamsType;
}

const useRequest = <T, S extends { page: number; page_size: number }>(
  props: IUseRequestProps<T, S>,
): IUserRequestReturn<S, T> => {
  const {
    request,
    initSearchData,
    page,
    manual = false,
    batchDelete,
    createItem,
    updateItem,
  } = props;
  // console.log(page);
  const [loading, setLoading] = useState<LoadingType>("noStart");
  const [data, setData] = useState<T[] | null>(null);
  const [searchData, setSearchData] = useState<Omit<S, "page" | "page_size">>({
    ...(initSearchData || null),
  });

  const [pageParams, setPageParams] = useState<PageParamsType>({
    page: page?.page || 1,
    page_size: page?.page_size || 10,
    total: 0,
  });

  const fetchData = async (requestData: S) => {
    try {
      setLoading("loading");
      const result = await request(requestData);
      setData(result.data);
      setPageParams((prev) => ({
        ...prev,
        total: result?.total || 0,
      }));
      setLoading("done");
      return result;
    } catch (error) {
      setLoading("error");
      setData(null);
      console.error("请求错误:", error);
      Message.error(error);
    }
  };

  const handleRequest = (params: Omit<S, "page" | "page_size">) => {
    setSearchData(params);
    setPageParams({
      page: page?.page || 1,
      page_size: page?.page_size || 10,
      total: 0,
    });
    return fetchData({
      ...params,
      page: pageParams.page,
      page_size: pageParams.page_size,
    } as S);
  };

  const handlePageChange = (p: Omit<PageParamsType, "total">) => {
    setPageParams((prev) => ({ ...prev, ...p }));
    fetchData({
      ...(searchData || {}),
      page: p.page,
      page_size: p.page_size,
    } as S);
  };

  const handleBatchDelete = async (ids: IdsType) => {
    try {
      await batchDelete(ids);
      Message.success("删除成功!");
      setTimeout(() => {
        fetchData({
          ...(searchData || {}),
          page: pageParams.page,
          page_size: pageParams.page_size,
        } as S);
      }, 1000);
    } catch (e) {
      Message.error(e);
      console.log(e);
    }
  };

  const handleCreateItem = async (item: T) => {
    if (!createItem) {
      console.error("请传入创建配置项");
      return null;
    }
    try {
      const result = await createItem(item);
      Message.success("创建成功");
      fetchData({
        ...(searchData || {}),
        page: pageParams.page,
        page_size: pageParams.page_size,
      } as S);
      return result;
    } catch (e) {
      console.log(e);
      Message.error(e);
    }
  };

  const handleUpdateItem = async (item: T) => {
    if (!updateItem) {
      console.error("请传入更新配置项");
      return null;
    }
    try {
      await updateItem(item);
      Message.success("更新成功");
      // 重新请求数据
      fetchData({
        ...(searchData || {}),
        page: pageParams.page,
        page_size: pageParams.page_size,
      } as S);
    } catch (e) {
      console.log(e);
      Message.error(e);
    }
  };

  useEffect(() => {
    if (!manual) {
      fetchData({
        ...(searchData || {}),
        page: pageParams.page,
        page_size: pageParams.page_size,
      } as S);
    }
  }, []);

  return {
    page,
    searchData,
    pageParams,
    data,
    setData,
    setSearchData,
    setPageParams,
    loading,
    handleRequest,
    handlePageChange,
    handleBatchDelete,
    handleCreateItem,
    handleUpdateItem,
  };
};

export default useRequest;
