import { ReactNode } from "react";
import "./VideoConfigCard.less";
export interface IVideoConfigCardHeader {
  style?: React.CSSProperties;
  headerLeft: ReactNode;
  headerRight: ReactNode;
  className?: string;
  onClick?: () => void;
}

export interface IVideoConfigCardContainer {
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const VideoConfigCardHeader: React.FC<IVideoConfigCardHeader> = (
  props,
) => {
  const { headerLeft, headerRight, style, className, onClick } = props;
  return (
    <div
      className={`video-config-card-header ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={style}>
      <div className="video-config-card-left">{headerLeft}</div>
      <div className="video-config-card-right">{headerRight}</div>
    </div>
  );
};

export const VideoConfigCardContainer: React.FC<IVideoConfigCardContainer> = (
  props,
) => {
  const { children, style, className, onClick } = props;
  return (
    <div
      className={`${className} video-config-card-container`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={style}>
      {children}
    </div>
  );
};

const VideoConfigCard: React.FC<IVideoConfigCardContainer> = (props) => {
  const { children, style } = props;
  return (
    <div className="video-config-card" style={style}>
      {children}
    </div>
  );
};

export default VideoConfigCard;
