import "./CoverCard.less";

export interface ICoverCardProps {
  url: string;
  style?: React.CSSProperties;
  active?: boolean;
}

const CoverCard: React.FC<ICoverCardProps> = (props) => {
  const { style, url, active } = props;
  return (
    <>
      <div style={style} className={`cover-card ${active ? "active" : ""}`}>
        <div className="img-wrapper">
          <img src={url} alt="" />
        </div>
      </div>
    </>
  );
};

export default CoverCard;
