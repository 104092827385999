import React, { useState } from "react";
import { Button, Slider } from "@arco-design/web-react";

export interface IZoomControl {
  value: number;
  onChange: (e: number) => void;
}

const ZoomControl: React.FC<IZoomControl> = (props) => {
  const { value, onChange } = props;

  // 增加缩放值
  const handleIncrease = () => {
    if (value + 1 <= 9) {
      onChange(value + 1);
    }
  };

  // 减少缩放值
  const handleDecrease = () => {
    if (value - 1 > 0) {
      onChange(value - 1);
    }
  };
  return (
    <div>
      <Button
        shape="circle"
        onClick={handleDecrease}
        style={{
          marginRight: "10px",
          width: "30px",
          height: "30px",
          backgroundColor: "#FFFFFF",
          border: "1px solid #D8D8D8",
        }}>
        -
      </Button>
      <Slider
        min={1} // 缩放最小值
        max={9} // 缩放最大值
        step={1} // 缩放步长
        value={value} // 当前缩放值
        onChange={onChange} // 缩放时触发
        style={{ width: 300, marginBottom: 20 }} // 设置滑块样式
      />
      <Button
        shape="circle"
        onClick={handleIncrease}
        style={{
          marginLeft: "10px",
          width: "30px",
          height: "30px",
          backgroundColor: "#FFFFFF",
          border: "1px solid #D8D8D8",
        }}>
        +
      </Button>
    </div>
  );
};

export default ZoomControl;
