import { useState } from "react";
import BgMusicContainer from "./BgMusicContainer/BgMusicContainer";
import BgMusicTabsNav from "./BgMusicTabsNav/BgMusicTabsNav";
import { IMusicResponse } from "@/services/mixCutDetail/mixCutDetail";
import "./BgMusicTabs.less";
import CustomMusicGroup from "./CustomMusicGroup/CustomMusicGroup";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import { LoadingType } from "@/requestHooks/mixCutDetail/useRequest";

export interface IBuMusicTabs {
  list: { name: string; collection_id: number }[];
  musicList: IMusicResponse[];
  activeId: number;
  selfMusicList: IMaterialDetail[];
  favoriteMusicList: IMusicResponse[];
  publicLoading: LoadingType;
  favoriteLoading: LoadingType;
  handleListActiveIdChange: (id: number) => void;
  handleMusicCollectBtcClick: (id: number) => void;
  handleSelfUploadBlockBtnClick: () => void;
  handleDeleteCustomMusicItem: (id: number) => void;
  handleCancelCollectBtnClick: (id: number) => void;
}

const BgMusicTabs: React.FC<IBuMusicTabs> = (props) => {
  const {
    list,
    musicList,
    activeId,
    selfMusicList,
    favoriteMusicList,
    publicLoading,
    favoriteLoading,
    handleListActiveIdChange,
    handleSelfUploadBlockBtnClick,
    handleMusicCollectBtcClick,
    handleCancelCollectBtnClick,
    handleDeleteCustomMusicItem,
  } = props;
  const [isSelfBgContainer, setIsSelfBgContainer] = useState(false);
  const { handleBgAudiosChange, bgAudio } = useCoverAndBg();
  const hanldeBgMusiceNavIndexChange = (id: number) => {
    if (id === -1) {
      setIsSelfBgContainer(true);
    } else {
      setIsSelfBgContainer(false);
    }
    handleListActiveIdChange(id);
  };

  const handleCheckStateChange = (e: boolean, id: number) => {
    if (e) {
      const item = (selfMusicList as any[]).find(
        (item) => Number(item.id) === Number(id),
      );
      handleBgAudiosChange([
        ...bgAudio,
        { ...item, duration: item.duration * 1000 },
      ]);
    } else {
      const index = bgAudio.findIndex((item) => Number(item.id) === Number(id));
      bgAudio.splice(index, 1);
      handleBgAudiosChange([...bgAudio]);
    }
  };

  return (
    <div className="bg-music-tabs" style={{ height: 397 }}>
      <BgMusicTabsNav
        activeId={activeId}
        handleActiveId={(e) => {
          hanldeBgMusiceNavIndexChange(e);
        }}
        navList={list}
      />
      {isSelfBgContainer ? (
        <CustomMusicGroup
          handleDeleteCustomMusicItem={handleDeleteCustomMusicItem}
          handleSelfUploadBlockBtnClick={handleSelfUploadBlockBtnClick}
          data={selfMusicList}
          collectionAudio={bgAudio}
          handleCheckStateChange={handleCheckStateChange}
        />
      ) : (
        <BgMusicContainer
          loading={publicLoading === "loading" || favoriteLoading === "loading"}
          data={activeId !== 0 ? musicList : favoriteMusicList}
          handleMusicCollectBtcClick={handleMusicCollectBtcClick}
          handleCancelCollectBtnClick={handleCancelCollectBtnClick}
        />
      )}
    </div>
  );
};

export default BgMusicTabs;
