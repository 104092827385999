import { Checkbox, Table, TableColumnProps } from "@arco-design/web-react";
import React, { useEffect, useState } from "react";
// const

const Tabactive = (props) => {
  const { active } = props;
  const [datapage, setdatpage] = useState(null);

  useEffect(() => {
    setdatpage(active);
  }, [active]);
  useEffect(() => {
    setdatpage(active);
  }, []);

  const columns: TableColumnProps[] = [
    {
      title: "一级菜单",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "二级模块",
      dataIndex: "nameto",
      render: (a, b, c) => {
        let data;
        if (typeof a === "function") {
          data = a(String(active));
        }
        const handeFilter = (e) => {
          console.log(e, data, b.name);
          console.log(data.filter((i) => i == e).length > 0);

          // data.filter((i) => i == item.key).length > 0
          return data.filter((i) => i == e).length > 0;
        };
        return (
          <div className="tab_check_contel">
            {b.data.map((item) => {
              return (
                <div key={item.key} className="tab_check_item">
                  <Checkbox disabled checked={handeFilter(item.key)}>
                    {item.name}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "工作台",
      nameto: function (active) {
        const data = {
          "1": ["0", "1", "2", "3", "4", "5"],
          "2": ["2", "3", "4"],
          "3": ["0", "2", "3", "4"],
          "4": ["1", "4", "5"],
        };
        return data[active];
      },
      data: [
        {
          name: "智能混剪",
          key: "0",
        },
        {
          name: "矩阵营销",
          key: "1",
        },
        {
          name: "AI视频解析",
          key: "2",
        },
        {
          name: "多主题脚本",
          key: "3",
        },
        {
          name: "创作空间",
          key: "4",
        },
        {
          name: "客户端下载",
          key: "5",
        },
      ],
    },
    {
      key: "2",
      name: "创作空间",
      nameto: function (active) {
        const data = {
          "1": ["0", "1", "2", "3"],
          "2": ["0", "2", "3"],
          "3": ["0", "1", "2", "3"],
          "4": ["0", "3"],
        };
        return data[active];
      },
      data: [
        {
          name: "素材库",
          key: "0",
        },
        {
          name: "创意工程",
          key: "1",
        },
        {
          name: "脚本库",
          key: "2",
        },
        {
          name: "作品集",
          key: "3",
        },
      ],
    },
    {
      key: "3",
      name: "创意脚本",
      nameto: function (active) {
        const data = {
          "1": ["0", "1", "2"],
          "2": ["0", "1", "2"],
          "3": ["0", "1", "2"],
          "4": [],
        };
        return data[active];
      },
      data: [
        {
          name: "爆款脚本",
          key: "0",
        },
        {
          name: "AI主题创作",
          key: "1",
        },
        {
          name: "AI视频解析",
          key: "2",
        },
      ],
    },
    {
      key: "4",
      name: "视频裁剪",
      nameto: function (active) {
        const data = {
          "1": ["0"],
          "2": [],
          "3": ["0"],
          "4": [],
        };
        return data[active];
      },
      data: [
        {
          name: "智能混剪",
          key: "0",
        },
      ],
    },
    {
      key: "5",
      name: "矩阵营销",
      nameto: function (active) {
        const data = {
          "1": ["0"],
          "2": [],
          "3": [],
          "4": ["0"],
        };
        return data[active];
      },
      data: [
        {
          name: "矩阵营销",
          key: "0",
        },
      ],
    },
    {
      key: "6",
      name: "团队管理",
      nameto: function (active) {
        const data = {
          "1": ["0", "1", "2"],
          "2": [],
          "3": [],
          "4": [],
        };
        return data[active];
      },
      data: [
        {
          name: "成员管理",
          key: "0",
        },
        {
          name: "角色管理",
          key: "1",
        },
        {
          name: "部门管理",
          key: "2",
        },
      ],
    },
    {
      key: "7",
      name: "任务中心",
      nameto: function (active) {
        const data = {
          "1": ["0"],
          "2": ["0"],
          "3": ["0"],
          "4": [],
        };
        return data[active];
      },
      data: [
        {
          name: "任务中心",
          key: "0",
        },
      ],
    },
    {
      key: "8",
      name: "用户头像",
      nameto: function (active) {
        const data = {
          "1": ["0"],
          "2": ["0"],
          "3": ["0"],
          "4": ["0"],
        };
        return data[active];
      },
      data: [
        {
          name: "个人中心",
          key: "0",
        },
      ],
    },
  ];
  return (
    <Table
      className={"table_R_contel"}
      pagination={false}
      columns={columns}
      data={data}
      style={{ width: "100%" }}
    />
  );
};
export default Tabactive;
