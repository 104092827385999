import { GetProjectMemberIds } from "@/services/createSpace/project";
import {
  IDepartment,
  IDepartmentMember,
  toDepartmentFindMember,
  toTeamIdGetAllDepartment,
} from "@/services/createSpace/team";
import { team_detailserach } from "@/services/teme";
import { TreeDataType } from "@arco-design/web-react/es/Tree/interface";
import { useEffect, useState } from "react";

interface IUseAllDepartmentAndMember {
  department: IDepartment[];
  memberList: { department_id: string; memberList: IDepartmentMember[] }[];
  treeData: TreeDataType[];
  currentMemberIds: string[];
  currentNickName: string;
}

const useAllDepartmentAndMember = (
  projectId: number,
  managerId: string,
): IUseAllDepartmentAndMember => {
  const [department, setDepartment] = useState<IDepartment[]>([]);
  const [memberList, setMemberList] = useState<
    { department_id: string; memberList: IDepartmentMember[] }[]
  >([]);
  const [treeData, setTreeData] = useState<TreeDataType[]>([]);
  const [currentMemberIds, setCurrentMemberIds] = useState<string[]>([]);
  const [currentNickName, setCurrentNickName] = useState<string>(null);
  const fetchAllMembers = async (): Promise<{
    all: IDepartmentMember[];
    haveNoDepartmentList: IDepartmentMember[];
  }> => {
    const result = await toDepartmentFindMember({
      state: 3,
    });
    const newHaveNoDepartment = result.filter((item) => !item.department_id);
    return { all: result, haveNoDepartmentList: newHaveNoDepartment };
  };

  const createTreeData = async (
    department: IDepartment[],
    member: IDepartmentMember[],
    haveNoDepartmentList,
  ): Promise<TreeDataType[]> => {
    if (department && department.length > 0) {
      const teamInfo = await team_detailserach();
      const teamInfoDetail = teamInfo.data.item;
      return [
        {
          title: teamInfoDetail.name,
          key: teamInfoDetail.id,
          children: [
            ...haveNoDepartmentList.map((item) => {
              return {
                title: item.member_name,
                key: item.member_uuid,
                disabled: item.member_uuid === managerId,
              };
            }),
            ...department
              .map((item) => {
                return {
                  title: item.name,
                  key: item.id,
                  children: member
                    .filter(
                      (memberItem) =>
                        memberItem.department_id === Number(item.id),
                    )
                    .map((memberItem) => {
                      return {
                        title: memberItem.member_name,
                        key: memberItem.member_uuid,
                        disabled: memberItem.member_uuid === managerId,
                      };
                    }),
                };
              })
              .map((item) => {
                return {
                  ...item,
                  disabled: item.children.length === 0,
                };
              }),
          ],
        },
      ];
    }
    return [];
  };

  const init = async () => {
    const allDepartment = await toTeamIdGetAllDepartment({}); // 传递参数对象
    const newMemberList = await fetchAllMembers();
    const newTreeData = await createTreeData(
      allDepartment,
      newMemberList.all,
      newMemberList.haveNoDepartmentList,
    );
    const currentNickNameItem = newMemberList.all.find(
      (item) => item.member_uuid === managerId,
    ).member_name;
    setCurrentNickName(currentNickNameItem);
    setDepartment(allDepartment);
    setMemberList([]);
    setTreeData(newTreeData);
  };

  const getCurrentProjectMemberIds = async () => {
    const currentProjectMemberIds = await GetProjectMemberIds(projectId);
    setCurrentMemberIds(currentProjectMemberIds);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (projectId) {
      getCurrentProjectMemberIds();
    }
  }, [projectId]);

  return {
    department,
    memberList,
    treeData,
    currentMemberIds,
    currentNickName,
  };
};

export default useAllDepartmentAndMember;
