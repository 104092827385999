import { Tag } from "@arco-design/web-react";
import "./ChoseActionBar.less";
import { memo } from "react";
export interface IChoseActionBar {
  choseList: { name: string; id: number }[];
  handleChoseListChange: (closeId: number) => void;
}

const ChoseActionBar: React.FC<IChoseActionBar> = memo((props) => {
  const { choseList, handleChoseListChange } = props;
  return (
    <div className="chose-action-bar-wrapper">
      <span>已选择</span>
      {choseList && choseList.length > 0 ? (
        <span> {choseList.length}</span>
      ) : (
        <></>
      )}

      <div className="chose-action-tags">
        {/* {choseList.map((item) => {
          return (
            <Tag
              style={{
                background: "#E4ECFF",
                borderRadius: " 4px 4px 4px 4px",
                border: " 1px solid #165DFF",
                fontWeight: 400,
                fontSize: 12,
                color: "#165DFF",
                marginTop: "6px",
              }}
              bordered
              onClose={() => {
                handleChoseListChange(item.id);
              }}
              key={item.id}
              closable>
              {item.name}
            </Tag>
          );
        })} */}
      </div>
    </div>
  );
});

export default ChoseActionBar;
