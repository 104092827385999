import React, { useEffect, useState } from "react";
import operateop from "@/assets/member/operate.png";
import {
  Form,
  Input,
  Button,
  Select,
  Switch,
  Message,
} from "@arco-design/web-react";
import { IconClose, IconCheck } from "@arco-design/web-react/icon";
import delect from "@/assets/member/delect.png";
import disable from "@/assets/member/disable.png";
import details from "@/assets/member/details.png";
import { Tooltip } from "@arco-design/web-react";
import { inject, observer } from "mobx-react";
import eye from "@/assets/member/eye.png";
import Modals from "@/components/UI/Modals/index";
import edit from "@/assets/member/edit.png";
import info from "@/assets/member/indfo.png";
import Review from "@/assets/member/Review.png";
import Reviewdetil from "@/assets/member/Reviewdetil.png";
import Enbledelet from "@/assets/member/Enbledelet.png";
import "../index.less";
import "../components/Operate.less";
import Roleactive from "./roleactive";
const FormItem = Form.Item;

interface OperateProps {
  data: Object;
  team: any;
}
const Operate: React.FC<OperateProps> = (props: any) => {
  const { team, teamover } = props;
  const { data } = props;
  const [dataState, setdataState] = useState(false); //控制 弹窗 浮现
  const [dataProps, setdataProps] = useState(null);
  useEffect(() => {
    setdataProps(data);
  }, []);
  useEffect(() => {
    setdataProps(data);
  }, [data]);
  const InfoActive = () => {
    const status = data.state;
    const role = data.role_id;
    // 1 待加入
    // 3 正常
    // 201   管理员
    // 4 已停用
    // 2 已拒绝

    const BtnClikedetil = () => {
      const Option = Select.Option;
      const [formref] = Form.useForm();
      const [btnclonedeildelp, setbtnclonedeildelp] = useState(false);
      const mutiemArrymi = [
        {
          title: "编导",
          key: 2,
        },
        {
          title: "剪辑",
          key: 3,
        },
        {
          title: "运营",
          key: 4,
        },
      ];

      const [name, setname] = useState(data.member_name); // 姓名

      const [optionsdepe, setoptionsdepe] = useState([]); //渲染部门数据
      const [depeactive, setdepeactive] = useState(data.department_name); //选择的部门数据
      const [seleData, setDataopt] = useState(data.department_id); // 已经选存储的 部门id

      const [selectList, setSelectList] = useState(
        Roleactive.array(data.role_id),
      ); //已选择的 角色
      const [selectroledata, setselectdata] = useState(
        role.map((item) => +item),
      ); // 已选择的 角色 id

      const [Switchinfo, setSwitchinfo] = useState(
        String(dataProps.state) == "3" ? true : false,
      );
      interface OptionInfo {
        child: {
          key: string;
        };
      }
      useEffect(() => {
        setoptionsdepe(team.Treedepedata);
      }, [team.Treedepedata]);

      const getCheckState = (list: string[], name: string) => {
        return list.includes(name);
      };

      const merberdeilteedit = () => {
        setbtnclonedeildelp(false);
      };
      const merberdeilteeditsubmit = () => {
        formref
          .validate({ validateOnly: true })
          .then(() => {
            team.team_member_idput_to({
              id: dataProps.id,
              role_id: String(role) == "1" ? [1] : selectroledata,
              state: String(role) == "1" ? 3 : Switchinfo ? 3 : 4,
              name: name ? name : "",
              department_id: seleData ? Number(seleData) : undefined,
            });
            setbtnclonedeildelp(false);
          })
          .catch((e) => {
            console.log(e);
          });
      };

      return (
        <>
          <Button
            type="primary"
            className="popin_img"
            icon={
              <img
                src={details}
                alt=""
                style={{ width: "14px", height: "14px" }}
              />
            }
            onClick={() => {
              setbtnclonedeildelp(true);
            }}>
            详情
          </Button>

          <Modals
            width={486}
            title="成员详情编辑"
            titleIcon={
              <img src={edit} style={{ width: "23px", height: "23px" }} />
            }
            visible={btnclonedeildelp}
            cancelBtn={<Button onClick={merberdeilteedit}>取消</Button>}
            subMiteBtn={
              <Button
                type="primary"
                onClick={() => {
                  merberdeilteeditsubmit();
                }}>
                保存
              </Button>
            }
            closeIcon={<IconClose onClick={merberdeilteedit} />}>
            <div className="moadal-merber-contenl">
              <p className="moadal-merber-header-plehone">
                <span>手机号</span>
                <span>{dataProps.member_phone} </span>
                {/* <span>
                  <img src={eye} alt="" />
                </span> */}
              </p>
              <p className="merbrr-header-nametitle">姓名</p>
              <Form
                form={formref}
                style={{ width: 438, height: 32 }}
                initialValues={{
                  Merbername: name,
                }}>
                <FormItem
                  field="Merbername"
                  rules={[
                    {
                      validator(value, cb) {
                        if (!value) {
                          return cb("请输入20字以内的成员姓名");
                        }
                        return cb();
                      },
                    },
                  ]}>
                  <Input
                    placeholder="请输入20字以内的成员姓名"
                    onChange={(e) => {
                      setname(e);
                    }}
                  />
                </FormItem>
              </Form>
              <p className="merbrr-header-depeseleect">部门</p>
              <Select
                style={{ width: 438, height: 32 }}
                placeholder="请选择所在部门"
                className="meber_mobal_selelon_depe"
                value={depeactive}
                onChange={(e, a) => {
                  setDataopt((a as OptionInfo).child.key);
                  setdepeactive(e);
                }}>
                {optionsdepe.map((option, index) => (
                  <Option key={option.key} value={option.title}>
                    {option.title}
                  </Option>
                ))}
              </Select>
              <span className="merbrr-header-roleselect">角色</span>
              {String(role) != "1" ? (
                <Select
                  style={{ width: 438, height: 32 }}
                  placeholder="请选择成员角色"
                  className="meber_mobal_selelon_name"
                  mode="multiple"
                  value={selectList}
                  onChange={(e, a) => {
                    const arr = [];
                    (a as OptionInfo[]).map((ite, index) => {
                      arr.push(Number(ite.child.key));
                    });
                    setselectdata(arr);
                    setSelectList(e);
                  }}
                  allowClear>
                  {mutiemArrymi.map((option, index) => (
                    <Option
                      key={option.key}
                      value={option.title}
                      wrapperClassName="select-demo-hide-option-checkbox">
                      <div className="depe_select_modal_iconcheck">
                        <span>{option.title}</span>
                        <span>
                          {getCheckState(selectList, String(option.title)) ? (
                            <>
                              <IconCheck
                                style={{ color: "#165DFF" }}
                                className="icon_wrapper"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  style={{ width: 438, height: 32 }}
                  className="meber_mobal_selelon_name"
                  mode="multiple"
                  disabled
                  placeholder="管理员"
                  defaultValue={["管理员"]}
                  allowClear></Select>
              )}

              <>
                <p className="merbrr-header-status">状态</p>
                <Switch
                  checked={Switchinfo}
                  onChange={() => {
                    setSwitchinfo(!Switchinfo);
                  }}
                  disabled={String(role) == "1"}
                />

                <div style={{ height: "23px" }}>
                  {!Switchinfo && (
                    <p className="merbrr-header-infoswitch">
                      <img src={info} alt="" />
                      <span>已停用成员不可登录此团队</span>
                    </p>
                  )}
                </div>
              </>
            </div>
          </Modals>
        </>
      );
    };

    const BtnClickDisble = () => {
      const [BtnclickVisble, setBtnclickVisble] = useState(false);
      const Btnclick = () => {
        setBtnclickVisble(false);
      };
      const BtnCllckModal = () => {
        team.team_meber_batchstaus_to({
          ids: [dataProps.id],
          state: 4,
        });
        setBtnclickVisble(false);
      };
      return (
        <>
          <Button
            type="primary"
            className="popin_img"
            icon={
              <img
                src={disable}
                alt=""
                style={{ width: "14px", height: "14px" }}
              />
            }
            onClick={() => {
              setBtnclickVisble(true);
            }}>
            停用
          </Button>
          <Modals
            width={464}
            height={230}
            title="提示"
            titleIcon={
              <img
                src={edit}
                alt=""
                style={{ width: "23px", height: "23px" }}
              />
            }
            visible={BtnclickVisble}
            cancelBtn={<Button onClick={Btnclick}>取消</Button>}
            subMiteBtn={
              <Button type="primary" onClick={BtnCllckModal}>
                确认
              </Button>
            }
            closeIcon={<IconClose onClick={Btnclick} />}>
            <div className="disblae_modals">
              <div className="disblae_modals_body">
                <p className="disblae_modals_titeldis">确定停用账号？</p>
                <p className="disblae_modals_tit_wrapp">停用后不可登录此团队</p>
              </div>
            </div>
          </Modals>
        </>
      );
    };
    const BtnClickReview = () => {
      const [BtnclickVisble, setBtnclickVisble] = useState(false);
      const Btnclick = () => {
        team.team_meber_batchstaus_to({
          ids: [dataProps.id],
          state: 2,
        });
        setBtnclickVisble(false);
      };
      const BtnCllckModal = async () => {
        await teamover.teme_package_to();

        if (
          teamover.supertoaldata.supertotal != null &&
          teamover.supertoaldata.supertotal > 0
        ) {
          await team.team_merber_idreview_to({
            id: dataProps.id,
            state: 3,
            // type: 2,
          });

          setTimeout(() => {
            teamover.teme_package_to();
          }, 200);
          setBtnclickVisble(false);
        } else {
          Message.info("套餐席位已达上限，无法添加");
        }
      };
      return (
        <>
          <Button
            type="primary"
            className="popin_img"
            icon={
              <img
                src={Reviewdetil}
                alt=""
                style={{ width: "14px", height: "14px" }}
              />
            }
            onClick={() => {
              setBtnclickVisble(true);
            }}>
            审核
          </Button>
          <Modals
            width={464}
            height={230}
            title="审核"
            titleIcon={
              <img
                src={Review}
                alt=""
                style={{ width: "23px", height: "23px" }}
              />
            }
            visible={BtnclickVisble}
            cancelBtn={<Button onClick={Btnclick}>拒绝</Button>}
            subMiteBtn={
              <Button type="primary" onClick={BtnCllckModal}>
                同意
              </Button>
            }
            closeIcon={
              <IconClose
                onClick={() => {
                  setBtnclickVisble(false);
                }}
              />
            }>
            <div className="disblae_modals">
              <div className="disblae_modals_body">
                <p className="disblae_modals_headerdepe">
                  <span>所属部门：</span>
                  <span>{dataProps.department_name}</span>
                  <span>{`   >   
                  `}</span>
                  <span>{Roleactive.string(dataProps.role_id)}</span>
                </p>
                <p className="disblae_modals_headername">
                  <span>
                    用户：
                    <span style={{ fontWeight: "bold" }}>
                      {dataProps.member_name}
                    </span>
                  </span>
                  <span>{dataProps.phonenuber}申请加入团队，请确认</span>
                </p>
              </div>
            </div>
          </Modals>
        </>
      );
    };
    const BtnClickDelete = () => {
      const [BtnclickVisble, setBtnclickVisble] = useState(false);
      const Btnclick = () => {
        setBtnclickVisble(false);
      };
      const BtnCllckModal = () => {
        const id = [dataProps.id];
        team.team_member_delete_to({
          ids: id,
        });
        setBtnclickVisble(false);
      };
      return (
        <>
          <Button
            type="primary"
            className="popin_img"
            icon={
              <img
                src={delect}
                alt=""
                style={{ width: "14px", height: "14px" }}
              />
            }
            onClick={() => {
              setBtnclickVisble(true);
            }}>
            删除
          </Button>
          <Modals
            width={464}
            height={230}
            title="提示"
            titleIcon={
              <img
                src={edit}
                alt=""
                style={{ width: "23px", height: "23px" }}
              />
            }
            visible={BtnclickVisble}
            cancelBtn={<Button onClick={Btnclick}>取消</Button>}
            subMiteBtn={
              <Button type="primary" onClick={BtnCllckModal}>
                确认
              </Button>
            }
            closeIcon={<IconClose onClick={Btnclick} />}>
            <div className="disblae_modals">
              <div className="disblae_modals_body">
                <p className="disblae_modals_titeldis">确定删除账号？</p>
                <p className="disblae_modals_tit_wrapp">
                  删除后不可登录此团队，且数据不可恢复
                </p>
              </div>
            </div>
          </Modals>
        </>
      );
    };
    const BtnClickEnable = () => {
      return (
        <>
          <Button
            type="primary"
            className="popin_img"
            icon={
              <img
                src={Enbledelet}
                alt=""
                style={{ width: "14px", height: "14px" }}
              />
            }
            onClick={() => {
              team.team_meber_batchstaus_to({
                ids: [dataProps.id],
                state: 3,
              });
            }}>
            启用
          </Button>
        </>
      );
    };
    // 1 待加入
    // 3 正常
    // 201   管理员
    // 4 已停用
    // 2 已拒绝

    return (
      <>
        {String(status) != "1" && String(status) != "2" && (
          <BtnClikedetil></BtnClikedetil>
        )}
        {String(status) == "3" && String(role) != "1" && (
          <BtnClickDisble></BtnClickDisble>
        )}
        {String(status) == "1" && <BtnClickReview></BtnClickReview>}
        {String(status) == "4" && <BtnClickEnable></BtnClickEnable>}
        {String(role) != "1" && <BtnClickDelete></BtnClickDelete>}
      </>
    );
  };

  return (
    <div className="popinformationbody">
      <div className="popinformationbrem" style={{ position: "relative" }}>
        <Tooltip
          position="bl"
          trigger="click"
          className="popinformation"
          content={<InfoActive></InfoActive>}>
          <img
            src={operateop}
            alt=""
            onClick={() => {
              setdataState(!dataState);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default inject("team", "teamover")(observer(Operate));
