import BgColorConfig from "./BgColorConfig/BgColorConfig";
import BgMusicConfig from "./BgMusicConfig/BgMusicConfig";
import CoverConfig from "./CoverConfig/CoverConfig";

const BgConfig = () => {
  return (
    <>
      <BgMusicConfig />
      <BgColorConfig />
      <CoverConfig />
    </>
  );
};

export default BgConfig;
