import { Fragment, useContext, useEffect, useRef, useState } from "react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../UIComponents/VideoConfigCard/VideoConfigCard";
import { Message } from "@arco-design/web-react";
import { IconPlus } from "@arco-design/web-react/icon";
import LineFontConfig from "../VideoConfig/VoiceConfig/LineFontConfig/LineFontConfig";
import useTitle from "@/useStore/mixCutDetail/useTitle";
import { mixCutDetailContext } from "../MixCutDetail";
import { observer } from "mobx-react";
import SmartSearch from "@/components/business/SmartSearch";
import Intelligent from "@/components/business/Intelligent";
import MyEditor from "@/components/business/MyEditor";
import aiLineImg from "@/assets/cut-mix-detail/ai_line.png";
import usePreviewConfig from "@/useStore/preview/usePreview";
import "./index.less";
import { useEditorHelper } from "@/components/business/MyEditor/helper";
export interface FontTitleProps {
  style?: React.CSSProperties;
}

const FontTitle: React.FC<FontTitleProps> = observer((props) => {
  const { style } = props;
  const {
    titleList,
    handleTitleItemChange,
    handelTitleItemAddItem,
    handleTitleItemSimpleRemove,
  } = useTitle();
  const { handleClipIdChange } = usePreviewConfig();
  const { videoGroupActive } = useContext(mixCutDetailContext);
  const [currentActiveTitleIndex, setCurrentActiveTitleIndex] = useState(0);

  const [aiSearchState, setAiSearchState] = useState<boolean[]>([]);
  const [intelligenceState, setIntelligenceState] = useState<boolean>(false);

  // smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(true);

  const editorRef = useRef([]);
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    range: {
      from: -1,
      to: -1,
    },
  });

  const handleClickInterlligent = (opt) => {
    const edit = editorRef.current[currentActiveTitleIndex].editor;
    const [helper] = useEditorHelper(edit);
    const { from, to } = helper.getSelectionSSML();
    switch (opt.type) {
      case "change":
        if (from === to) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
  };

  const reSetCurrentActiveTitleIndex = (index: number) => {
    if (index !== currentActiveTitleIndex) {
      setAiSearchState(aiSearchState.map(() => false));
      setIntelligenceState(false);
      setItelligentIsExecution(true);
    }
    const editor = editorRef.current[index].editor;
    const [helper] = useEditorHelper(editor);
    setSelectInfo({ ...selectInfo, range: helper.getSelectionSSML() });
    setCurrentActiveTitleIndex(index);
  };

  useEffect(() => {
    setCurrentActiveTitleIndex(0);
  }, [videoGroupActive]);
  return (
    <div style={style}>
      <VideoConfigCard style={{ marginTop: 10 }}>
        {((titleList || [])[videoGroupActive]?.content || []).map(
          (item, index) => {
            return (
              <div
                key={item.id}
                className={`video-config-simple-title-wrapper ${currentActiveTitleIndex === index ? "title-item-active" : ""}`}
                onClick={() => {
                  reSetCurrentActiveTitleIndex(index);
                }}>
                <VideoConfigCardHeader
                  style={{ marginTop: 10 }}
                  headerLeft={"文本标题"}
                  headerRight={
                    index !== 0 ? (
                      <div
                        className="delete-title-item"
                        onClick={() => {
                          handleTitleItemSimpleRemove(videoGroupActive, index);
                        }}>
                        删除
                      </div>
                    ) : (
                      <></>
                    )
                  }></VideoConfigCardHeader>
                <VideoConfigCardContainer
                  style={{ paddingBottom: 0 }}
                  className="video-config-card-container-item">
                  <Fragment key={index}>
                    <MyEditor
                      ref={(ref) => {
                        editorRef.current[index] = ref;
                      }}
                      useCharacterLimit={false}
                      content={
                        item._text && item._text.length > 0
                          ? item._text
                          : [
                              {
                                type: "paragraph",
                                content: [
                                  {
                                    type: "text",
                                    text: "",
                                  },
                                ],
                              },
                            ]
                      }
                      placeholder={"请输入文本标题"}
                      handleblur={() => {
                        console.log("title handleBlur");
                        // handleClipIdChange(null);
                      }}
                      handlefocus={() => {
                        console.log("title handlefoucs");
                        reSetCurrentActiveTitleIndex(index);
                        const clipId =
                          titleList[videoGroupActive].content[index].id;
                        handleClipIdChange(clipId);
                      }}
                      handleUpdate={(val) => {
                        const data = editorRef.current[index].editor
                          .getText()
                          .replace("\n", " ");
                        handleTitleItemChange(videoGroupActive, index, {
                          ...item,
                          text: data,
                          _text: val,
                        });
                      }}
                      selected={({ value, range }) => {
                        setSmartValue(
                          value ||
                            editorRef.current[currentActiveTitleIndex].editor
                              .getText()
                              .replace(/\n\n\n\n/g, "\n")
                              .slice(1),
                        );
                        setSelectInfo({
                          index: -1,
                          range,
                        });
                      }}
                      selectInfo={selectInfo}
                      type={""}
                    />
                    <div className="voice-container-bar-title">
                      <div className="insert-opeator">
                        <img
                          src={aiLineImg}
                          alt=""
                          style={{
                            cursor: "pointer",
                            width: "73px",
                            height: "22px",
                            marginLeft: 6,
                          }}
                          className="ai-line"
                          onClick={() => {
                            const newAiSearchState = [...aiSearchState];
                            newAiSearchState[index] = !newAiSearchState[index];
                            setAiSearchState([...newAiSearchState]);
                          }}
                        />
                        {aiSearchState[index] ? (
                          <SmartSearch
                            disStopPropagation
                            placeholder={"帮我写..."}
                            styles={{
                              marginLeft: "30px",
                              width: "468px",
                            }}
                            handelClickBtn={(value: string, type: string) => {
                              setSmartStatus(type);
                              setSendSmartStatus(value);
                              setIntelligenceState(true);
                            }}
                            editValue={smartValue}
                            isEnd={itelligentIsExecution}
                            handleKeyDown={(event) => {
                              if (
                                event.key === "Escape" ||
                                event.keyCode === 27
                              ) {
                                setAiSearchState(
                                  aiSearchState.map(() => false),
                                );
                                setIntelligenceState(false);
                                setItelligentIsExecution(true);
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                </VideoConfigCardContainer>
              </div>
            );
          },
        )}
      </VideoConfigCard>
      {intelligenceState ? (
        <Intelligent
          style={{
            width: "405px",
            top: 73,
            position: "fixed",
            right: 0,
            bottom: 0,
            height: "calc(100vh - 120px)",
            zIndex: 1000,
          }}
          defaultStatus={smartStatus}
          value={sendSmartStatus}
          isInExecution={setItelligentIsExecution}
          handleOnChange={(e) => {
            handleClickInterlligent(e);
          }}
          handleCloseBtnClick={() => {
            setSendSmartStatus("");
            setIntelligenceState(false);
            setItelligentIsExecution(true);
            setAiSearchState(aiSearchState.map((item) => false));
          }}
        />
      ) : null}
      <div
        className="button-wrapper"
        style={{
          display: "flex",
          marginTop: 10,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          className="add-button"
          onClick={() => {
            handelTitleItemAddItem(videoGroupActive);
          }}>
          <IconPlus />
          添加标题
        </div>
      </div>
      <LineFontConfig
        global={true}
        style={{ marginTop: 15 }}
        data={
          ((titleList || [])[videoGroupActive]?.content || [])[
            currentActiveTitleIndex
          ]?.text_setting
        }
        handleTextSettingChange={(e) => {
          const textSettingItem = (titleList || [])[videoGroupActive].content[
            currentActiveTitleIndex
          ];
          handleTitleItemChange(videoGroupActive, currentActiveTitleIndex, {
            ...titleList[videoGroupActive].content[currentActiveTitleIndex],
            text_setting: { ...textSettingItem.text_setting, ...e },
          });
        }}
      />
    </div>
  );
});

export default FontTitle;
