import { Input, Radio, Select, DatePicker, Tabs } from "@arco-design/web-react";
import { IconSearch } from "@arco-design/web-react/icon";
import React, { useState } from "react";
import "./index.less";
import { inject, observer } from "mobx-react";
import Nodata from "../componts/Nodata";
import no from "@/assets/teamoverview/no.png";
const TabPane = Tabs.TabPane;
const ContelSelect = (props) => {
  const { teamover } = props;
  const { RangePicker } = DatePicker;
  const Option = Select.Option;
  const options = [];
  const [activeTab, setActiveTab] = useState("activeqy");
  return (
    <div className="contel_componts">
      {/* <Radio.Group
        type="button"
        name="position"
        className={"team_overview_content_tabs"}
        options={["权益流水", "交易记录"]}></Radio.Group> */}
      <div className="Filtememberscontexttabs">
        <Tabs
          activeTab={activeTab}
          onChange={(e) => {
            teamover.setactive(e);
            setActiveTab(e);
          }}>
          <TabPane key={"activeqy"} title="权益流水"></TabPane>
          <TabPane key={"activejy"} title="交易记录"></TabPane>
        </Tabs>
      </div>

      <div className="contel_R">
        <Input
          prefix={<IconSearch />}
          allowClear
          placeholder="请输入姓名/手机号查询"
          className="plearthPhone"
          onChange={(e) => {
            console.log(e);
          }}
        />
        <div>
          <Select
            placeholder="请选择应用类型"
            className={"optab_select"}
            notFoundContent={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <img
                  src={no}
                  alt=""
                  style={{
                    width: "120px",
                    height: "77px",
                    marginTop: "10px",
                  }}
                />
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}>
                  暂无内容
                </p>
              </div>
            }
            allowClear>
            {options.map((option, index) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <RangePicker
            mode="date"
            className={"select_data"}
            onChange={() => {
              //
            }}
            onSelect={() => {
              //
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default inject("teamover")(observer(ContelSelect));
