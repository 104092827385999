import "./TimbreSelector.less";

interface ITimberSelector {
  activeId: number;
  handleActiveIndex: (id: number) => void;
}

const timbreConfigList = [
  { name: "女声", id: 1 },
  { name: "男声", id: 2 },
  { name: "方言", id: 3 },
  // { name: "自定义", id: 4 },
];

const TimberSelector: React.FC<ITimberSelector> = (props) => {
  const { activeId, handleActiveIndex } = props;
  return (
    <div className="timberSelector">
      {timbreConfigList.map((item, index) => {
        return (
          <div
            onClick={() => {
              handleActiveIndex(item.id);
            }}
            key={item.name + index}
            className={`${activeId === item.id ? "active" : ""} timber-selector-block`}>
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

export default TimberSelector;
