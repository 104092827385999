import markImg from "@/assets/cut-mix-detail/bg-music-cover/favorite.png";
import noMarkImg from "@/assets/cut-mix-detail/bg-music-cover/no_favorite.png";
import { Checkbox, Message } from "@arco-design/web-react";
import { IMusicResponse } from "@/services/mixCutDetail/mixCutDetail";
import { formatPlayerTime } from "../BgMusicContainer/MusicPlayer/MusicPlayer";
import "./MusicCard.less";
export interface IMusicCardProps {
  data: Omit<IMusicResponse, "type" | "create_at" | "update_at">;
  style?: React.CSSProperties;
  checkState: boolean;
  handleCancelCollectBtnClick: () => void;
  handleCheckStateChange: (e: boolean) => void;
  hanldeCollectBtnClick: () => void;
  onClick: () => void;
}

const MusicCard: React.FC<IMusicCardProps> = (props) => {
  const {
    style,
    data,
    checkState,
    handleCheckStateChange,
    onClick,
    hanldeCollectBtnClick,
    handleCancelCollectBtnClick,
  } = props;
  const { cover_url, title, author_name, is_collect } = data;

  return (
    <div
      className={`${checkState ? "active" : ""} music-card`}
      style={style}
      onClick={onClick}>
      <div className="checkout-wrapper">
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
          }}
          checked={checkState}
          style={{ margin: 0, zIndex: 100 }}
          onChange={handleCheckStateChange}></Checkbox>
      </div>
      <div className="img-wrapper">
        <img src={cover_url} alt="" />
      </div>
      <div className="music-msg-wrapper">
        <div className="music-msg">
          <p className="name">{title}</p>
          <p className="singer">{author_name}</p>
        </div>
        <p className="bottom">
          <span className="time">{formatPlayerTime(data.duration)}</span>
          {is_collect ? (
            <img
              src={markImg}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                handleCancelCollectBtnClick();
              }}
            />
          ) : (
            <img
              onClick={(e) => {
                e.stopPropagation();
                hanldeCollectBtnClick();
              }}
              src={noMarkImg}
              alt=""
            />
          )}
        </p>
      </div>
    </div>
  );
};

export default MusicCard;
