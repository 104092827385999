import { useState, useEffect } from "react";
import { getAudition } from "@/services/mixCutDetail/mixCutDetail";

interface ISpeechRequest {
  line: string; // content
  speaker_id: string; // speaker_id
  speed_ratio: number; // speed_ratio
  volume_ratio: number; // volume_ratio
  pitch_ratio: number; // pitch_ratio
}

let audioContext: AudioContext | null = null;
let audioSource: AudioBufferSourceNode | null = null;

function releaseAudioResources() {
  if (audioSource) {
    audioSource.disconnect();
    audioSource = null;
  }
  if (audioContext) {
    audioContext.close();
    audioContext = null;
  }
}

const useAudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  async function playBase64Audio(base64Audio: string): Promise<void> {
    if (audioSource) {
      audioSource.stop();
      releaseAudioResources();
    }
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }

    audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const binaryData = atob(base64Audio);
    const buffer = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      buffer[i] = binaryData.charCodeAt(i);
    }
    const audioArrayBuffer = buffer.buffer;
    const audioBuffer = await audioContext.decodeAudioData(audioArrayBuffer);

    audioSource = audioContext.createBufferSource();
    audioSource.buffer = audioBuffer;
    audioSource.connect(audioContext.destination);
    audioSource.start();

    audioSource.onended = () => {
      releaseAudioResources();
      setIsPlaying(false);
    };
  }

  const playAudio = async (data: ISpeechRequest) => {
    setLoading(true);
    try {
      const base64Audio = await getAudition(data);
      await playBase64Audio(base64Audio);
      setIsPlaying(true);
    } catch (error) {
      console.error("播放音频时出错:", error);
    } finally {
      setLoading(false);
    }
  };

  const stopAudio = () => {
    if (audioSource) {
      audioSource.stop();
      releaseAudioResources();
      setIsPlaying(false);
    }
  };

  const togglePlay = async (data: ISpeechRequest) => {
    if (isPlaying) {
      stopAudio();
    } else {
      await playAudio(data);
    }
  };

  useEffect(() => {
    return () => {
      releaseAudioResources();
    };
  }, []);

  return {
    playAudio,
    isPlaying,
    loading,
    togglePlay,
    stopAudio,
  };
};

export default useAudioPlayer;
