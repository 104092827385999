import { IconClose } from "@arco-design/web-react/icon";
import "./TitleGroupTagItem.less";

export interface ITitleGroupTagItem {
  closeState?: boolean;
  active: boolean;
  title: string;
  onClick: () => void;
  handleDeleteBtnClick: () => void;
}

const TitleGroupTagItem: React.FC<ITitleGroupTagItem> = (props) => {
  const { title, active, onClick, handleDeleteBtnClick, closeState } = props;
  return (
    <div
      onClick={onClick}
      className={`${active ? "active" : ""}  title-group-tag-item`}>
      {title}
      {closeState ? (
        <IconClose
          className="icon-close"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteBtnClick();
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TitleGroupTagItem;
