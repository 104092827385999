import { ITerbreItemResponse } from "@/services/mixCutDetail/mixCutDetail";
import "./TimbreCard.less";
export type TimbreCardDataType = Partial<
  Pick<
    ITerbreItemResponse,
    "name" | "speaker_style" | "url" | "id" | "speaker_id"
  >
>;

export interface ITimbreCard {
  data: TimbreCardDataType;
  active: boolean;
  onClick: () => void;
}

const TimbreCard: React.FC<ITimbreCard> = (props) => {
  const { active, onClick, data } = props;
  return (
    <div className={`${active ? "active" : ""} timbre-card`} onClick={onClick}>
      {/* {url} */}
      <img src={data.url} alt="" />
      <div className="timbre-msg">
        <div className="title">{data.name ? data.name : "无"}</div>
        {data.speaker_style ? (
          <div className="sub-title">{data.speaker_style}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TimbreCard;
