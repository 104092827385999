import React, { useState } from "react";
import "./style/index.less";
import Tabactive from "./table";

const Role = () => {
  const role_data = [
    {
      title: "超级管理员",
      ID: "1",
    },
    {
      title: "编导",
      ID: "2",
    },
    {
      title: "剪辑",
      ID: "3",
    },
    {
      title: "运营",
      ID: "4",
    },
  ];
  const [item_active, setactive] = useState("1");
  return (
    <div className="team_role_wrapple">
      <div className="role_table">
        <div className="role_table_contel">
          <p className="role_table_title">系统管理权限配置</p>
          <div className="table_contel">
            {role_data.map((item) => {
              return (
                <div
                  className={`role_table_item  ${item_active == item.ID && "item_active"}`}
                  style={{ cursor: "pointer" }}
                  key={item.ID}
                  onClick={() => {
                    setactive(item.ID);
                  }}>
                  <div className="role_table_item_text">{item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="role_contel">
        <Tabactive active={item_active}></Tabactive>
      </div>
    </div>
  );
};

export default Role;
