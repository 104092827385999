import { isString } from "@tiptap/core";

const Roleactive = {
    roleMap: {
        "1": "管理员",
        "2": "编导",
        "3": "剪辑",
        "4": "运营",
        "5": "查看",
    },
    array: function (role: string[]) {
        if (!role) {
            return [];
        }

        const result = [];
        role.forEach((r) => {
            const trimmedRole = r.trim();
            if (this.roleMap[trimmedRole]) {
                result.push(this.roleMap[trimmedRole]);
            }
        });
        return result;
    },

    string: function (role: string[]) {
        if (!role) {
            return "";
        }
        const result = [];
        role.forEach((r) => {
            const trimmedRole = r.trim();
            if (this.roleMap[trimmedRole]) {
                result.push(this.roleMap[trimmedRole]);
            }
        });
        return result.join(",");
    },
};
export default Roleactive;
// const Roleactive = (role: string) => {

//     const roleMap = {
//         '1': '超级管理员',
//         '2': '编导',
//         '3': '剪辑',
//         '4': '运营',
//         '5': '查看'
//     };
//     role = role.trim();
//     if (!role) {
//         return [];
//     }
//     const rolesArray = role.split(',');
//     const result = [];
//     rolesArray.forEach(role => {
//         role = role.trim();
//         if (roleMap[role]) {
//             result.push(roleMap[role]);
//         }
//     });
//     return result;

// }

// const arr = (role: string) => {
//     const roleMap = {
//         '1': '超级管理员',
//         '2': '编导',
//         '3': '剪辑',
//         '4': '运营',
//         '5': '查看'
//     };

//     role = role.trim();
//     if (!role) {
//         return '';


//     }

//     const rolesArray = role.split(',');
//     const result = [];
//     rolesArray.forEach(r => {
//         const trimmedRole = r.trim();
//         if (roleMap[trimmedRole]) {
//             result.push(roleMap[trimmedRole]);
//         }
//     });
//     return result.join(',');
// };