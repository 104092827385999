import "./Title.less";

interface ITitle {
  title: string;
  desc: string;
}

const Title: React.FC<ITitle> = (props) => {
  const { title, desc } = props;
  return (
    <div className="card-title">
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  );
};

export default Title;
